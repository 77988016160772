import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { dateParser } from "../../src/Utils/DateUtil";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator/Buffet";
import * as Widget from "./Widget/Buffet";
import * as Icon from "./Icon";
import StatusButton from "./StatusButton";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class CommonOrderDetailDialog extends Component {
  constructor(props) {
    super(props);
    let { instance, items } = this.props;
    this.state = {
      record: {
        ...instance
      },
      shoesItems: items,
      showSpinner: false,
      message: {
        display: true,
        text: ""
      },
      staffs: []
    };
  }

  componentDidMount() {
    this._getStaffList();
  }

  render() {
    let { title, items, open, onClose, profile, shoesCustomTypes } = this.props;
    let { record, shoesItems, showSpinner, message, staffs } = this.state;

    return (
      <Widget.Dialog open={open} className={`${open ? "active" : ""}`}>
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="content">
              <div className="title">{title}</div>
              <div className="form-section">
                <div className="form-container">
                  <div className="form-content">
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單編號</span>
                      <div className="text">{record.number}</div>
                    </Widget.FormField>
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單狀態</span>
                      <select
                        value={record.status}
                        onChange={e =>
                          this.setState({
                            record: { ...record, status: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      >
                        {[
                          { value: "waiting", display: "未付款" },
                          { value: "confirming", display: "留單" },
                          { value: "deposit_paid", display: "已付訂金" },
                          { value: "in_production", display: "製作中" },
                          { value: "in_inspection", display: "整理檢查" },
                          { value: "store_pickup", display: "可店取" },
                          { value: "delivered", display: "已寄送" },
                          { value: "completed", display: "已完成" }
                        ].map((status, i) => (
                          <option key={"statue" + i} value={status.value}>
                            {status.display}
                          </option>
                        ))}
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">製單人</span>
                      <select
                        value={record.staff}
                        onChange={e =>
                          this.setState({
                            record: { ...record, staff: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      >
                        <option value="">---</option>
                        {staffs &&
                          staffs.map((staff, i) => (
                            <option key={"staff" + i} value={staff.name}>
                              {staff.name}
                            </option>
                          ))}
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">顧客姓名</span>
                      <input
                        value={record.buyer_name}
                        onChange={e =>
                          this.setState({
                            record: { ...record, buyer_name: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">顧客電話</span>
                      <input
                        value={record.buyer_phone}
                        onChange={e =>
                          this.setState({
                            record: { ...record, buyer_phone: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">顧客信箱</span>
                      <input
                        value={record.buyer_email}
                        onChange={e =>
                          this.setState({
                            record: { ...record, buyer_email: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">寄送地址</span>
                      <input
                        value={record.receiver_address}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              receiver_address: e.target.value
                            },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單折扣</span>
                      <select
                        value={record.discount}
                        onChange={e =>
                          this.setState({
                            record: { ...record, discount: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      >
                        <option value={0}>無折扣</option>
                        <option value={9.5}>九五折</option>
                        <option value={9}>九折</option>
                        <option value={8}>八折</option>
                        <option value={8.5}>八五折</option>
                        <option value={5}>對折</option>
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">運費</span>
                      <input
                        value={record.shipping_cost}
                        type="number"
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              shipping_cost: e.target.value
                            },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">尾款結清</span>
                      <input
                        type="checkbox"
                        checked={record.payment_balance}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              payment_balance: e.target.checked
                            }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>

                  <div className="form-content">
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">預付訂金</span>
                      <input
                        type="number"
                        value={record.deposit}
                        onChange={e =>
                          this.setState({
                            record: { ...record, deposit: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單金額</span>
                      <input
                        type="number"
                        value={record.amount}
                        onChange={e =>
                          this.setState({
                            record: { ...record, amount: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">付款方式</span>
                      <select
                        value={record.payment_method}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              payment_method: e.target.value
                            }
                          })
                        }
                      >
                        <option value={"cash"}>現金</option>
                        <option value={"card"}>信用卡</option>
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單備註</span>
                      <input
                        value={record.note}
                        onChange={e =>
                          this.setState({
                            record: { ...record, note: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">預期完成日</span>
                      <input
                        type="date"
                        value={record.completed_date}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              completed_date: e.target.value
                            },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">配送方式</span>
                      <select
                        value={record.delivery_type}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              delivery_type: e.target.value
                            }
                          })
                        }
                      >
                        <option value="express">順豐</option>
                        <option value="post">郵局</option>
                        <option value="pelican">宅配通</option>
                        <option value="self_pick">自取</option>
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">物流單編號</span>
                      <input
                        value={record.logistics_number}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              logistics_number: e.target.value
                            },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">作業備註</span>
                      <textarea
                        value={record.production_note}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              production_note: e.target.value
                            },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>
                </div>

                {shoesItems.map((item, idx) => (
                  <div className="shoes-container">
                    {shoesCustomTypes && (
                      <div className="shoes-content">
                        {shoesCustomTypes
                          .filter((_, i) => i <= 2)
                          .map(customType => {
                            let choice = item.choices.find(
                              (c, i) => c.type.id === customType.id
                            );
                            return (
                              <Widget.FormField css="margin-bottom: 17px;">
                                <span className="label">
                                  {customType.display}
                                </span>
                                <select
                                  value={choice && choice.option.id}
                                  onChange={e =>
                                    this._setItemsChoiceValue(
                                      e,
                                      item,
                                      choice,
                                      customType,
                                      shoesCustomTypes,
                                      items,
                                      idx
                                    )
                                  }
                                >
                                  {customType.options.map(o => (
                                    <option value={o.id}>{o.display}</option>
                                  ))}
                                </select>
                              </Widget.FormField>
                            );
                          })}
                      </div>
                    )}
                    {shoesCustomTypes && (
                      <div className="shoes-content">
                        {shoesCustomTypes
                          .filter((_, i) => i >= 3)
                          .map(customType => {
                            let choice = item.choices.find(
                              (c, i) => c.type.id === customType.id
                            );
                            return (
                              <Widget.FormField css="margin-bottom: 17px;">
                                <span className="label">
                                  {customType.display}
                                </span>
                                <select
                                  value={choice && choice.option.id}
                                  onChange={e =>
                                    this._setItemsChoiceValue(
                                      e,
                                      item,
                                      choice,
                                      customType,
                                      shoesCustomTypes,
                                      items,
                                      idx
                                    )
                                  }
                                >
                                  {customType.options.map(o => (
                                    <option value={o.id}>{o.display}</option>
                                  ))}
                                </select>
                              </Widget.FormField>
                            );
                          })}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="bottom-section">
                <div className="message">{message.display && message.text}</div>

                {showSpinner ? (
                  <Widget.Spinner />
                ) : (
                  <div className="buttons">
                    <Widget.WhiteButton onClick={onClose}>
                      取消
                    </Widget.WhiteButton>
                    <Widget.BlueButton
                      onClick={() => this._submit(record, shoesItems)}
                    >
                      確定
                    </Widget.BlueButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Widget.Dialog>
    );
  }

  _getStaffList = async () => {
    let { appActions } = this.props;
    try {
      let staffs = await appActions.getStaffList();
      this.setState({ staffs });
    } catch (err) {
      console.warn(err);
    }
  };

  _setItemsChoiceValue = (
    e,
    item,
    choice,
    customType,
    shoesCustomTypes,
    items,
    idx
  ) => {
    if (choice) {
      //step1: find selected shoes type & restructure option data
      let selectedResult = customType.options.find(
        o => o.id === parseInt(e.target.value)
      );
      let selectedOption = {
        id: selectedResult.id,
        name: selectedResult.name
      };
      choice.option = selectedOption;

      //step2: find hit item choice, and update this option data
      let updateItem = item;
      let itemChoiceHit = updateItem.choices.find(
        (c, i) => c.type.id === customType.id
      );
      itemChoiceHit.option = choice.option;

      //step3: find hit item, and update data
      let updateItems = items;
      updateItems[idx] = updateItem;
      this.setState({ shoesItems: updateItems });
    } else {
      //step1: find selected shoes type
      let selectedResult = customType.options.find(
        o => o.id === parseInt(e.target.value)
      );
      let hitType = shoesCustomTypes.find(
        type => type.id === selectedResult.type
      );

      //step2: restructure type and option data
      let selectedType = {
        id: hitType.id,
        name: hitType.name
      };
      let selectedOption = {
        id: selectedResult.id,
        name: selectedResult.name
      };
      let selectedChoice = {
        type: selectedType,
        option: selectedOption
      };

      //step3: find hit item and push data
      let updateItem = item;
      item.choices.push(selectedChoice);

      //step4: update state data
      let updateItems = items;
      updateItems[idx] = updateItem;
      this.setState({
        shoesItems: updateItems
      });
    }
  };

  _submit = (record, shoesItems) => {
    let { instance, appActions, onClose, refreshOrder } = this.props;
    let { message } = this.state;

    //error handling
    if (record.final_amount !== 0 && record.status === "delivered") {
      this.setState({
        message: {
          display: true,
          text: "尾款未結清，訂單狀態不得修改為「已寄送」。"
        }
      });
      return;
    }

    if (record.final_amount !== 0 && record.status === "completed") {
      this.setState({
        message: {
          display: true,
          text: "尾款未結清，訂單狀態不得修改為「已完成」。"
        }
      });
      return;
    }

    if (record.completed_date === "") {
      this.setState({
        message: {
          display: true,
          text: "請填寫預期完成日。"
        }
      });
      return;
    }

    record.deposit = Number(record.deposit);
    record.shipping_cost = Number(record.shipping_cost);

    let data = JSON.stringify({ items: shoesItems });
    record.data = data;

    this.setState({ showSpinner: true });
    delay(500)
      .then(() => appActions.updateCommonOrderById(record))
      .then(() => this.setState({ showSpinner: false }))
      .then(() => {
        onClose();
        refreshOrder();
      })
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(CommonOrderDetailDialog);
