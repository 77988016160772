import React from "react";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import styled from "styled-components";
import Constants from "../../src/Domain/constants";
import * as Widget from "../Components/Widget";
import * as Icon from "../../src/Components/Icon";
import Table from "../../src/Components/Table";
import {
  factoryOrderNumberFormat,
  commonOrderNumberForamt,
  repairOrderNumberFormat
} from "../../src/Utils/NumberingFormatUtil";
import {
  orderStatusName,
  repairOrderStatusName
} from "../../src/Utils/OrderStatusNameUtil";
import AppConfig from "../Config";
import FactoryOrderDetailDialog from "./BuffetFactoryOrderDetailDialog";
import FactoryOrderDetailView from "./BuffetFactoryOrderDetailView";
import ExportCSVBar from "./BuffetExportCSVBar";

const factoryOrderModel = {
  fields: [
    {
      name: "serial_number",
      label: "工廠單編號",
      style: {
        flexBasis: 200,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        padding: "20px 10px"
      }
    },
    {
      name: "status",
      label: "工廠單狀態",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      },
      extra: {
        displayMap: {
          pending: "等待中",
          in_production: "製作中",
          arrival: "已到達",
          in_inspection: "整貨中",
          complete: "已完成"
        }
      }
    },
    {
      name: "created",
      label: "創立時間",
      type: "datetime",
      style: {
        flexBasis: 155,
        padding: "20px 30px"
      }
    },
    {
      name: "updated",
      label: "更新時間",
      type: "datetime",
      style: {
        flexBasis: 155,
        padding: "20px 30px"
      }
    },
    {
      name: "export_status",
      label: "是否匯出",
      style: {
        flexBasis: 120,
        padding: "20px 10px"
      },
      extra: {
        displayMap: {
          true: <Icon.Check size={24} color="#95A7B1" />,
          false: <Icon.Close size={24} color="#95A7B1" />
        }
      }
    }
  ]
};

const orderModel = {
  fields: [
    {
      name: "number",
      label: "編號",
      type: "checkbox",
      style: {
        flexBasis: 230,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        padding: "20px 10px"
      }
    },
    {
      name: "status",
      label: "狀態",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      },
      extra: {
        displayMap: {
          waiting: "未付款",
          confirming: "待確認",
          deposit_paid: "已付訂金",
          in_production: "製作中",
          in_inspection: "整理檢查",
          in_delivered: "已寄送"
        }
      }
    },
    {
      name: "payment_balance",
      label: "尾款狀態",
      type: "boolean",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      },
      extra: {
        displayMap: [
          { display: "結清", value: true },
          { display: "未結清", value: false }
        ]
      }
    },
    {
      name: "buyer_name",
      label: "顧客姓名",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "buyer_phone",
      label: "顧客電話",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "buyer_email",
      label: "顧客電郵",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    },
    {
      name: "created",
      label: "創立時間",
      type: "datetime",
      style: {
        flexBasis: 140,
        padding: "20px 30px"
      }
    }
  ]
};

const repairOrderModel = {
  fields: [
    {
      name: "serial_number",
      label: "編號",
      type: "checkbox",
      style: {
        flexBasis: 230,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        padding: "20px 10px"
      }
    },
    {
      name: "status",
      label: "狀態",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      },
      extra: {
        displayMap: {
          waiting: "等待中",
          accepted: "已受理",
          in_maintenance: "維修中",
          in_inspection: "檢查中",
          completed: "已完成"
        }
      }
    },
    {
      name: "buyer_name",
      label: "顧客姓名",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "buyer_phone",
      label: "顧客電話",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "buyer_email",
      label: "顧客電郵",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    },
    {
      name: "created",
      label: "創立時間",
      type: "datetime",
      style: {
        flexBasis: 140,
        padding: "20px 30px"
      }
    }
  ]
};

const SEARCH_FIELDS = ["title", "created", "updated", "export_status"];

class FactoryOrderTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      factoryOrders: [],
      filterType: "all",
      allFactoryOrders: [],
      instance: null,
      search: "",
      openDialog: false
    };
  }

  async componentDidMount() {
    this._fetchFactoryOrders();
  }

  render() {
    let {
      factoryOrders,
      filterType,
      allFactoryOrders,
      instance,
      search,
      openDialog
    } = this.state;

    return (
      <Wrapper>
        <h2>工廠單管理</h2>

        {(() => {
          if (instance) {
            return (
              <FactoryOrderDetailView
                onClose={() => {
                  this.setState({ instance: null });
                  this._fetchFactoryOrders();
                }}
                factoryOrderModel={factoryOrderModel}
                orderModel={orderModel}
                repairOrderModel={repairOrderModel}
                instance={instance}
              />
            );
          }

          return (
            <>
              <div className="align-container">
                <div className="search-wrapper">
                  <input
                    placeholder={`共${factoryOrders.length}筆紀錄`}
                    value={search}
                    onChange={e => this.setState({ search: e.target.value })}
                  />
                  <div className="icon-wrapper">
                    <Icon.Search
                      size={24}
                      css="margin-right: 10px;"
                      color={AppConfig.global.themeColor}
                    />
                  </div>
                </div>

                <div className="buttons-wrapper">
                  <Widget.CreateButton
                    onClick={() => this.setState({ openDialog: true })}
                  >
                    <div className="icon">
                      <Icon.Add size={24} color={"white"} />
                    </div>
                    <span>新增工廠單</span>
                  </Widget.CreateButton>
                </div>
              </div>

              <ExportCSVBar
                apiLink={`${Constants.apiUrl}/api/factory_order/export_csv/`}
              />

              <FilteringWrapper css="margin-bottom: 20px;">
                {[
                  { value: "title", label: "工廠單狀態：" },
                  { value: "all", label: "全部" },
                  { value: "pending", label: "等待中" },
                  { value: "in_production", label: "製作中" },
                  { value: "arrival", label: "已到達" },
                  { value: "in_inspection", label: "整貨中" },
                  { value: "complete", label: "已完成" }
                ].map((e, idx) => {
                  if (e.value === "title") {
                    return (
                      <div className="title" key={idx}>
                        {e.label}
                      </div>
                    );
                  }

                  return (
                    <div
                      key={idx}
                      className={
                        "option" + (e.value === filterType ? " active" : "")
                      }
                      onClick={() =>
                        this._onFilterClick(e.value, allFactoryOrders)
                      }
                    >
                      {e.label}
                    </div>
                  );
                })}
              </FilteringWrapper>

              <div className="table-container">
                {factoryOrders && (
                  <Table
                    model={factoryOrderModel}
                    instances={factoryOrders.filter(o => {
                      return SEARCH_FIELDS.some(
                        f => (o[f] ? `${o[f]}` : "").indexOf(search) > -1
                      );
                    })}
                    onCellClick={this._onCellClick}
                    activeInstanceId={instance && instance.id}
                  />
                )}
              </div>
            </>
          );
        })()}

        {openDialog && (
          <FactoryOrderDetailDialog
            target="create"
            title="新增工廠單"
            open={openDialog}
            onClose={() => this.setState({ openDialog: false })}
            refreshOrders={() => {
              this._fetchFactoryOrders();
            }}
          />
        )}
      </Wrapper>
    );
  }

  _onFilterClick = (value, allFactoryOrders) => {
    this.setState({ filterType: value });
    if (value === "all") {
      this.setState({ factoryOrders: allFactoryOrders });
    } else if (value === "pending") {
      let result = allFactoryOrders.filter(order => order.status === "pending");
      this.setState({ factoryOrders: result });
    } else if (value === "complete") {
      let result = allFactoryOrders.filter(
        order => order.status === "complete"
      );
      this.setState({ factoryOrders: result });
    }
  };

  _updateFactoryOrderStatus = async value => {
    let { appActions } = this.props;
    let { instance } = this.state;
    appActions
      .updateFactoryOrder(instance.id, { status: value })
      .then(resp => console.log(resp))
      .catch(err => console.log("fail to update!!"));
  };

  _onInstanceOrderRemove = async order => {
    let { instance } = this.state;
    let { appActions } = this.props;
    let _cachedOrders = [...instance.orders];
    let foundIdx = _cachedOrders.findIndex(o => o.id === order.id);
    _cachedOrders.splice(foundIdx, 1);
    let orderIds = _cachedOrders.map(o => o.id);
    appActions
      .updateFactoryOrder(instance.id, { orderIds })
      .then(result => {
        this.setState({ instance: result });
        this._fetchFactoryOrders();
      })
      .catch(err => console.log(err));
  };

  _onInstanceOrdersSave = async orders => {
    let { appActions } = this.props;
    let { instance } = this.state;
    let orderIds = [...instance.orders, ...orders].map(o => o.id);
    appActions
      .updateFactoryOrder(instance.id, { orderIds })
      .then(result => {
        this.setState({ instance: result });
        this._fetchFactoryOrders();
      })
      .catch(err => console.log(err));
  };

  _searchOrders = async search => {
    let { appActions } = this.props;
    return appActions
      .searchOrders(search, { from: "", to: "" })
      .then(result => result)
      .catch(err => []);
  };

  _convertFactoryOrderStructure = factoryOrder => {
    return {
      ...factoryOrder,
      serial_number: factoryOrderNumberFormat(factoryOrder),
      orders: factoryOrder.orders.map(o => ({
        ...o,
        number: commonOrderNumberForamt(o),
        status: orderStatusName(o.status)
      })),
      repair_orders: factoryOrder.repair_orders.map(o => ({
        ...o,
        serial_number: repairOrderNumberFormat(o),
        status: repairOrderStatusName(o.status)
      })),
      ordersCount: `${factoryOrder.orders.length} 筆`
    };
  };

  _fetchFactoryOrders = async () => {
    let { appActions } = this.props;
    let raw = await appActions.getFactoryOrders();
    let factoryOrders = raw
      .sort((a, b) => b.id - a.id)
      .map(fo => this._convertFactoryOrderStructure(fo));
    let updatedInstance = null;
    this.setState({
      factoryOrders,
      allFactoryOrders: factoryOrders
    });
    return factoryOrders;
  };

  _onCellClick = ({ instance }) => {
    if (
      (this.state.instance && instance.id !== this.state.instance.id) ||
      !this.state.instance
    ) {
      this.setState({
        instance
      });
    } else {
      this.setState({
        instance: null
      });
    }
  };
}

const Wrapper = styled.div`
  padding: 0px 30px;
  & > h2 {
    padding: 10px;
    letter-spacing: 3.8px;
    font-weight: 400;
    font-size: 38px;
    color: #737987;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 15px;
  }

  & .back-button {
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    color: blue;
  }

  & .table-container {
    & .row {
      display: flex;
    }
  }

  & .align-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;

    @media screen and (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  & .search-wrapper {
    display: flex;
    align-items: center;
    padding: 10px 0px;
    position: relative;
    width: 300px;

    & .icon-wrapper {
      position: absolute;
      right: 10px;
    }

    & input {
      width: 100%;
      border: 1px solid #979797;
      border-radius: 6px;
      padding: 8px 10px;
      outline: none;
      color: #101d1f;

      &:focus {
        box-shadow: 0px 0px 0px 1.5px lightgrey;
      }
    }
  }

  & .buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    & > .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      margin-right: 20px;

      padding: 3px;
      min-width: 80px;
      max-height: 30px;
      text-decoration: none;
      letter-spacing: 1.6px;
      font-size: 16px;
      color: #727886;

      :hover {
        background: #e8e8e8;
      }

      :last-child {
        margin-right: 0px;
      }
    }
  }
`;

const FilteringWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & .title {
    letter-spacing: 1.6px;
    font-size: 16px;
    color: rgba(25, 35, 59, 0.6);
  }

  & .option {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 5px;
    padding: 5px 10px;
    letter-spacing: 1.6px;
    color: #b5b8bf;
    min-width: 60px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }

  & .option.active {
    color: #4e77e7;
  }

  @media screen and (max-width: 550px) {
    flex-wrap: wrap;
  }

  ${props => props.css || ""};
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(FactoryOrderTable);
