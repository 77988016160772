import React, { Component } from "react";
import { connect } from "react-redux";
import { dateParser } from "../../src/Utils/DateUtil";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator/Buffet";
import * as Widget from "./Widget/Buffet";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class ConfirmDeleteActionDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false
    };
  }

  render() {
    let { open, title, text, onConfirm, onClose, profile } = this.props;
    let { showSpinner } = this.state;

    return (
      <Widget.Dialog open={open} className={`${open ? "active" : ""}`}>
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="content">
              <div className="title">{title}</div>
              <div className="text-container">{text}</div>
              <div className="bottom-section">
                <div className="message">
                  創立時間：{dateParser(new Date())} {profile.name} 創立
                </div>
                {showSpinner ? (
                  <Widget.Spinner />
                ) : (
                  <div className="buttons">
                    <Widget.WhiteButton onClick={onClose}>
                      取消
                    </Widget.WhiteButton>
                    <Widget.BlueButton
                      onClick={() => {
                        this.setState({ showSpinner: true });
                        delay(1000)
                          .then(() => onConfirm())
                          .then(() => this.setState({ showSpinner: false }))
                          .then(() => onClose())
                          .catch(err => {
                            console.warn(err);
                            this.setState({ showSpinner: false });
                          });
                      }}
                    >
                      確定
                    </Widget.BlueButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Widget.Dialog>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(ConfirmDeleteActionDialog);
