import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../ActionCreator";
import InputField from "../../../src/Components/InputField";
import Image from "../../../src/Components/Image";
import * as Icon from "../../../src/Components/Icon";
import * as Widget from "../../../src/Components/Widget";

class ImagesEditingForm extends Component {
  state = {
    newImage: {
      image: "",
      name: ""
    },

    deletingIdx: null
  };
  render() {
    let { images, onDeleteImage, onCreateImage, css = "" } = this.props;
    let { newImage, deletingIdx } = this.state;
    return (
      <Wrapper css={css}>
        {onCreateImage && (
          <div className="add-image">
            <InputField
              type="text"
              setState={this.setState.bind(this)}
              obj={newImage}
              objName="newImage"
              disabled={false}
              key={"name"}
              name={"name"}
              label="名稱"
              value={newImage.name}
            />

            <InputField
              type="image"
              setState={this.setState.bind(this)}
              obj={newImage}
              objName="newImage"
              disabled={false}
              key={"image"}
              name={"image"}
              label="檔案"
              value={newImage.image ? newImage.image : ""}
            />

            <button
              onClick={() => {
                onCreateImage(newImage).then(() =>
                  this.setState({ newImage: { image: "", name: "" } })
                );
              }}
            >
              新增圖片
            </button>
          </div>
        )}

        <div className="images-container">
          {images.map((image, idx) => {
            return (
              <div key={idx} className="image-container">
                <Widget.LoadingWrapper
                  loading={deletingIdx === idx}
                  key={idx}
                  css="height: 100%;"
                >
                  {onDeleteImage && (
                    <div
                      className="delete"
                      onClick={() => {
                        this.setState({ deletingIdx: idx });
                        onDeleteImage(image).then(() =>
                          this.setState({ deletingIdx: null })
                        );
                      }}
                    >
                      <Icon.Close size={24} color="white" />
                    </div>
                  )}
                  <Image
                    src={image.image}
                    css={`
                      height: 100%;
                    `}
                  />
                </Widget.LoadingWrapper>
              </div>
            );
          })}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & > .images-container {
    display: flex;
    flex-wrap: wrap;

    & > .image-container {
      position: relative;
      height: 200px;
      margin-right: 5px;
      margin-bottom: 5px;

      & .delete {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: tomato;
        cursor: pointer;
      }
    }
  }

  & > .add-image {
  }

  & button {
  }

  ${props => props.css}
`;

export default connect(
  null,
  ActionCreator
)(ImagesEditingForm);
