import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import Form from "../../../src/Components/Form";
import ImagesEditingForm from "../ImagesEditingForm";

class StoreForm extends React.Component {
  state = { error: "" };
  render() {
    let { store, appActions, css } = this.props;

    let { error } = this.state;
    if (!store && !error) {
      return null;
    }

    if (error) {
      return (
        <Wrapper css={css}>
          <p>{error}</p>
        </Wrapper>
      );
    }

    return (
      <Wrapper css={css}>
        <Form
          model={{
            fields: [
              { name: "name", type: "text", label: "店家名稱" },
              { name: "description", type: "textarea", label: "描述" },
              { name: "image", type: "image", label: "商家首圖" }
            ]
          }}
          instance={store}
          fnUpdate={data => {
            if (typeof data.image === "string") {
              delete data.image;
            }
            appActions.editStore(store.id, data);
          }}
        />

        <div>
          <div style={{ fontWeight: 600, marginBottom: 10 }}>編輯附屬圖片</div>
          <ImagesEditingForm
            images={store.images}
            onDeleteImage={image =>
              appActions
                .deleteStoreImagesById(image.id)
                .then(() => this._fetchStore())
            }
            onCreateImage={data =>
              appActions
                .createStoreImages(store.id, data)
                .then(() => this._fetchStore())
            }
            css={`
              margin-left: 30px;
            `}
          />
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 10px;
  ${props => props.css}
`;

export default connect(
  (state, ownProps) => ({
    store: Selectors.getStore(state)
  }),
  ActionCreator
)(StoreForm);
