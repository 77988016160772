export function calcFinalAmount(o) {
  let final_amount = o.amount;
  if (o.discount) {
    final_amount = Math.round((final_amount * o.discount) / 10);
  }
  final_amount += o.shipping_cost;
  final_amount -= o.deposit;

  if (o.payment_balance) {
    final_amount = 0;
  }

  return final_amount;
}
