import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../ActionCreator";
import Selectors from "../../Selectors";
import Form from "../../../src/Components/Form";
import ImagesEditingForm from "../ImagesEditingForm";

class ProductForm extends React.Component {
  render() {
    let { instance, appActions, css } = this.props;
    if (!instance) {
      return null;
    }

    return (
      <Wrapper css={css}>
        <Form
          model={{
            fields: [
              { name: "name", type: "text", label: "Name" },
              { name: "price", type: "number", label: "Price" },
              { name: "image", type: "image", label: "Image" },
              { name: "description", type: "textarea", label: "Description" },
              { name: "valid", type: "checkbox", label: "Valid" }
            ]
          }}
          instance={instance}
          fnUpdate={() => 0}
        />

        <ImagesEditingForm
          images={instance.images}
          onDeleteImage={image =>
            appActions.deleteProductImagesById(image.id).then(() => {
              let foundIdx = instance.images.findIndex(i => image.id === i.id);
              instance.images.splice(foundIdx, 1);
            })
          }
          onCreateImage={data =>
            appActions.createProductImages(instance.id, data).then(result => {
              instance.images.push(result);
            })
          }
          css={`
            margin-left: 30px;
          `}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${props => props.css || ""}
`;

export default connect(
  null,
  ActionCreator
)(ProductForm);
