import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { factoryOrderNumberFormat } from "../../src/Utils/NumberingFormatUtil";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget/Buffet";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class FactoryOrderDetailDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {
        title: "",
        status: "pending",
        factory: "",
        ...this.props.instance
      },
      showSpinner: false
    };
  }

  render() {
    let { target, title, open, onClose } = this.props;
    let { record, showSpinner } = this.state;

    return (
      <Widget.Dialog open={open} className={`${open ? "active" : ""}`}>
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="content">
              <div className="title">{title}</div>

              <div className="form-section">
                <div className="form-container">
                  <div className="form-content">
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">工廠單編號</span>
                      <div className="text">
                        {target === "create"
                          ? "待創建後生成"
                          : record.serial_number}
                      </div>
                    </Widget.FormField>
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">工廠單狀態</span>
                      <select
                        value={record.status}
                        onChange={e =>
                          this.setState({
                            record: { ...record, status: e.target.value }
                          })
                        }
                      >
                        <option value="pending">等待中</option>
                        <option value="in_production">製作中</option>
                        <option value="arrival">已到達</option>
                        <option value="in_inspection">整貨中</option>
                        <option value="complete">已完成</option>
                      </select>
                    </Widget.FormField>
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">指定工廠</span>
                      <select
                        value={record.factory}
                        onChange={e =>
                          this.setState({
                            record: { ...record, factory: e.target.value }
                          })
                        }
                      >
                        <option value="">---</option>
                        <option value="金永麗企業社">金永麗企業社</option>
                        <option value="丞豐鞋業有限公司">
                          丞豐鞋業有限公司
                        </option>
                        <option value="龍寶鞋業有限公司">
                          龍寶鞋業有限公司
                        </option>
                      </select>
                    </Widget.FormField>
                    <Widget.FormField>
                      <span className="label">作業備註</span>
                      <textarea
                        value={record.note}
                        onChange={e =>
                          this.setState({
                            record: { ...record, note: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>
                </div>
              </div>

              <div className="bottom-section">
                <div className="message" />
                {showSpinner ? (
                  <Widget.Spinner />
                ) : (
                  <div className="buttons">
                    <Widget.WhiteButton onClick={onClose}>
                      取消
                    </Widget.WhiteButton>
                    <Widget.BlueButton
                      onClick={() => this._submit(record, target)}
                    >
                      確定
                    </Widget.BlueButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Widget.Dialog>
    );
  }

  _submit = (data, target) => {
    let { appActions, onClose, refreshOrders, refreshOrder } = this.props;

    if (target === "create") {
      this.setState({ showSpinner: true });
      data.title = "buffet factory order";
      delay(500)
        .then(() => appActions.createFactoryOrder(data))
        .then(result => {
          result.serial_number = factoryOrderNumberFormat(result);
          appActions.updateFactoryOrderById(result);
        })
        .then(() => this.setState({ showSpinner: false }))
        .then(() => {
          onClose();
          refreshOrders();
        })
        .catch(err => {
          console.warn(err);
          this.setState({ showSpinner: false });
        });
    } else {
      //update
      this.setState({ showSpinner: true });
      delay(500)
        .then(() => appActions.updateFactoryOrderById(data))
        .then(() => this.setState({ showSpinner: false }))
        .then(() => {
          onClose();
          refreshOrder();
        })
        .catch(err => {
          console.warn(err);
          this.setState({ showSpinner: false });
        });
    }
  };
}

export default connect(
  null,
  ActionCreator
)(FactoryOrderDetailDialog);
