import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { dateParser } from "../../src/Utils/DateUtil";
import * as Widget from "./Widget/Buffet";
import * as Icon from "./Icon";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class StockDetailDialog extends Component {
  constructor(props) {
    super(props);
    let { stockTypes } = this.props;
    this.state = {
      record: {
        serial_number: "",
        stock_type: stockTypes[0].id,
        size: "",
        unit: "",
        price: "",
        company_name: "",
        company_data: "",
        large_inventory: "",
        small_inventory: "",
        booked_qty: "",
        safe_qty: "",
        req_qty: "",
        purchase_date: "",
        profile: "",
        note: "",
        ...this.props.instance
      },
      staffs: null,
      showSpinner: false,
      message: {
        display: true,
        text: ""
      }
    };
  }

  async componentDidMount() {
    let staffs = await this._fetchStaffs();
    this.setState({ staffs });
  }

  render() {
    let { target, title, open, onClose, stockTypes, profile } = this.props;
    let { record, staffs, showSpinner, message } = this.state;

    return (
      <Widget.Dialog open={open} className={`${open ? "active" : ""}`}>
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="content">
              <div className="title">{title}</div>

              <div className="form-section">
                <div className="form-container">
                  <div className="form-content">
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">材料編號</span>
                      <input
                        value={record.serial_number}
                        onChange={e =>
                          this.setState({
                            record: { ...record, serial_number: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">材料類別</span>
                      <select
                        value={record.stock_type}
                        onChange={e =>
                          this.setState({
                            record: { ...record, stock_type: e.target.value }
                          })
                        }
                      >
                        {stockTypes.map((type, i) => (
                          <option value={type.id} key={"stock-type" + i}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">材料尺寸</span>
                      <input
                        value={record.size}
                        onChange={e =>
                          this.setState({
                            record: { ...record, size: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">材料單位</span>
                      <input
                        value={record.unit}
                        onChange={e =>
                          this.setState({
                            record: { ...record, unit: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">參考單價</span>
                      <input
                        type="number"
                        value={record.price}
                        onChange={e =>
                          this.setState({
                            record: { ...record, price: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">廠商名稱</span>
                      <input
                        value={record.company_name}
                        onChange={e =>
                          this.setState({
                            record: { ...record, company_name: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">廠商資料</span>
                      <input
                        value={record.company_data}
                        onChange={e =>
                          this.setState({
                            record: { ...record, company_data: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>

                  <div className="form-content">
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">大庫存</span>
                      <input
                        type="number"
                        value={record.large_inventory}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              large_inventory: e.target.value
                            }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">小庫存</span>
                      <input
                        type="number"
                        value={record.small_inventory}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              small_inventory: e.target.value
                            }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">預定雙數</span>
                      <input
                        type="number"
                        value={record.booked_qty}
                        onChange={e =>
                          this.setState({
                            record: { ...record, booked_qty: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">預算數量</span>
                      <input
                        type="number"
                        value={record.safe_qty}
                        onChange={e =>
                          this.setState({
                            record: { ...record, safe_qty: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">還需採購</span>
                      <input
                        type="number"
                        value={record.req_qty}
                        onChange={e =>
                          this.setState({
                            record: { ...record, req_qty: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">採購日期</span>
                      <input
                        type="date"
                        value={record.purchase_date}
                        onChange={e =>
                          this.setState({
                            record: { ...record, purchase_date: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">採購人</span>
                      <select
                        value={record.profile}
                        onChange={e =>
                          this.setState({
                            record: { ...record, profile: e.target.value }
                          })
                        }
                      >
                        {staffs &&
                          staffs.map((staff, i) => (
                            <option key={"staff" + i} value={staff.id}>
                              {staff.name}
                            </option>
                          ))}
                      </select>
                    </Widget.FormField>
                  </div>
                </div>

                <div className="form-container">
                  {/* FAKE ITEM */}
                  <div className="form-content" style={{ height: 0 }}>
                    <Widget.FormField css="height: 0px;" />
                  </div>

                  <div className="form-content">
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">備註</span>
                      <textarea
                        value={record.note}
                        onChange={e =>
                          this.setState({
                            record: { ...record, note: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>
                </div>
              </div>

              <div className="bottom-section">
                <div className="message">{message.display && message.text}</div>

                {showSpinner ? (
                  <Widget.Spinner />
                ) : (
                  <div className="buttons">
                    <Widget.WhiteButton onClick={onClose}>
                      取消
                    </Widget.WhiteButton>
                    <Widget.BlueButton
                      onClick={() => this._submit(record, target)}
                    >
                      確定
                    </Widget.BlueButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Widget.Dialog>
    );
  }

  _fetchStaffs = async () => {
    let { appActions } = this.props;
    return await appActions.getStaffList();
  };

  _submit = (record, target) => {
    let { appActions, onClose, refreshStocks, refreshStock } = this.props;

    if (record.purchase_date === "") {
      this.setState({
        message: {
          display: true,
          text: "請填寫採購日期。"
        }
      });
      return;
    }

    if (target === "create") {
      this.setState({ showSpinner: true });
      delay(500)
        .then(() => appActions.createStockRecord(record))
        .then(() => this.setState({ showSpinner: false }))
        .then(() => {
          onClose();
          refreshStocks();
        })
        .catch(err => {
          console.warn(err);
          this.setState({ showSpinner: false });
        });
    } else {
      //update
      this.setState({ showSpinner: true });
      delay(500)
        .then(() => appActions.updateStockRecordById(record))
        .then(() => this.setState({ showSpinner: false }))
        .then(() => {
          onClose();
          refreshStock();
        })
        .catch(err => {
          console.warn(err);
          this.setState({ showSpinner: false });
        });
    }
  };
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(StockDetailDialog);
