import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { staffNumberFormat } from "../../src/Utils/NumberingFormatUtil";
import Constants from "../../src/Domain/constants";
import * as Icon from "../../src/Components/Icon";
import Table from "../../src/Components/Table";
import AppConfig from "../Config";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget";
import StaffDetailView from "./BuffetStaffDetailView";
import StaffDetailDialog from "./BuffetStaffDetailDialog";

const staffModel = {
  fields: [
    {
      name: "id",
      label: "編號",
      style: {
        flexBasis: 200,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        padding: "20px 10px"
      }
    },
    {
      name: "name",
      label: "姓名",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    },
    {
      name: "position",
      label: "公司職稱",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    },
    {
      name: "user",
      label: "後台權限",
      type: "nested",
      key: "is_superuser",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      },
      extra: {
        displayMap: {
          true: "管理",
          false: "編輯"
        }
      }
    },
    {
      name: "phone",
      label: "手機",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    },
    {
      name: "email",
      label: "電郵",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    }
  ]
};

const SEARCH_FIELDS = ["id", "name", "phone", "address", "email"];

class StaffTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffs: [],
      instance: null,
      search: "",
      openDialog: false
    };
  }

  async componentDidMount() {
    let { profile } = this.props;

    let staffs = await this._fetchStaffs();
    staffs = staffs
      .filter(s => (profile.user.is_superuser ? s : s.id === profile.id))
      .map(s => ({ ...s, id: staffNumberFormat(s.id) }));

    this.setState({ staffs });
  }

  render() {
    let { staffs, instance, search, openDialog } = this.state;

    return (
      <Wrapper>
        <h2>人員管理</h2>

        {(() => {
          if (instance) {
            return (
              <StaffDetailView
                onClose={async () => {
                  let staffs = await this._fetchStaffs();
                  staffs = staffs.map(s => ({
                    ...s,
                    id: staffNumberFormat(s.id)
                  }));
                  this.setState({ staffs });
                  this.setState({ instance: null });
                }}
                staffModel={staffModel}
                instance={instance}
              />
            );
          }

          return (
            <>
              <div className="align-container">
                <div className="search-wrapper">
                  <input
                    placeholder={`共${staffs.length}位員工`}
                    value={search}
                    onChange={e => this.setState({ search: e.target.value })}
                  />
                  <div className="icon-wrapper">
                    <Icon.Search
                      size={24}
                      css="margin-right: 10px;"
                      color={AppConfig.global.themeColor}
                    />
                  </div>
                </div>

                {/* <div className="buttons-wrapper">
                  <Widget.CreateButton
                    onClick={() => this.setState({ openDialog: true })}
                  >
                    <div className="icon">
                      <Icon.Add size={24} color={"white"} />
                    </div>
                    <span>新增人員</span>
                  </Widget.CreateButton>
                </div> */}
              </div>

              <div className="table-container">
                {staffs && (
                  <Table
                    model={staffModel}
                    instances={staffs.filter(s => {
                      return SEARCH_FIELDS.some(
                        f => (s[f] ? `${s[f]}` : "").indexOf(search) > -1
                      );
                    })}
                    onCellClick={this._onCellClick}
                    activeInstanceId={instance && instance.id}
                  />
                )}
              </div>
            </>
          );
        })()}

        {openDialog && (
          <StaffDetailDialog
            target="create"
            title="新增人員資料"
            open={openDialog}
            onClose={() => this.setState({ openDialog: false })}
            refreshStaffs={async () => {
              let staffs = await this._fetchStaffs();
              this.setState({ staffs });
            }}
          />
        )}
      </Wrapper>
    );
  }

  _fetchStaffs = async () => {
    let { appActions } = this.props;
    return await appActions.getStaffList();
  };

  _onCellClick = ({ instance }) => {
    if (
      (this.state.instance && instance.id !== this.state.instance.id) ||
      !this.state.instance
    ) {
      this.setState({
        instance
      });
    } else {
      this.setState({
        instance: null
      });
    }
  };
}

const Wrapper = styled.div`
  padding: 0px 30px;

  & > h2 {
    padding: 10px;
    letter-spacing: 3.8px;
    font-weight: 400;
    font-size: 38px;
    color: #737987;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 30px;
  }

  & .tab-container {
    margin-bottom: 30px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
  }

  & .back-button {
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    color: blue;
  }

  & .table-container {
    & .row {
      display: flex;
    }
  }

  & .align-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;

    @media screen and (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  & .search-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 300px;

    & .icon-wrapper {
      position: absolute;
      right: 10px;
    }

    & input {
      width: 100%;
      border: 1px solid #979797;
      border-radius: 6px;
      padding: 8px 10px;
      outline: none;
      color: #101d1f;

      &:focus {
        box-shadow: 0px 0px 0px 1.5px lightgrey;
      }
    }

    @media screen and (max-width: 550px) {
      margin-bottom: 14px;
    }

    @media screen and (max-width: 320px) {
      width: 260px;
    }
  }

  & .buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    & > .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      margin-right: 20px;

      padding: 3px;
      min-width: 80px;
      max-height: 30px;
      text-decoration: none;
      letter-spacing: 1.6px;
      font-size: 16px;
      color: #727886;

      :hover {
        background: #e8e8e8;
      }
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(StaffTable);
