import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Constants from "../../src/Domain/constants";
import * as Icon from "../../src/Components/Icon";
import Table from "../../src/Components/Table";
import AppConfig from "../Config";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget";
import MemberDetailView from "./BuffetMemberDetailView";
import MemberDetailDialog from "./BuffetMemberDetailDialog";

const memberModel = {
  fields: [
    {
      name: "id",
      label: "ID",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "name",
      label: "姓名",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    },
    {
      name: "email",
      label: "電子郵箱",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    },
    {
      name: "phone",
      label: "手機號碼",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    }
  ]
};

const SEARCH_FIELDS = ["id", "name", "phone", "email"];

class MemberTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      instance: null,
      search: "",
      openDialog: false
    };
  }

  async componentDidMount() {
    let members = await this._fetchMembers();
    this.setState({ members });
  }

  render() {
    let { members, instance, search, openDialog } = this.state;

    return (
      <Wrapper>
        <h2>會員管理</h2>

        {(() => {
          if (instance) {
            return (
              <MemberDetailView
                onClose={async () => {
                  let members = await this._fetchMembers();
                  this.setState({ members });
                  this.setState({ instance: null });
                }}
                memberModel={memberModel}
                instance={instance}
              />
            );
          }

          return (
            <>
              <div className="align-container">
                <div className="search-wrapper">
                  <input
                    placeholder={`共${members.length}位會員`}
                    value={search}
                    onChange={e => this.setState({ search: e.target.value })}
                  />
                  <div className="icon-wrapper">
                    <Icon.Search
                      size={24}
                      css="margin-right: 10px;"
                      color={AppConfig.global.themeColor}
                    />
                  </div>
                </div>

                <div className="buttons-wrapper">
                  <Widget.CreateButton
                    onClick={() => this.setState({ openDialog: true })}
                  >
                    <div className="icon">
                      <Icon.Add size={24} color={"white"} />
                    </div>
                    <span>新增會員</span>
                  </Widget.CreateButton>
                </div>
              </div>

              <div className="table-container">
                {members && (
                  <Table
                    model={memberModel}
                    instances={members.filter(m => {
                      return SEARCH_FIELDS.some(
                        f => (m[f] ? `${m[f]}` : "").indexOf(search) > -1
                      );
                    })}
                    onCellClick={this._onCellClick}
                    activeInstanceId={instance && instance.id}
                  />
                )}
              </div>
            </>
          );
        })()}

        {openDialog && (
          <MemberDetailDialog
            target="create"
            title="新增會員"
            open={openDialog}
            onClose={() => this.setState({ openDialog: false })}
            refreshMembers={async () => {
              let members = await this._fetchMembers();
              this.setState({ members });
            }}
          />
        )}
      </Wrapper>
    );
  }

  _fetchMembers = async () => {
    let { appActions } = this.props;
    return await appActions.fetchMembers();
  };

  _onCellClick = ({ instance }) => {
    if (
      (this.state.instance && instance.id !== this.state.instance.id) ||
      !this.state.instance
    ) {
      this.setState({ instance });
    } else {
      this.setState({ instance: null });
    }
  };
}

const Wrapper = styled.div`
  padding: 0px 30px;

  & > h2 {
    padding: 10px;
    letter-spacing: 3.8px;
    font-weight: 400;
    font-size: 38px;
    color: #737987;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 30px;
  }

  & .tab-container {
    margin-bottom: 30px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
  }

  & .back-button {
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    color: blue;
  }

  & .table-container {
    & .row {
      display: flex;
    }
  }

  & .align-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;

    @media screen and (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  & .search-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 300px;

    & .icon-wrapper {
      position: absolute;
      right: 10px;
    }

    & input {
      width: 100%;
      border: 1px solid #979797;
      border-radius: 6px;
      padding: 8px 10px;
      outline: none;
      color: #101d1f;

      &:focus {
        box-shadow: 0px 0px 0px 1.5px lightgrey;
      }
    }

    @media screen and (max-width: 550px) {
      margin-bottom: 14px;
    }

    @media screen and (max-width: 320px) {
      width: 260px;
    }
  }

  & .buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    & > .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      margin-right: 20px;

      padding: 3px;
      min-width: 80px;
      max-height: 30px;
      text-decoration: none;
      letter-spacing: 1.6px;
      font-size: 16px;
      color: #727886;

      :hover {
        background: #e8e8e8;
      }
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(MemberTable);
