import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Icon from "../../src/Components/Icon/Buffet";
import { dateParser } from "../../src/Utils/DateUtil";
import { formatPrice } from "../../src/Utils/PriceFormatter";
import { repairOrderNumberFormat } from "../../src/Utils/NumberingFormatUtil";
import { repairOrderStatusName } from "../../src/Utils/OrderStatusNameUtil";
import { repairOrderMailTemplate } from "../../src/Utils/MailTemplateUtil";
import ActionCreator from ".././ActionCreator";
import * as Widget from "./Widget/Buffet";
import Panel from "./BuffetPanel";
import ConfirmDialog from "./BuffetConfirmDialog";
import ConfirmDeleteActionDialog from "./BuffetConfirmDeleteActionDialog";
import RepairOrderDetailDialog from "./BuffetRepairOrderDetailDialog";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class RepairOrderDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: {
        ...this.props.instance,
        serial_number: repairOrderNumberFormat(this.props.instance)
      },
      history: this.props.history,
      staffList: [],
      showSpinner: false,
      openConfirmDialog: false,
      openConfirmDeleteDialog: false,
      openDetailDialog: false
    };
  }

  componentDidMount() {
    this._fetchStaffList();
  }

  render() {
    let { onClose, orderModel, items } = this.props;
    let {
      instance,
      history,
      staffList,
      showSpinner,
      openConfirmDialog,
      openConfirmDeleteDialog,
      openDetailDialog
    } = this.state;

    return (
      <DetailWrapper>
        <div className="align-container">
          <div className="breadcrumbs">{`訂單管理 > 維修單 > ${instance.serial_number}`}</div>
          <div className="buttons-wrapper">
            <div
              className="button"
              onClick={() => this.setState({ openConfirmDialog: true })}
            >
              <Icon.Email
                size={24}
                css="margin-right: 5px;"
                color={"#727886"}
              />
              通知
            </div>
            <div
              className="button"
              onClick={() => this.setState({ openDetailDialog: true })}
            >
              <Icon.Edit size={24} css="margin-right: 5px;" color={"#727886"} />
              編輯
            </div>
            <div
              className="button"
              onClick={() => this.setState({ openConfirmDeleteDialog: true })}
            >
              <Icon.Trash
                size={24}
                color={"#727886"}
                css="margin-right: 5px;"
              />
              刪除
            </div>
            <Widget.OutlineButton onClick={onClose}>返回</Widget.OutlineButton>
          </div>
        </div>

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <div className="panels-section">
            <div className="panels-container">
              <Panel
                css="margin-bottom: 20px;"
                title="訂單資料"
                children={
                  <ItemContent>
                    {[
                      ...orderModel.fields.filter(
                        f => f.name.indexOf("buyer") === -1
                      ),
                      { name: "staff", label: "製單人" },
                      { name: "amount", label: "結帳金額" },
                      { name: "completed_date", label: "預期完成日" }
                    ].map((f, idx) => (
                      <div className="field" key={idx}>
                        <div className="label">{f.label}</div>
                        <div className="value">
                          {(() => {
                            if (f.name === "created") {
                              return dateParser(instance[f.name]);
                            } else if (f.name === "status") {
                              return repairOrderStatusName(instance[f.name]);
                            } else if (f.name === "url") {
                              return (
                                <a href={instance[f.name]} target="_blank">
                                  {instance[f.name]}
                                </a>
                              );
                            } else if (f.name === "amount") {
                              return `${formatPrice(instance[f.name])} 元`;
                            }
                            return instance[f.name];
                          })()}
                        </div>
                      </div>
                    ))}
                  </ItemContent>
                }
              />
              <Panel
                css="margin-bottom: 20px;"
                title="顧客資訊"
                children={
                  <ItemContent>
                    {[
                      ...orderModel.fields.filter(
                        f => f.name.indexOf("buyer") > -1
                      ),
                      { name: "receiver_address", label: "寄送地址" }
                    ].map((f, idx) => (
                      <div className="field" key={idx}>
                        <div className="label">{f.label}</div>
                        <div className="value">{instance[f.name]}</div>
                      </div>
                    ))}
                  </ItemContent>
                }
              />
            </div>

            <div className="panels-container">
              <Panel
                css="margin-bottom: 20px;"
                title="商品詳情"
                children={
                  <ItemContent css="border-bottom: 1px solid #eaeaea;">
                    <div className="field">
                      <div className="label">尺寸</div>
                      <div className="value">{instance.size}</div>
                    </div>
                    <div className="field">
                      <div className="label">款式</div>
                      <div className="value">{instance.shape}</div>
                    </div>
                    <div className="field">
                      <div className="label">皮革</div>
                      <div className="value">{instance.leather}</div>
                    </div>
                    <div className="field">
                      <div className="label">鞋底</div>
                      <div className="value">{instance.bottom}</div>
                    </div>
                    <div className="field">
                      <div className="label">鞋扣</div>
                      <div className="value">{instance.shoe_buckle}</div>
                    </div>
                    <div className="field">
                      <div className="label">鞋帶</div>
                      <div className="value">{instance.belt}</div>
                    </div>
                  </ItemContent>
                }
              />

              <Panel
                css="margin-bottom: 20px;"
                title="維修單更新紀錄"
                children={
                  <>
                    <ItemContent css="padding: 0px 23px 0px 0px;">
                      <div className="block">
                        <div className="list-container">
                          <div className="line" />
                          <div className="dot" />
                        </div>
                        <div className="text-container">
                          <div className="text">
                            {`${dateParser(instance.created)}\n${
                              instance.staff
                            } 創立維修單`}
                          </div>
                        </div>
                      </div>
                    </ItemContent>
                    {history &&
                      history.map((h, i) => {
                        let data = JSON.parse(h.data);
                        let preHistoryData;

                        if (i > 0) {
                          preHistoryData = JSON.parse(history[i - 1].data);

                          if (data.status !== preHistoryData.status) {
                            return (
                              <ItemContent
                                css="padding: 0px 23px 0px 0px;"
                                key={"common-order-history" + i}
                              >
                                <div className="block">
                                  <div className="list-container">
                                    <div className="line" />
                                    <div className="dot" />
                                  </div>
                                  <div className="text-container">
                                    <div className="text">
                                      {`${dateParser(
                                        h.created
                                      )}\n${this._getStaffName(
                                        staffList,
                                        h.profile
                                      )} 更新維修單狀態為 ${repairOrderStatusName(
                                        data.status
                                      )}`}
                                    </div>
                                  </div>
                                </div>
                              </ItemContent>
                            );
                          }
                        }
                      })}
                  </>
                }
              />
            </div>
          </div>
        )}

        {openConfirmDialog && (
          <ConfirmDialog
            title="寄送維修單成立通知"
            text={`確認成立通知給 購買人 ${instance.buyer_name} 嗎？`}
            open={openConfirmDialog}
            onConfirm={() => this._sendMail()}
            onClose={() => this.setState({ openConfirmDialog: false })}
          />
        )}

        {openDetailDialog && (
          <RepairOrderDetailDialog
            target="update"
            instance={instance}
            title="修改維修單"
            open={openDetailDialog}
            onClose={() => this.setState({ openDetailDialog: false })}
            refreshOrder={() => {
              this._refreshOrderById(instance.id);
            }}
          />
        )}

        {openConfirmDeleteDialog && (
          <ConfirmDeleteActionDialog
            haveMessage={true}
            title="刪除維修單"
            text="確認刪除該維修單嗎？"
            open={openConfirmDeleteDialog}
            onConfirm={() => {
              this._deleteOrderById(instance.id);
            }}
            onClose={() => this.setState({ openConfirmDeleteDialog: false })}
          />
        )}
      </DetailWrapper>
    );
  }

  _sendMail = () => {
    let { appActions } = this.props;
    let { instance } = this.state;

    let data = {
      html: true,
      subject: "BUFFET 維修單成立通知",
      message: repairOrderMailTemplate(instance),
      to: instance.buyer_email
    };

    appActions
      .sendMail(data)
      .then(() => this.setState({ openConfirmDialog: false }))
      .catch(err => console.warn(err));
  };

  _getStaffName = (list, id) => {
    let staff = list.filter(s => s.id === id)[0];

    if (staff) {
      return staff.name;
    } else {
      return "---";
    }
  };

  _fetchStaffList = () => {
    let { appActions } = this.props;

    appActions
      .getStaffList()
      .then(staffList => this.setState({ staffList }))
      .catch(err => console.warn(err));
  };

  _refreshOrderById = id => {
    let { appActions } = this.props;

    this.setState({ showSpinner: true });
    delay(1000)
      .then(() => appActions.getRepairOrderById(id))
      .then(result => this.setState({ instance: result }))
      .then(() => appActions.fetchRepairOrdersHistoryById(id))
      .then(history => this.setState({ history }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };

  _deleteOrderById = async id => {
    let { appActions, onClose } = this.props;

    try {
      await appActions.deleteRepairOrderById(id);
      onClose();
    } catch (err) {
      console.warn(err);
    }
  };
}

const ItemContent = styled.div`
  padding: 10px 23px;

  & .field {
    display: flex;
    align-items: center;
    padding: 8px 0px;

    & .label {
      margin-right: 10px;
      display: block;
      width: 160px;
      color: #19233b;
    }

    & .value {
      color: #101d1f;
    }
  }

  & .block {
    display: flex;

    & .list-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 59px;
      position: relative;

      & .line {
        width: 1px;
        height: 130.5px;
        background: #eaeaea;
      }

      & .dot {
        position: absolute;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background: #8596a6;
      }
    }

    & .text-container {
      flex: 1;
      min-height: 84px;
      margin-top: 16.5px;

      & .text {
        border-radius: 5px;
        padding: 20px;
        background: #eaeaea;
        letter-spacing: 1.6px;
        white-space: pre-wrap;
        font-size: 16px;
        color: #19233b;
      }

      :last-child {
        margin-bottom: 30px;
      }
    }
  }

  ${props => props.css || ""};
`;

const DetailWrapper = styled.div`
  & .align-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .breadcrumbs {
    letter-spacing: 1.51px;
    line-height: 22px;
    font-size: 16px;
    color: #0d1100;
  }

  & .buttons-wrapper {
    & > .button {
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 1.6px;
      font-size: 16px;
      color: #727886;
    }
  }

  & .panels-section {
    display: flex;
    flex-wrap: wrap;

    & > .panels-container {
      max-width: 500px;
      width: 100%;
      padding: 10px 10px 10px 0px;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(RepairOrderDetailView);
