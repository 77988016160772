import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../../src/Components/Table";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget/Buffet";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class FactoryOrderAddRepairOrdersDailog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: null,
      orderIds: [...this.props.orderIds],
      showSpinner: false
    };
  }

  async componentDidMount() {
    let orders = await this._fetchOrders();
    this.setState({ orders });
  }

  render() {
    let { title, instance, orderModel, open, onClose } = this.props;
    let { orders, orderIds, showSpinner } = this.state;

    return (
      <Widget.Dialog
        open={open}
        className={`${open ? "active" : ""}`}
        css={`
          & .container {
            max-width: 1200px;
          }
        `}
      >
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="content">
              <div className="title">{title}</div>
              <div className="table-section">
                {orders ? (
                  orders.length === 0 ? (
                    <div>目前沒有付款完成的維修單！</div>
                  ) : (
                    <Table
                      model={orderModel}
                      instances={orders}
                      onCellSelect={id => this._onCellSelect(id, orderIds)}
                    />
                  )
                ) : (
                  <Widget.Spinner />
                )}
              </div>

              <div className="bottom-section">
                <div className="message" />
                {showSpinner ? (
                  <Widget.Spinner />
                ) : (
                  <div className="buttons">
                    <Widget.WhiteButton onClick={onClose}>
                      取消
                    </Widget.WhiteButton>
                    <Widget.BlueButton
                      onClick={() => this._submit(instance, orderIds)}
                    >
                      確定
                    </Widget.BlueButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Widget.Dialog>
    );
  }

  _onCellSelect = (id, orderIds) => {
    if (orderIds.indexOf(id) === -1) {
      // add
      this.setState({ orderIds: [id, ...orderIds] });
    } else {
      //cancel
      let idIndex = orderIds.indexOf(id);
      orderIds.splice(idIndex, 1);
      this.setState({ orderIds });
    }
  };

  _fetchOrders = async (options = { from: "", to: "" }) => {
    let { appActions } = this.props;
    let orders = await appActions.searchRepairOrders("", {
      from: options.from,
      to: options.to
    });
    return orders.filter(
      o => o.status === "accepted" && o.factory_order === null
    );
  };

  _submit = (instance, orderIds) => {
    let { appActions, onClose, refreshOrder } = this.props;
    let data = {
      id: instance.id,
      title: instance.title,
      status: instance.status,
      rep_order_ids: orderIds
    };

    this.setState({ showSpinner: true });
    delay(500)
      .then(() => appActions.updateFactoryOrderById(data))
      .then(() => this.setState({ showSpinner: false }))
      .then(() => {
        onClose();
        refreshOrder();
      })
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

export default connect(
  null,
  ActionCreator
)(FactoryOrderAddRepairOrdersDailog);
