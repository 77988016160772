import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import AppConfig from "../Config/Buffet";
import * as Icon from "../../src/Components/Icon/Buffet";
import * as Widget from "./Widget/Buffet";
import Panel from "./BuffetPanel";
import StockDetailDialog from "./BuffetStockDetailDialog";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class StockDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: {
        ...this.props.instance
      },
      showSpinner: false,
      openDetailDialog: false,
      staffs: null
    };
  }

  async componentDidMount() {
    let staffs = await this._fetchStaffList();
    this.setState({ staffs });
  }

  render() {
    let { onClose, stockModel, stockTypes } = this.props;
    let { instance, showSpinner, openDetailDialog } = this.state;

    return (
      <DetailWrapper>
        <div className="align-container">
          <div className="breadcrumbs">{`庫存管理 > ${this._getStockTypeName(
            instance["stock_type"]
          )} > 編號 ${instance.serial_number}`}</div>
          <div className="buttons-wrapper">
            <div
              className="button"
              onClick={() => this.setState({ openDetailDialog: true })}
            >
              <Icon.Edit size={24} css="margin-right: 5px;" color={"#727886"} />
              編輯
            </div>
            <Widget.OutlineButton onClick={onClose}>返回</Widget.OutlineButton>
          </div>
        </div>

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <div className="panels-section">
            <div className="panels-container">
              <Panel
                css="margin-bottom: 20px;"
                title="材料資訊"
                children={
                  <ItemContent>
                    {[
                      { name: "serial_number", label: "編號" },
                      { name: "stock_type", label: "材料類別" },
                      { name: "size", label: "材料尺寸" },
                      { name: "unit", label: "材料單位" },
                      { name: "price", label: "參考單價" },
                      { name: "company_name", label: "廠商名稱" },
                      { name: "company_data", label: "廠商資料" }
                    ].map((f, idx) => (
                      <div className="field" key={"staff" + idx}>
                        <div className="label">{f.label}</div>
                        <div className="value">
                          {(() => {
                            if (f.name === "stock_type") {
                              let type = this._getStockTypeName(
                                instance[f.name]
                              );
                              return type;
                            }

                            return instance[f.name];
                          })()}
                        </div>
                      </div>
                    ))}
                  </ItemContent>
                }
              />
            </div>
            <div className="panels-container">
              <Panel
                css="margin-bottom: 20px;"
                title="庫存狀況"
                children={
                  <ItemContent>
                    {[
                      { name: "large_inventory", label: "大庫存" },
                      { name: "small_inventory", label: "小庫存" },
                      { name: "booked_qty", label: "預定雙數" },
                      { name: "safe_qty", label: "預算數量" },
                      { name: "req_qty", label: "還需採購" },
                      { name: "purchase_date", label: "採購時間" },
                      { name: "profile", label: "採購人" },
                      { name: "note", label: "備註" }
                    ].map((f, idx) => (
                      <div className="field" key={"staff" + idx}>
                        <div className="label">{f.label}</div>
                        <div className="value">
                          {(() => {
                            if (f.name === "profile") {
                              let name = this._getStaffName(instance[f.name]);
                              return name;
                            }

                            return instance[f.name];
                          })()}
                        </div>
                      </div>
                    ))}
                  </ItemContent>
                }
              />
            </div>
          </div>
        )}
        {openDetailDialog && (
          <StockDetailDialog
            target="update"
            title="修改庫存"
            open={openDetailDialog}
            instance={instance}
            onClose={() => this.setState({ openDetailDialog: false })}
            stockTypes={stockTypes}
            refreshStock={() => {
              this._refreshStockById(instance.id);
            }}
          />
        )}
      </DetailWrapper>
    );
  }

  _fetchStaffList = async () => {
    let { appActions } = this.props;
    let staffs = await appActions.getStaffList();
    return staffs;
  };

  _getStockTypeName = typeId => {
    let { stockTypes } = this.props;
    let stockType = stockTypes.filter(type => type.id === typeId);
    return stockType[0].name;
  };

  _getStaffName = profileId => {
    let { staffs } = this.state;
    if (!staffs) {
      return;
    } else {
      let staff = staffs.filter(s => s.id === profileId);
      return staff[0].name;
    }
  };

  _refreshStockById = id => {
    let { appActions } = this.props;

    this.setState({ showSpinner: true });
    delay(1000)
      .then(() => appActions.fetchStockRecordById(id))
      .then(result => this.setState({ instance: result }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

const DetailWrapper = styled.div`
  & .back-button {
    cursor: pointer;
    margin-bottom: 20px;
    color: ${AppConfig.global.themeColor};
  }

  & .breadcrumbs {
    letter-spacing: 1.51px;
    line-height: 22px;
    font-size: 16px;
    color: #0d1100;
  }

  & .buttons-wrapper {
    & > .button {
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 1.6px;
      font-size: 16px;
      color: #727886;
    }
  }

  & .panels-section {
    display: flex;
    flex-wrap: wrap;

    & > .panels-container {
      max-width: 500px;
      width: 100%;
      padding: 10px 10px 10px 0px;
    }
  }
`;

const ItemContent = styled.div`
  padding: 10px 23px;

  & .field {
    display: flex;
    align-items: center;
    padding: 8px 0px;

    & .label {
      margin-right: 10px;
      display: block;
      width: 160px;
      color: #19233b;
    }

    & .value {
      color: #101d1f;
    }
  }

  & .block {
    display: flex;

    & .list-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 59px;
      position: relative;

      & .line {
        width: 1px;
        height: 130.5px;
        background: #eaeaea;
      }

      & .dot {
        position: absolute;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background: #8596a6;
      }
    }

    & .text-container {
      flex: 1;
      min-height: 84px;
      margin-top: 16.5px;

      & .text {
        border-radius: 5px;
        padding: 20px;
        background: #eaeaea;
        letter-spacing: 1.6px;
        white-space: pre-wrap;
        font-size: 16px;
        color: #19233b;
      }

      :last-child {
        margin-bottom: 30px;
      }
    }
  }

  ${props => props.css || ""};
`;

export default connect(
  null,
  ActionCreator
)(StockDetailView);
