import React from "react";
import { connect } from "react-redux";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import { getDiffDate, d2s, dateParser } from "../../../src/Utils/DateUtil";
import styled from "styled-components";
import * as Icon from "../../../src/Components/Icon";
import Table from "../../../src/Components/Table";
import JsonParser from "../../../src/Utils/JsonParser";
import AppConfig from "../../Config";

const storeOrderModel = {
  fields: [
    {
      name: "id",
      label: "ID",
      style: {
        flexBasis: 200,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
      }
    },
    {
      name: "amount",
      label: "金額",
      style: {
        flexBasis: 120
      }
    },
    {
      name: "payment_status",
      label: "付款狀態",
      style: {
        flexBasis: 200
      }
    },
    {
      name: "payment_type",
      label: "付款方式",
      style: {
        flexBasis: 150
      }
    },
    { name: "buyer", label: "Buyer" },
    {
      name: "created",
      label: "Created",
      type: "datetime",
      style: {
        flexBasis: 250,
        whiteSpace: "nowrap"
      }
    }
  ]
};

const SEARCH_FIELDS = ["payment_type", "payment_status", "buyer", "id"];

class StoreOrderTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      filterType: "all",
      store: null,
      from: "",
      to: "",
      instance: null,

      search: ""
    };
  }

  async componentDidMount() {
    let { appActions, profile } = this.props;
    if (profile) {
      let store = await appActions.getMyStore();
      if (!store) {
        return;
      }

      // for users in staff site, users always are saved in monolith server, token_type always be 'local'
      let orders = await this._fetchOrders({
        storeId: store.id,
        from: "",
        to: ""
      });
      this.setState({ orders, store });
    }
  }

  render() {
    let { store, orders, filterType, instance, search } = this.state;
    if (!store) {
      return <div>None Store Found</div>;
    }

    if (instance) {
      let { items } = JsonParser(instance.cart, { items: [] });
      return (
        <DetailWrapper>
          <div
            className="back-button"
            onClick={() => this.setState({ instance: null })}
          >
            <Icon.ArrowBack
              size={18}
              color={AppConfig.global.themeColor}
              css="margin-right: 10px;"
            />
            Back to table
          </div>
          <div className="fields">
            {storeOrderModel.fields.map((f, idx) => (
              <div className="field">
                <div className="label">{f.label}</div>
                <div className="value">
                  {f.name === "created"
                    ? dateParser(instance[f.name])
                    : instance[f.name]}
                </div>
              </div>
            ))}
          </div>
          <div className="cart">
            <div className="title">商品細項</div>
            {items.map((item, idx) => (
              <div className="row" key={idx}>
                <div className="cell">{item.product.name}</div>
                <div className="cell">單價 {item.product.price}</div>
                <div className="cell">數量 {item.calculation.quantity}</div>
                <div className="cell">價格 {item.calculation.amount}</div>
              </div>
            ))}
          </div>
        </DetailWrapper>
      );
    }

    return (
      <Wrapper>
        <h2>Store Order Table</h2>
        <FilteringDateWrapper>
          {[
            { value: "all", label: "全部" },
            { value: "today", label: "今日" },
            { value: "past-7-days", label: "過去七天" },
            { value: "past-1-month", label: "過去一個月" }
          ].map((e, idx) => {
            return (
              <div
                key={idx}
                className={
                  "date-option" + (e.value === filterType ? " active" : "")
                }
                onClick={() => {
                  this._onDateFilterClick(e.value);
                }}
              >
                {e.label}
              </div>
            );
          })}
        </FilteringDateWrapper>

        <div className="align-right">
          <div className="search-wrapper">
            <input
              placeholder={`共${orders.length}筆訂單`}
              value={search}
              onChange={e => this.setState({ search: e.target.value })}
            />
            <div className="icon-wrapper">
              <Icon.Search
                size={24}
                css="margin-right: 10px;"
                color={AppConfig.global.themeColor}
              />
            </div>
          </div>
        </div>

        <div className="table-container">
          {orders && (
            <Table
              model={storeOrderModel}
              instances={orders.filter(o =>
                SEARCH_FIELDS.some(f => o[f].indexOf(search) > -1)
              )}
              onCellClick={this._onCellClick}
              activeInstanceId={instance && instance.id}
            />
          )}
        </div>
      </Wrapper>
    );
  }

  _fetchOrders = async ({ storeId, from, to }) => {
    let { appActions } = this.props;
    let orders = await appActions.listStoreOrder({
      store: storeId,
      tokenType: "local", // TODO: should change to 'mono' when rev-cms-sls project config changed
      from,
      to
    });
    return orders;
  };

  _onDateFilterClick = async type => {
    let { store } = this.state;
    let from, to;
    let today = new Date();
    to = d2s(today);

    switch (type) {
      case "today":
        from = to;
        break;
      case "past-7-days":
        from = getDiffDate(7, {
          operator: "-",
          format: "YYYY-MM-DD",
          interval: "days"
        });
        break;
      case "past-1-month":
        from = getDiffDate(1, {
          operator: "-",
          format: "YYYY-MM-DD",
          interval: "months"
        });
        break;
      default:
        break;
    }
    this.setState({ filterType: type });
    let orders = await this._fetchOrders({ storeId: store.id, from, to });
    this.setState({ orders });
  };

  _onCellClick = ({ instance }) => {
    if (
      (this.state.instance && instance.id !== this.state.instance.id) ||
      !this.state.instance
    ) {
      this.setState({
        instance
      });
    } else {
      this.setState({
        instance: null
      });
    }
  };
}

const DetailWrapper = styled.div`
  padding: 20px;
  & .back-button {
    cursor: pointer;
    margin-bottom: 20px;
    color: ${AppConfig.global.themeColor};
  }

  & .field {
    display: flex;
    & .label {
      margin-right: 10px;
      display: block;
      font-weight: 600;
      margin-right: 10px;
      width: 160px;
      color: #a4a8b1;
    }

    & .value {
      padding: 8px 10px;
      color: #101d1f;
    }
  }

  & .cart {
    border-top: 1px solid lightgrey;
    margin-top: 10px;
    padding-top: 10px;
    .title {
      color: #a4a8b1;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .row {
      display: flex;
      padding: 8px 10px;

      & .cell {
        padding: 0px 10px;
      }

      & .cell:last-child {
        flex-grow: 1;
        text-align: right;
      }
    }
  }
`;

const FilteringDateWrapper = styled.div`
  display: flex;
  & .date-option {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 5px;
    padding: 5px 10px;
    border: 1px solid lightgray;
    border-radius: 18px;
    background-color: white;
    color: gray;
    min-width: 80px;
    font-size: 14px;
    cursor: pointer;
  }

  & .date-option.active {
    background-color: grey;
    color: white;
  }
`;

const Wrapper = styled.div`
  padding: 0px 30px;
  & > h2 {
    padding: 10px;
    color: #717785;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 10px;
  }

  & .back-button {
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    color: blue;
  }

  & .table-container {
    & .row {
      display: flex;
    }
  }

  & .align-right {
    display: flex;
    justify-content: flex-end;
  }

  & .search-wrapper {
    display: flex;
    align-items: center;
    padding: 10px;
    position: relative;
    width: 300px;

    & .icon-wrapper {
      position: absolute;
      right: 10px;
    }

    & input {
      width: 100%;
      border: 1px solid #979797;
      border-radius: 6px;
      padding: 8px 10px;
      outline: none;
      color: #101d1f;

      &:focus {
        box-shadow: 0px 0px 0px 1.5px lightgrey;
      }
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(StoreOrderTable);
