import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Constants from "../../src/Domain/constants";
import * as Icon from "../../src/Components/Icon";
import Table from "../../src/Components/Table";
import AppConfig from "../Config";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget";
import StockDetailView from "./BuffetStockDetailView";
import StockDetailDialog from "./BuffetStockDetailDialog";
import ExportCSVBar from "./BuffetExportCSVBar";

const stockModel = {
  fields: [
    {
      name: "serial_number",
      label: "編號",
      style: {
        flexBasis: 230,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        padding: "20px 10px"
      }
    },
    {
      name: "large_inventory",
      label: "大庫存",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "small_inventory",
      label: "小庫存",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "safe_qty",
      label: "預算數量",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "req_qty",
      label: "還需採購",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "created",
      label: "創立時間",
      type: "datetime",
      style: {
        flexBasis: 140,
        padding: "20px 30px"
      }
    }
  ]
};

const SEARCH_FIELDS = [
  "serial_number",
  "large_inventory",
  "small_inventory",
  "safe_qty",
  "req_qty",
  "created"
];

class StockTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: null,
      showSpinner: false,
      stocksTypes: [],
      stocks: [],
      instance: null,
      search: "",
      openDialog: false
    };
  }

  async componentDidMount() {
    let stockTypes = await this._fetchStockTypes();
    let stocks = await this._fetchStockRecords();
    this.setState({ stockTypes, stocks, uiState: stockTypes[0].id });
  }

  render() {
    let {
      uiState,
      stockTypes,
      stocks,
      instance,
      search,
      openDialog
    } = this.state;

    return (
      <Wrapper>
        <h2>庫存管理</h2>

        {(() => {
          if (instance) {
            return (
              <StockDetailView
                onClose={async () => {
                  let stocks = await this._fetchStockRecords();
                  this.setState({ stocks });
                  this.setState({ instance: null });
                }}
                stockTypes={stockTypes}
                stockModel={stockModel}
                instance={instance}
              />
            );
          }

          return !stockTypes ? (
            <Widget.Spinner />
          ) : (
            <>
              <div className="tab-container">
                {stockTypes.map((type, i) => (
                  <Widget.TabItem
                    key={"stock-type" + i}
                    select={uiState === type.id}
                    onClick={() => this.setState({ uiState: type.id })}
                  >
                    {type.name}
                  </Widget.TabItem>
                ))}
              </div>
              <div className="align-container">
                <div className="search-wrapper">
                  <input
                    placeholder={`輸入編號`}
                    value={search}
                    onChange={e => this.setState({ search: e.target.value })}
                  />
                  <div className="icon-wrapper">
                    <Icon.Search
                      size={24}
                      css="margin-right: 10px;"
                      color={AppConfig.global.themeColor}
                    />
                  </div>
                </div>

                <div className="buttons-wrapper">
                  <Widget.CreateButton
                    onClick={() => this.setState({ openDialog: true })}
                  >
                    <div className="icon">
                      <Icon.Add size={24} color={"white"} />
                    </div>
                    <span>新增</span>
                  </Widget.CreateButton>
                </div>
              </div>

              <ExportCSVBar
                apiLink={`${Constants.apiUrl}/api/stock_record/export_csv/`}
              />

              <div className="table-container">
                {stocks && (
                  <Table
                    model={stockModel}
                    instances={stocks
                      .filter(s => s.stock_type === uiState)
                      .filter(s => {
                        return SEARCH_FIELDS.some(
                          f => (s[f] ? `${s[f]}` : "").indexOf(search) > -1
                        );
                      })}
                    onCellClick={this._onCellClick}
                    activeInstanceId={instance && instance.id}
                  />
                )}
              </div>
            </>
          );
        })()}

        {openDialog && (
          <StockDetailDialog
            target="create"
            title="新增庫存"
            open={openDialog}
            onClose={() => this.setState({ openDialog: false })}
            stockTypes={stockTypes}
            refreshStocks={async () => {
              let stocks = await this._fetchStockRecords();
              this.setState({ stocks });
            }}
          />
        )}
      </Wrapper>
    );
  }

  _fetchStockTypes = async () => {
    let { appActions } = this.props;
    let stockTypes = await appActions.fetchStockTypes();
    return stockTypes;
  };

  _fetchStockRecords = async () => {
    let { appActions } = this.props;
    let stocks = await appActions.fetchStockRecords();
    return stocks;
  };

  _onCellClick = ({ instance }) => {
    if (
      (this.state.instance && instance.id !== this.state.instance.id) ||
      !this.state.instance
    ) {
      this.setState({ instance });
    } else {
      this.setState({
        instance: null
      });
    }
  };
}

const Wrapper = styled.div`
  padding: 0px 30px;

  & > h2 {
    padding: 10px;
    letter-spacing: 3.8px;
    font-weight: 400;
    font-size: 38px;
    color: #737987;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 30px;
  }

  & .tab-container {
    margin-bottom: 30px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
  }

  & .back-button {
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    color: blue;
  }

  & .table-container {
    & .row {
      display: flex;
    }
  }

  & .align-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;

    @media screen and (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  & .search-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 300px;

    & .icon-wrapper {
      position: absolute;
      right: 10px;
    }

    & input {
      width: 100%;
      border: 1px solid #979797;
      border-radius: 6px;
      padding: 8px 10px;
      outline: none;
      color: #101d1f;

      &:focus {
        box-shadow: 0px 0px 0px 1.5px lightgrey;
      }
    }

    @media screen and (max-width: 550px) {
      margin-bottom: 14px;
    }

    @media screen and (max-width: 320px) {
      width: 260px;
    }
  }

  & .buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    & > .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      margin-right: 20px;

      padding: 3px;
      min-width: 80px;
      max-height: 30px;
      text-decoration: none;
      letter-spacing: 1.6px;
      font-size: 16px;
      color: #727886;

      :hover {
        background: #e8e8e8;
      }
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(StockTable);
