import React, { Component } from "react";
import { RadialChart } from "react-vis";
import styled from "styled-components";

class PieChart extends Component {
  render() {
    let { data, css } = this.props;

    return (
      <Wrapper css={css}>
        <RadialChart
          colorType={"literal"}
          colorDomain={[0, 100]}
          colorRange={[0, 10]}
          getLabel={d => d.name}
          data={data}
          labelsRadiusMultiplier={0.8}
          labelsStyle={{ fontSize: 18, fill: "white" }}
          showLabels
          style={{ stroke: "#fff", strokeWidth: 2 }}
          width={250}
          height={250}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  ${props => props.css || ""};
`;

export default PieChart;
