import * as _User from "../Reducers/User";
import * as _Store from "../Reducers/Store";
import { namedExportWithMemorizedOnce } from "../../src/Utils/FpUtil";
import ParseQuery from "../../src/Utils/ParseQuery";

let User = namedExportWithMemorizedOnce(_User);
let Store = namedExportWithMemorizedOnce(_Store);

const selectors = {
  getQueryParams: ownProps => ParseQuery(ownProps.location.search),
  getLoginUser: state => User.getLoginUser(state.user),
  getStore: state => Store.getStore(state.store)
};

export default selectors;
