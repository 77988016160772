import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "./Widget/Buffet";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class BuffetConfirmDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinner: false
    };
  }

  render() {
    let { title, text, open, onClose, onConfirm, message } = this.props;
    let { showSpinner } = this.state;

    return (
      <Widget.Dialog open={open} className={`${open ? "active" : ""}`}>
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="content">
              <div className="title">{title}</div>
              <div>{text}</div>

              <div className="bottom-section">
                <div className="message">
                  {message ? (message.display ? message.text : "") : ""}
                </div>
                {showSpinner ? (
                  <Widget.Spinner />
                ) : (
                  <div className="buttons">
                    <Widget.WhiteButton onClick={onClose}>
                      取消
                    </Widget.WhiteButton>
                    <Widget.BlueButton
                      onClick={() => {
                        this.setState({ showSpinner: true });
                        delay(500)
                          .then(() => onConfirm())
                          .then(() => this.setState({ showSpinner: false }))
                          .catch(err => console.warn(err));
                      }}
                    >
                      確定
                    </Widget.BlueButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Widget.Dialog>
    );
  }

  _submit = () => {};
}

const Wrapper = styled.div``;

export default BuffetConfirmDialog;
