import constants from "../Domain/constants";

export function commonOrderMailTemplate(data) {
  return `
            <html>
                <head></head>
                <style>
                    .container {
                        width: 90%;
                        min-height: 700px;
                        padding: 20px;
                        border: 1px solid black;
                    }

                    .title {
                        font-weight: 600;
                        text-align: center;
                    }

                    .button {
                        max-width: 250px;
                        width: 100%;
                        padding: 15px;
                        background-color: black;
                        text-align: center;
                        text-decoration: none;
                        color: white;
                    }

                    .contact {
                        width: 100%;
                        border-top: 1px solid lightgray;
                        display: flex;
                        align-items: center;
                    }

                    .social-media {
                        max-width: 300px;
                        width: 100%;
                    }

                    .info {
                        flex-grow: 1;
                    }

                    .footer {
                        width: 90%;
                        padding: 20px;
                        background-color: lightgray;
                        border: 1px solid lightgray;
                    }

                    .text-center {
                        text-align: center;
                    }

                    .have-margin-bottom {
                        margin-bottom: 40px;
                    }

                    .have-margin-top {
                        margin-top: 40px;
                    }

                    .text-color-gray {
                        color: gray;
                    }
                </style>
                <body>
                    <div class="container">
                        <h1 class="title">OFFICE ®</h1>
                        <p>尊敬的購買人 ${data.buyer_name} 您好：</p>
                        <p>已收到您所訂製的產品款項完成資料。恭喜您經過每個步驟的選擇設計，完成了一雙屬於你個人的藝術品</p>
                        <p>我們將會按照你的設計開始進行製作，並以先後順序與日期分批配送或自取。並在 ${data.completed_date} 前完成貨寄出。也歡迎自取 ${data.completed_date} 起至辦公室領取。</p>
                        <p>謝謝你的喜歡與等候，訂製型態的服務流程是與你一起完成的。</p>
                        <p>感謝您選擇 chenjingkai office。</p>

                        <p>訂單編號：${data.number}</p>
                        <p>物流單編號：${data.logistics_number}</p>
                        <p>完成/配送時間: ${data.completed_date}</p>

                        <p class="have-margin-bottom">如有任何問題，都歡迎與我們聯繫：chenjingkaioffice@gmail.com</p>

                        <a class="button" target="_blank" href="${constants.webUrl}/search">查詢訂單</a>

                        <p class="text-color-gray have-margin-top have-margin-bottom">我們一直不斷追求新方法改進產品定製體驗。為此，辦公室推出 BUFFET 工具。後續還將繼續發佈增加功能。請支持我們持續調整更新直到完美為止。</p>

                        <div class="contact">
                            <div class="social-media">
                                <h3>Follow us</h3>
                                <div><a target="_blank" href="https://www.facebook.com/ChenJingkai">Facebook</a></div>
                                <div><a target="_blank" href="https://www.instagram.com/chenjingkaioffice/?hl=zh-tw">Instagram</a></div>
                            </div>
                            <div class="info">
                                <p>chenjingkai office</p>
                                <p>地址：台北市大安區通安街134號（捷運信義安和 3 號出口）</p>
                                <p>電話：0983-733-838 / 02-2735-5236</p>
                                <p>營業時間：1600-2000 close on Monday</p>
                            </div>
                        </div>
                    </div>

                    <div class="footer">
                        <p class="text-center">您可以參閱完整的服務條款。</p>
                        <p class="text-center">如需了解詳情，請發送郵件至</p>
                        <p class="text-center"> chenjingkaioffice@gmail.com。</p>
                        <p class="text-center">此封為系統統一發出，請勿回覆此郵件。</p>
                        <p class="text-center">© 2019 chenjingkai office</p>
                    </div>
                </body>
            </html>
        `;
}

export function repairOrderMailTemplate(data) {
  return `
            <html>
                <head></head>
                <style>
                    .container {
                        width: 90%;
                        min-height: 700px;
                        padding: 20px;
                        border: 1px solid black;
                    }

                    .title {
                        font-weight: 600;
                        text-align: center;
                    }

                    .button {
                        max-width: 250px;
                        width: 100%;
                        padding: 15px;
                        background-color: black;
                        text-align: center;
                        text-decoration: none;
                        color: white;
                    }

                    .contact {
                        width: 100%;
                        border-top: 1px solid lightgray;
                        display: flex;
                        align-items: center;
                    }

                    .social-media {
                        max-width: 300px;
                        width: 100%;
                    }

                    .info {
                        flex-grow: 1;
                    }

                    .footer {
                        width: 90%;
                        padding: 20px;
                        background-color: lightgray;
                        border: 1px solid lightgray;
                    }

                    .text-center {
                        text-align: center;
                    }

                    .have-margin-bottom {
                        margin-bottom: 40px;
                    }

                    .have-margin-top {
                        margin-top: 40px;
                    }

                    .text-color-gray {
                        color: gray;
                    }
                </style>
                <body>
                    <div class="container">
                        <h1 class="title">OFFICE ®</h1>
                        <p>尊敬的購買人 ${data.buyer_name} 您好：</p>
                        <p class="have-margin-top have-margin-bottom">我們已經根據以下訊息創建了維修/保固訂單。</p>
                        <p>商店名稱：chenjingkai office</p>
                        <p>維修編號：${data.serial_number}</p>
                        <p>客戶姓名：${data.buyer_name}</p>
                        <p>產品型號：${data.size}-${data.shape}-${data.leather}-${data.bottom}-${data.shoe_buckle}-${data.belt}</p>
                        <p class="have-margin-bottom">維修完成/配送時間：${data.completed_date}</p>

                        <a class="button" target="_blank" href="${constants.webUrl}/search">查詢訂單</a>

                        <p class="text-color-gray have-margin-top have-margin-bottom">我們一直不斷追求新方法改進產品定製體驗。為此，辦公室推出 BUFFET 工具。後續還將繼續發佈增加功能。請支持我們持續調整更新直到完美為止。</p>

                        <div class="contact">
                            <div class="social-media">
                                <h3>Follow us</h3>
                                <div><a target="_blank" href="https://www.facebook.com/ChenJingkai">Facebook</a></div>
                                <div><a target="_blank" href="https://www.instagram.com/chenjingkaioffice/?hl=zh-tw">Instagram</a></div>
                            </div>
                            <div class="info">
                                <p>chenjingkai office</p>
                                <p>地址：台北市大安區通安街134號（捷運信義安和 3 號出口）</p>
                                <p>電話：0983-733-838 / 02-2735-5236</p>
                                <p>營業時間：1600-2000 close on Monday</p>
                            </div>
                        </div>
                    </div>

                    <div class="footer">
                        <p class="text-center">您可以參閱完整的服務條款。</p>
                        <p class="text-center">如需了解詳情，請發送郵件至</p>
                        <p class="text-center"> chenjingkaioffice@gmail.com。</p>
                        <p class="text-center">此封為系統統一發出，請勿回覆此郵件。</p>
                        <p class="text-center">© 2019 chenjingkai office</p>
                    </div>
                </body>
            </html>
        `;
}
