import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../ActionCreator";
import AppConfig from "../../Config";
import Image from "../../../src/Components/Image";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {}
    };
  }

  render() {
    let { values } = this.state;

    return (
      <Wrapper>
        <div className="left-section">
          <div className="wording">
            {AppConfig["Components/LoginForm"].wording || "Welcome!"}
          </div>
        </div>
        <div className="right-section">
          <Image
            background
            src={
              AppConfig["Components/LoginForm"].logo ||
              "../../../images/revtel-logo.png"
            }
            css={"width: 150px; height: 150px; margin: 20px;"}
          />
          <div className="login-form">
            <input
              placeholder="帳號"
              name="username"
              value={values.username || ""}
              onChange={e =>
                this.setState({
                  values: { ...values, username: e.target.value }
                })
              }
            />

            <input
              placeholder="密碼"
              name="password"
              type="password"
              value={values.password || ""}
              onChange={e =>
                this.setState({
                  values: { ...values, password: e.target.value }
                })
              }
            />
            <div className="login-button" onClick={this._login}>
              登入
            </div>
          </div>
        </div>
        <div className="made-by">
          This awsome product is all made by love and
          <Image
            src={"../../../images/revtel-logo-grey.png"}
            css={"width: 160px;height: 35px; margin-left: 10px;"}
          />
        </div>
      </Wrapper>
    );
  }

  _login = () => {
    let {
      values: { username, password }
    } = this.state;
    if (!username || !password) {
      return;
    }

    let { appActions } = this.props;
    appActions
      .login({ username, password })
      .then(() => appActions.getMyStore())
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  display: flex;
  height: 100vh;

  & > .left-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${AppConfig["Components/LoginForm"].leftBackground ||
      "lightblue"};

    & > .wording {
      width: 70%;
      font-size: 38px;
      font-weight: bold;
      color: white;
      line-height: 73px;
    }
  }

  & > .right-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & > .login-form {
      display: flex;
      margin-bottom: 150px;
      align-items: center;
      flex-direction: column;

      input {
        width: 280px;
        margin: 10px;
        border-radius: 6px;
        outline: none;
        border: 1px solid #95a7b1;
        padding: 7px 12px;
      }
    }

    & .login-button {
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
      padding: 8px;
      width: 100px;
      color: white;
      background-color: #64bf7a;
      cursor: pointer;
    }
  }

  & .made-by {
    display: flex;
    white-space: nowrap;
    align-items: flex-end;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    color: rgba(190, 190, 190, 0.7);
    letter-spacing: 1.6px;

    @media screen and (max-width: 600px) {
      margin: 10px;
      font-size: 10px;
      bottom: -40px;
      white-space: unset;
      flex-wrap: wrap;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  ${AppConfig["Components/LoginForm"].css || ""}
`;

export default connect(
  null,
  ActionCreator
)(LoginForm);
