import styled from "styled-components";
import {
  AccountCircle,
  Lock,
  ArrowBack,
  Close as _Close,
  AddCircle as _AddCircle,
  ArrowDropDown as _ArrowDropDown
} from "styled-icons/material";

const Close = styled(_Close)`
  color: ${props => props.color || "black"};
  ${props => props.css}
`;

const AddCircle = styled(_AddCircle)`
  color: ${props => props.color || "black"};
  ${props => props.css}
`;

const ArrowDropDown = styled(_ArrowDropDown)`
  color: ${props => props.color || "black"};
  ${props => props.css}
`;

export { AccountCircle, Lock, ArrowBack, Close, AddCircle, ArrowDropDown };
