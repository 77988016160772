import React from "react";
import ActionCreator from "../../ActionCreator";
import ProductForm from "../../Components/ProductForm";
import CmsTableFactory from "../../../src/Components/CmsTableFactory";

const ProductTable = CmsTableFactory({
  title: "Product",
  actionFetch: "fetchProducts",
  sortType: "products",
  FormComp: ProductForm,
  ActionCreator,
  css: "padding: 0px 30px;",
  model: {
    fields: [
      { name: "id", label: "ID" },
      {
        name: "name",
        label: "Name",
        style: { flexBasis: 200 }
      },
      { name: "price", label: "Price" },
      {
        name: "created",
        label: "Created",
        type: "datetime",
        style: { flexBasis: 150 }
      },
      {
        name: "updated",
        label: "Updated",
        type: "datetime",
        style: { flexBasis: 150 }
      },
      {
        name: "description",
        label: "Description",
        style: { flexBasis: 200 }
      }
    ]
  }
});

export default ProductTable;
