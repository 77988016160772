import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { formatPrice } from "../../../src/Utils/PriceFormatter";
import ActionCreator from "../../ActionCreator";
import * as Widget from "../Widget";
import HomeTableItem from "../HomeTableItem";
import PieChart from "../PieChart";

class HomeTableContent extends Component {
  constructor(props) {
    super(props);
    let { duration } = this.props;
    this.state = {
      selectedDutation: duration,
      itemData: this._fetchData(duration)
    };
  }

  componentDidMount() {
    this._fetchProducts();
  }

  render() {
    let { duration } = this.props;
    let { selectedDutation, itemData } = this.state;

    if (selectedDutation !== duration) {
      this.setState({
        selectedDutation: duration,
        itemData: this._fetchData(duration)
      });
    }

    return (
      <Wrapper css="margin-top: 40px;">
        <h2>Realtime Message Data</h2>
        <div className="items-container">
          <Widget.Row
            css={`
              max-width: 540px;
              width: 100%;
              @media screen and (max-width: 1000px) {
                margin-bottom: 20px;
              }
              @media screen and (max-width: 550px) {
                flex-wrap: wrap;
                justify-content: center;
              }
            `}
          >
            {itemData
              .filter((_, i) => i <= 1)
              .map((d, i) => (
                <HomeTableItem
                  key={i}
                  data={d}
                  css={
                    (i === 0 &&
                      `
                                        margin-right: 20px;
                                        @media screen and (max-width: 550px) {
                                            margin-right: 0px;
                                            margin-bottom: 20px;
                                        }
                                    `) ||
                    (i === 1 &&
                      `
                        margin-right: 20px;
                        @media screen and (max-width: 1000px) {
                            margin-right: 0px;
                        }
                      `)
                  }
                />
              ))}
          </Widget.Row>

          <Widget.Row
            css={`
              max-width: 540px;
              width: 100%;
              @media screen and (max-width: 550px) {
                flex-wrap: wrap;
                justify-content: center;
              }
            `}
          >
            {itemData
              .filter((_, i) => i > 1)
              .map((d, i) => (
                <HomeTableItem
                  key={i}
                  data={d}
                  css={
                    i === 0 &&
                    `
                      margin-right: 20px;
                      @media screen and (max-width: 550px) {
                      margin-right: 0px;
                      margin-bottom: 20px;
                      }
                    `
                  }
                />
              ))}
          </Widget.Row>
          <Widget.Container css="flex: 1;" />
        </div>
        <div className="chart-container">
          <PieChart
            css={`
              margin-right: 20px;
              @media screen and (max-width: 550px) {
                margin-right: 0px;
                margin-bottom: 20px;
              }
            `}
            data={[
              //FAKE DATA!!!
              { angle: 1, color: "#eadca6", name: "25%" },
              { angle: 2, color: "#e2c275", name: "50%" },
              { angle: 1, color: "#c36a2d", name: "25%" }
            ]}
          />
          <PieChart
            data={[
              //FAKE DATA!!!
              { angle: 1, color: "#f1d6ab", name: "25%" },
              { angle: 2, color: "#f0e3c4", name: "50%" },
              { angle: 1, color: "#daa592", name: "25%" }
            ]}
          />
        </div>
      </Wrapper>
    );
  }

  _fetchData = (duration, products = {}) => {
    return [
      {
        icon: "",
        name: "交易總額",
        number: `$${formatPrice(468290)}`,
        unit: `/${duration}`,
        colors: ["#D78F11", "#AE5607"]
      },
      {
        icon: "",
        name: "訂單數量",
        number: null,
        unit: `筆/${duration}`,
        colors: ["#E3CC29", "#C29D12"]
      },
      {
        icon: "",
        name: "平均客單價",
        number: `$${formatPrice(1539)}`,
        unit: `/${duration}`,
        colors: ["#43CFA9", "#20A170"]
      },
      {
        icon: "",
        name: "商品數量",
        number: products ? products.count : null,
        unit: `件`,
        colors: ["#43BCCF", "#2087A1"]
      }
    ];
  };

  _fetchProducts = () => {
    let { duration, storeId, appActions } = this.props;

    appActions
      .fetchProductsByStoreId(storeId)
      .then(products =>
        this.setState({ itemData: this._fetchData(duration, products) })
      )
      .catch(err => console.warn(err));
  };
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  & > h2 {
    margin-bottom: 30px;
    font-size: 24px;
    color: rgba(25, 35, 59, 0.6);
  }

  & > .items-container {
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
    }
  }

  & > .chart-container {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 550px) {
      flex-direction: column;
    }
  }

  ${props => props.css || ""};
`;

export default connect(
  null,
  ActionCreator
)(HomeTableContent);
