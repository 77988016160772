import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AppConfig from "../Config/Buffet";
import { staffNumberFormat } from "../../src/Utils/NumberingFormatUtil";
import * as Icon from "../../src/Components/Icon/Buffet";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget/Buffet";
import Panel from "./BuffetPanel";
import StaffDetailDialog from "./BuffetStaffDetailDialog";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class StaffDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: {
        ...this.props.instance
      },
      showSpinner: false,
      openDetailDialog: false
    };
  }

  render() {
    let { onClose, staffModel } = this.props;
    let { instance, showSpinner, openDetailDialog } = this.state;

    return (
      <DetailWrapper>
        <div className="align-container">
          <div className="breadcrumbs">{`人員管理 > 員工 > ID${instance.id}`}</div>
          <div className="buttons-wrapper">
            <div
              className="button"
              onClick={() => this.setState({ openDetailDialog: true })}
            >
              <Icon.Edit size={24} css="margin-right: 5px;" color={"#727886"} />
              編輯
            </div>
            <Widget.OutlineButton onClick={onClose}>返回</Widget.OutlineButton>
          </div>
        </div>

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <div className="panels-section">
            <div className="panels-container">
              <Panel
                css="margin-bottom: 20px;"
                title="人員資料"
                children={
                  <ItemContent>
                    {[...staffModel.fields].map((f, idx) => (
                      <div className="field" key={"staff" + idx}>
                        <div className="label">{f.label}</div>
                        <div className="value">
                          {(() => {
                            if (f.type === "nested") {
                              return instance[f.name][f.key]
                                ? f.extra.displayMap.true
                                : f.extra.displayMap.false;
                            }

                            return instance[f.name];
                          })()}
                        </div>
                      </div>
                    ))}
                  </ItemContent>
                }
              />
            </div>
          </div>
        )}

        {openDetailDialog && (
          <StaffDetailDialog
            target="update"
            title="修改人員資料"
            instance={instance}
            open={openDetailDialog}
            onClose={() => this.setState({ openDetailDialog: false })}
            refreshStaff={() => {
              this._refreshStaffById(instance.id);
            }}
          />
        )}
      </DetailWrapper>
    );
  }

  _refreshStaffById = id => {
    let { appActions } = this.props;

    this.setState({ showSpinner: true });
    delay(1000)
      .then(() => appActions.getStaffById(id))
      .then(result =>
        this.setState({
          instance: { ...result, id: staffNumberFormat(result.id) }
        })
      )
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

const DetailWrapper = styled.div`
  & .back-button {
    cursor: pointer;
    margin-bottom: 20px;
    color: ${AppConfig.global.themeColor};
  }
`;

const ItemContent = styled.div`
  padding: 10px 23px;

  & .field {
    display: flex;
    align-items: center;
    padding: 8px 0px;

    & .label {
      margin-right: 10px;
      display: block;
      width: 160px;
      color: #19233b;
    }

    & .value {
      color: #101d1f;
    }
  }

  & .block {
    display: flex;

    & .list-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 59px;
      position: relative;

      & .line {
        width: 1px;
        height: 130.5px;
        background: #eaeaea;
      }

      & .dot {
        position: absolute;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background: #8596a6;
      }
    }

    & .text-container {
      flex: 1;
      min-height: 84px;
      margin-top: 16.5px;

      & .text {
        border-radius: 5px;
        padding: 20px;
        background: #eaeaea;
        letter-spacing: 1.6px;
        white-space: pre-wrap;
        font-size: 16px;
        color: #19233b;
      }

      :last-child {
        margin-bottom: 30px;
      }
    }
  }

  ${props => props.css || ""};
`;

export default connect(
  null,
  ActionCreator
)(StaffDetailView);
