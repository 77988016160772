import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import JsonParser from "../../src/Utils/JsonParser";
import { commonOrderNumberForamt } from "../../src/Utils/NumberingFormatUtil";
import ActionCreator from "../ActionCreator";
import AppConfig from "../Config";
import * as Widget from "./Widget/Buffet";
import * as Icon from "../../src/Components/Icon";
import Table from "../../src/Components/Table";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

const SEARCH_FIELDS = [
  "number",
  "buyer_name",
  "buyer_phone",
  "buyer_email",
  "created"
];

class FactoryOrderAddOrdersDailog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: null,
      orderIds: [...this.props.orderIds],
      showSpinner: false,

      search: "",
      message: {
        display: false,
        text: ""
      }
    };
  }

  async componentDidMount() {
    let orders = await this._fetchOrders();
    orders = orders.map(o => ({ ...o, number: commonOrderNumberForamt(o) }));
    this.setState({ orders });
  }

  render() {
    let { title, instance, orderModel, open, onClose } = this.props;
    let { orders, orderIds, showSpinner, search, message } = this.state;

    return (
      <Widget.Dialog
        open={open}
        className={`${open ? "active" : ""}`}
        css={`
          & .container {
            max-width: 1200px;
          }
        `}
      >
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="content">
              <div className="title" style={{ marginBottom: 10 }}>
                {title}
              </div>

              <Widget.Row
                justify="space-between"
                align="center"
                css="margin-bottom: 120x;"
              >
                <SearchBar>
                  <div className="search-wrapper">
                    <input
                      placeholder={`共${orders ? orders.length : ""}筆訂單`}
                      value={search}
                      onChange={e => this.setState({ search: e.target.value })}
                    />
                    <div className="icon-wrapper">
                      <Icon.Search
                        size={24}
                        css="margin-right: 10px;"
                        color={AppConfig.global.themeColor}
                      />
                    </div>
                  </div>
                  <span style={{ fontSize: 10, color: "rgba(25,35,59,0.6)" }}>
                    可搜尋編號、顧客姓名、顧客電話、顧客電郵、創立時間
                  </span>
                </SearchBar>

                {orders && (
                  <Widget.Row
                    align="center"
                    css="cursor: pointer;"
                    onClick={() => this._onCellAllSelect(orders, orderIds)}
                  >
                    {this._isAllSelected(orders, orderIds) ? (
                      <Icon.CheckBox size={22} color="rgba(25,35,59,0.6)" />
                    ) : (
                      <Icon.CheckBoxOutlineBlank
                        size={22}
                        color="rgba(25,35,59,0.6)"
                      />
                    )}
                    <span
                      style={{
                        marginLeft: 5,
                        fontSize: 16,
                        color: "rgba(25,35,59,0.6)"
                      }}
                    >
                      全部勾選
                    </span>
                  </Widget.Row>
                )}
              </Widget.Row>

              <div className="table-section">
                {orders ? (
                  orders.length === 0 ? (
                    <div>目前沒有已付訂金、尾款結清的訂單！</div>
                  ) : (
                    <Table
                      model={orderModel}
                      instances={orders.filter(o => {
                        return SEARCH_FIELDS.some(
                          f => (o[f] ? `${o[f]}` : "").indexOf(search) > -1
                        );
                      })}
                      onCellSelect={id => this._onCellSelect(id, orderIds)}
                      isSelectedFunc={instance => {
                        return orderIds.indexOf(instance.id) !== -1;
                      }}
                    />
                  )
                ) : (
                  <Widget.Spinner />
                )}
              </div>

              <div className="bottom-section">
                <div className="message">{message.display && message.text}</div>
                {showSpinner ? (
                  <Widget.Spinner />
                ) : (
                  <div className="buttons">
                    <Widget.WhiteButton onClick={onClose}>
                      取消
                    </Widget.WhiteButton>
                    <Widget.BlueButton
                      onClick={() => this._submit(instance, orderIds)}
                    >
                      確定
                    </Widget.BlueButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Widget.Dialog>
    );
  }

  _isAllSelected = (orders, orderIds) => {
    let fetchOrderIds = orders.map(o => o.id);
    let result = [];

    fetchOrderIds.forEach((element, idx, array) => {
      if (orderIds.indexOf(element) > -1) {
        result.push(true);
      } else {
        result.push(false);
      }
    });

    return result.every(element => element === true);
  };

  _onCellAllSelect = (orders, orderIds) => {
    if (this._isAllSelected(orders, orderIds)) {
      this.setState({ orderIds: [...this.props.orderIds] });
      return;
    } else {
      let fetchOrderIds = orders.map(o => o.id);

      fetchOrderIds.forEach((element, idx, array) => {
        if (orderIds.indexOf(element) === -1) {
          orderIds.push(element);
          this.setState({ orderIds });
        }
      });

      return;
    }
  };

  _onCellSelect = (id, orderIds) => {
    if (orderIds.indexOf(id) === -1) {
      //add
      this.setState({ orderIds: [id, ...orderIds] });
    } else {
      //cancel
      let idIndex = orderIds.indexOf(id);
      orderIds.splice(idIndex, 1);
      this.setState({ orderIds });
    }
  };

  _fetchOrders = async (options = { from: "", to: "" }) => {
    let { appActions } = this.props;
    let orders = await appActions.searchOrders("", {
      from: options.from,
      to: options.to
    });

    return orders
      .filter(o => o.factory_order === null)
      .filter(o => o.status === "deposit_paid" || o.payment_balance === true);
  };

  _submit = (instance, orderIds) => {
    let { appActions, onClose, refreshOrder } = this.props;
    let data = {
      id: instance.id,
      title: instance.title,
      status: instance.status,
      order_ids: orderIds
    };

    if (instance.status === "in_production") {
      this.setState({
        message: {
          display: true,
          text: "工廠單狀態已為「製作中」，若需新增訂單，請重新建立工廠單。"
        }
      });
      return;
    } else if (instance.status === "in_inspection") {
      this.setState({
        message: {
          display: true,
          text: "工廠單狀態已為「整貨中」，若需新增訂單，請重新建立工廠單。"
        }
      });
      return;
    }

    this.setState({ showSpinner: true });
    delay(500)
      .then(() => appActions.updateFactoryOrderById(data))
      .then(() => this.setState({ showSpinner: false }))
      .then(() => {
        onClose();
        refreshOrder();
      })
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

const SearchBar = styled.div`
  & .search-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 300px;

    & .icon-wrapper {
      position: absolute;
      right: 10px;
    }

    & input {
      width: 100%;
      border: 1px solid #979797;
      border-radius: 6px;
      padding: 8px 10px;
      outline: none;
      color: #101d1f;

      &:focus {
        box-shadow: 0px 0px 0px 1.5px lightgrey;
      }
    }

    @media screen and (max-width: 550px) {
      margin-bottom: 14px;
    }

    @media screen and (max-width: 320px) {
      width: 260px;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(FactoryOrderAddOrdersDailog);
