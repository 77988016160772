import React, { Component } from "react";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { getDiffDate, d2s } from "../../src/Utils/DateUtil";
import styled from "styled-components";
import Constants from "../../src/Domain/constants";
import JsonParser from "../../src/Utils/JsonParser";
import { commonOrderNumberForamt } from "../../src/Utils/NumberingFormatUtil";
import { orderStatusName } from "../../src/Utils/OrderStatusNameUtil";
import * as Icon from "../../src/Components/Icon";
import * as Widget from "./Widget";
import Table from "../../src/Components/Table";
import AppConfig from "../Config";
import CommonOrederDetailView from "./BuffetCommonOrderDetailView";
import ExportCSVBar from "./BuffetExportCSVBar";
import ConfirmDialog from "./BuffetConfirmDialog";
import { calcFinalAmount } from "../../src/Utils/calcFinalAmount";
import CommonCreateDialog from "./BuffetCommonOrderCreateDialog";

const orderModel = {
  fields: [
    {
      name: "number",
      label: "編號",
      style: {
        flexBasis: 230,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        padding: "20px 10px"
      }
    },
    {
      name: "status",
      label: "訂單狀態",
      type: "dropdown",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      },
      extra: {
        dataMap: [
          { display: "未付款", value: "waiting" },
          { display: "留單", value: "confirming" },
          { display: "已付訂金", value: "deposit_paid" },
          { display: "製作中", value: "in_production" },
          { display: "整理檢查", value: "in_inspection" },
          { display: "可店取", value: "store_pickup" },
          { display: "已寄送", value: "delivered" },
          { display: "已完成", value: "completed" }
        ]
      }
    },
    {
      name: "payment_balance",
      label: "尾款狀態",
      type: "boolean",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      },
      extra: {
        displayMap: [
          { display: "結清", value: true },
          { display: "未結清", value: false }
        ]
      }
    },
    {
      name: "final_amount",
      label: "尾款金額",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "buyer_name",
      label: "顧客姓名",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "buyer_phone",
      label: "顧客電話",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "buyer_email",
      label: "顧客電郵",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    },
    {
      name: "created",
      label: "創立時間",
      type: "datetime",
      style: {
        flexBasis: 140,
        padding: "20px 30px"
      }
    },
    {
      name: "note",
      label: "備註",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    }
  ]
};

const SEARCH_FIELDS = [
  "number",
  "buyer_name",
  "buyer_phone",
  "buyer_email",
  "created",
  "note"
];

class CommonOrderTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      ordersHistory: [],
      filterDateType: "all",
      filterProgressType: "",
      filterDateResult: [], //according to result, to filter orders payment.
      from: "",
      to: "",
      instance: null,

      search: "",

      openConfirmDialog: false,
      openCreateDialog: false,
      selectedListInstance: null,
      message: {
        display: false,
        text: ""
      }
    };
  }

  async componentDidMount() {
    let orders = await this._fetchOrders();
    orders = orders.map(o => ({ ...o, number: commonOrderNumberForamt(o) }));
    let ordersHistory = await this._fetchOrdersHistory();
    this.setState({ orders, filterDateResult: orders, ordersHistory });
  }

  render() {
    let { appActions } = this.props;
    let {
      orders,
      ordersHistory,
      filterDateType,
      filterProgressType,
      filterDateResult,
      from,
      to,
      instance,
      search,
      openConfirmDialog,
      openCreateDialog,
      selectedListInstance,
      message
    } = this.state;

    return (
      <Wrapper>
        {(() => {
          if (instance) {
            let { items } = JsonParser(instance.data, { items: [] });
            let history = ordersHistory.filter(h => h.order === instance.id);
            return (
              <CommonOrederDetailView
                onClose={async () => {
                  let orders = await this._fetchOrders();
                  orders = orders.map(o => ({
                    ...o,
                    number: commonOrderNumberForamt(o)
                  }));
                  this.setState({
                    orders,
                    filterDateResult: orders,
                    filterDateType: "all",
                    filterProgressType: ""
                  });
                  this.setState({ instance: null });
                }}
                orderModel={orderModel}
                instance={instance}
                items={items}
                history={history}
              />
            );
          }

          return (
            <>
              <div className="align-container">
                <div>
                  <div className="search-wrapper">
                    <input
                      placeholder={`共${orders.length}筆訂單`}
                      value={search}
                      onChange={e => this.setState({ search: e.target.value })}
                    />
                    <div className="icon-wrapper">
                      <Icon.Search
                        size={24}
                        css="margin-right: 10px;"
                        color={AppConfig.global.themeColor}
                      />
                    </div>
                  </div>
                  <span style={{ fontSize: 10, color: "rgba(25,35,59,0.6)" }}>
                    可搜尋編號、顧客姓名、顧客電話、顧客電郵、創立時間、備註
                  </span>
                </div>

                <div className="buttons-wrapper">
                  <a className="button" href={`/search`} target="_blank">
                    <Icon.PlaylistAddCheck
                      size={26}
                      css="margin-right: 5px;"
                      color={"#727886"}
                    />
                    url
                  </a>
                  <Widget.CreateButton
                    onClick={() => this.setState({ openCreateDialog: true })}
                  >
                    <div className="icon">
                      <Icon.Add size={24} color={"white"} />
                    </div>
                    <span>新增訂單</span>
                  </Widget.CreateButton>
                </div>
              </div>

              {/* <ExportCSVBar apiLink={`${Constants.apiUrl}/api/report/order/`} /> */}

              <FilteringWrapper>
                {[
                  { value: "title", label: "時間條件：" },
                  { value: "all", label: "全部" },
                  { value: "today", label: "今日" },
                  { value: "past-7-days", label: "過去七天" },
                  { value: "past-1-month", label: "過去一個月" }
                ].map((e, idx) => {
                  if (e.value === "title") {
                    return (
                      <div className="title" key={idx}>
                        {e.label}
                      </div>
                    );
                  }

                  return (
                    <div
                      key={idx}
                      className={
                        "option" + (e.value === filterDateType ? " active" : "")
                      }
                      onClick={() => {
                        this._onDateFilterClick(e.value, filterProgressType);
                      }}
                    >
                      {e.label}
                    </div>
                  );
                })}
              </FilteringWrapper>

              <FilteringWrapper css="margin-bottom: 20px;">
                {[
                  { value: "title", label: "訂單狀態：" },
                  { value: "waiting", label: "未付款" },
                  { value: "confirming", label: "待確認" },
                  { value: "deposit_paid", label: "已付訂金" },
                  { value: "in_production", label: "製作中" },
                  { value: "in_inspection", label: "整理檢查" },
                  { value: "store_pickup", label: "可店取" },
                  { value: "delivered", label: "已寄送" },
                  { value: "completed", label: "已完成" }
                ].map((e, idx) => {
                  if (e.value === "title") {
                    return (
                      <div className="title" key={idx}>
                        {e.label}
                      </div>
                    );
                  }

                  return (
                    <div
                      key={idx}
                      className={
                        "option" +
                        (e.value === filterProgressType ? " active" : "")
                      }
                      onClick={() => {
                        this._onPaymentFilterClick(
                          e.value,
                          filterDateResult,
                          filterProgressType
                        );
                      }}
                    >
                      {e.label}
                    </div>
                  );
                })}
              </FilteringWrapper>

              <div className="table-container">
                {orders && (
                  <Table
                    model={orderModel}
                    instances={orders
                      .filter(o => {
                        return SEARCH_FIELDS.some(
                          f => (o[f] ? `${o[f]}` : "").indexOf(search) > -1
                        );
                      })
                      .map(o => {
                        return {
                          ...o,
                          final_amount: calcFinalAmount(o)
                        };
                      })}
                    onCellClick={this._onCellClick}
                    onCellSelectValueChange={this._onCellSelectValueChange}
                    activeInstanceId={instance && instance.id}
                  />
                )}
              </div>
            </>
          );
        })()}
        {openConfirmDialog && (
          <ConfirmDialog
            title={"修改訂單狀態"}
            text={`${
              selectedListInstance.title
            } 確認修改訂單狀態為 ${orderStatusName(
              selectedListInstance.status
            )} 嗎？`}
            open={openConfirmDialog}
            message={message}
            onClose={() =>
              this.setState({
                openConfirmDialog: false,
                message: { display: false, text: "" }
              })
            }
            onConfirm={() => {
              let final_amount = calcFinalAmount(selectedListInstance);

              if (
                final_amount !== 0 &&
                selectedListInstance.status === "delivered"
              ) {
                this.setState({
                  message: {
                    display: true,
                    text: "尾款未結清，訂單狀態不得修改為「已寄送」"
                  }
                });
                return;
              }

              if (
                final_amount !== 0 &&
                selectedListInstance.status === "completed"
              ) {
                this.setState({
                  message: {
                    display: true,
                    text: "尾款未結清，訂單狀態不得修改為「已完成」"
                  }
                });
                return;
              }

              appActions
                .updateCommonOrderById(selectedListInstance)
                .then(async () => {
                  let orders = await this._fetchOrders();
                  orders = orders.map(o => ({
                    ...o,
                    number: commonOrderNumberForamt(o)
                  }));
                  this.setState({
                    orders,
                    filterDateResult: orders,
                    filterDateType: "all",
                    filterProgressType: "",
                    openConfirmDialog: false
                  });
                });
            }}
          />
        )}

        {openCreateDialog && (
          <CommonCreateDialog
            title={"建立訂單"}
            open={openCreateDialog}
            onClose={async () => {
              let orders = await this._fetchOrders();
              orders = orders.map(o => ({
                ...o,
                number: commonOrderNumberForamt(o)
              }));
              this.setState({
                orders,
                filterDateResult: orders,
                filterDateType: "all",
                filterProgressType: ""
              });
              this.setState({ openCreateDialog: false });
            }}
          />
        )}
      </Wrapper>
    );
  }

  _onCellSelectValueChange = (value, instance) => {
    this.setState({
      openConfirmDialog: true,
      selectedListInstance: {
        ...instance,
        id: instance.id,
        title: instance.title,
        amount: instance.amount,
        status: value
      }
    });
  };

  _fetchOrders = async (options = { from: "", to: "" }) => {
    let { appActions } = this.props;
    let orders = await appActions.searchOrders("", {
      from: options.from,
      to: options.to
    });

    return orders
      .sort((a, b) => b.id - a.id)
      .filter(o => o.cancel_this_order === false);
  };

  _fetchOrdersHistory = async () => {
    let { appActions } = this.props;
    let ordersHistory = await appActions.fetchOrdersHistory();

    return ordersHistory;
  };

  _onDateFilterClick = async (type, progress) => {
    let from, to;
    let today = new Date();
    to = d2s(today);

    switch (type) {
      case "today":
        from = to;
        break;
      case "past-7-days":
        from = getDiffDate(7, {
          operator: "-",
          format: "YYYY-MM-DD",
          interval: "days"
        });
        break;
      case "past-1-month":
        from = getDiffDate(1, {
          operator: "-",
          format: "YYYY-MM-DD",
          interval: "months"
        });
        break;
      default:
        break;
    }
    this.setState({ filterDateType: type, from, to });
    let orders = await this._fetchOrders({ from, to });
    orders = orders.map(o => ({ ...o, number: commonOrderNumberForamt(o) }));

    if (progress) {
      orders = orders.filter(o => o.status === progress);
    }

    this.setState({ orders, filterDateResult: orders });
  };

  _onPaymentFilterClick = (type, orders, currentType) => {
    if (currentType === type) {
      this.setState({ filterProgressType: "", orders });
    } else {
      this.setState({ filterProgressType: type });
      let result = orders.filter(order => order.status === type);
      this.setState({ orders: result });
    }
  };

  _onCellClick = ({ instance }) => {
    if (
      (this.state.instance && instance.id !== this.state.instance.id) ||
      !this.state.instance
    ) {
      this.setState({
        instance
      });
    } else {
      this.setState({
        instance: null
      });
    }
  };
}

const Wrapper = styled.div`
  & .align-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  & .search-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 300px;

    & .icon-wrapper {
      position: absolute;
      right: 10px;
    }

    & input {
      width: 100%;
      border: 1px solid #979797;
      border-radius: 6px;
      padding: 8px 10px;
      outline: none;
      color: #101d1f;

      &:focus {
        box-shadow: 0px 0px 0px 1.5px lightgrey;
      }
    }

    @media screen and (max-width: 550px) {
      margin-bottom: 14px;
    }

    @media screen and (max-width: 320px) {
      width: 260px;
    }
  }

  & .buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    & > .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      margin-right: 20px;

      padding: 3px;
      min-width: 80px;
      max-height: 30px;
      text-decoration: none;
      letter-spacing: 1.6px;
      font-size: 16px;
      color: #727886;

      :hover {
        background: #e8e8e8;
      }
    }

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }
`;

const FilteringWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & .title {
    letter-spacing: 1.6px;
    font-size: 16px;
    color: rgba(25, 35, 59, 0.6);
  }

  & .option {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 5px;
    padding: 5px 10px;
    letter-spacing: 1.6px;
    color: #b5b8bf;
    min-width: 60px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }

  & .option.active {
    color: #4e77e7;
  }

  @media screen and (max-width: 550px) {
    flex-wrap: wrap;
  }

  ${props => props.css || ""};
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(CommonOrderTable);
