import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ItemBrowser from "../ItemBrowser";
import Table from "../Table";
import Paging from "../Paging";
import SortingGroup from "../SortingGroup";
import * as Icon from "../Icon";

const Wrapper = styled.div`
  padding: 0px 30px;
  & > h2 {
    padding: 10px;
    color: #717785;
    border-bottom: 1px solid #e4e4e4;
    margin-bottom: 10px;
  }

  & .back-button {
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    color: blue;
  }

  ${props => props.css}
`;

const UiState = {
  LIST: 0,
  DETAIL: 1
};

const CmsTableFactory = ({
  title,
  model,
  actionFetch,
  FormComp,
  sortType,
  ActionCreator,
  css = "",
  options = {
    skipHeader: false
  }
}) => {
  class GeneratedTable extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        uiState: UiState.LIST,
        query: "",
        instance: null
      };

      this._goBack = () => this.setState({ uiState: UiState.LIST });
    }

    render() {
      let { appActions } = this.props;
      let { query, uiState, instance } = this.state;

      if (uiState === UiState.DETAIL) {
        return (
          <Wrapper css={css}>
            {!options.skipHeader && (
              <>
                <h2>
                  {title} #{instance.id}
                </h2>
                <div className="back-button" onClick={this._goBack}>
                  <Icon.ArrowBack size={18} color={"blue"} />
                  Back to table
                </div>
              </>
            )}

            <FormComp
              instance={instance}
              goBack={this._goBack}
              css="padding: 10px;"
            />
          </Wrapper>
        );
      }

      return (
        <Wrapper>
          <h2>{title} Table</h2>
          <ItemBrowser
            label={""}
            limit={10}
            fetchItems={appActions[actionFetch]}
            navPush={this._updateQueryFromUrl}
            query={query}
          >
            {({ mounted, loading, data, page, sort, search }) => {
              return (
                <>
                  {sort && sortType && (
                    <SortingGroup sort={sort} type={sortType} />
                  )}

                  {data && (
                    <Table
                      model={model}
                      instances={data}
                      onCellClick={this._onCellClick}
                    />
                  )}

                  {page.pages && <Paging page={page} />}
                </>
              );
            }}
          </ItemBrowser>
        </Wrapper>
      );
    }

    _onCellClick = ({ instance }) => {
      this.setState({
        instance,
        uiState: UiState.DETAIL
      });
    };

    _updateQueryFromUrl = url => {
      let query = url.split("?")[1];
      if (query) {
        query = "?" + query;
      }
      this.setState({ query: query || "" });
    };
  }

  return connect(
    null,
    ActionCreator
  )(GeneratedTable);
};

export default CmsTableFactory;
