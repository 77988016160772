import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import AppConfig from "../Config/Buffet";
import JsonParser from "../../src/Utils/JsonParser";
import { commonOrderNumberForamt } from "../../src/Utils/NumberingFormatUtil";
import Table from "../../src/Components/Table";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget/Buffet";
import Panel from "./BuffetPanel";
import CommonOrederDetailView from "./BuffetCommonOrderDetailView";

const orderModel = {
  fields: [
    {
      name: "number",
      label: "編號",
      style: {
        flexBasis: 230,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        padding: "20px 10px"
      }
    },
    {
      name: "status",
      label: "狀態",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      },
      extra: {
        displayMap: {
          waiting: "未付款",
          confirming: "待確認",
          deposit_paid: "已付訂金",
          in_production: "製作中",
          in_inspection: "整理檢查",
          in_delivered: "已寄送"
        }
      }
    },
    {
      name: "buyer_name",
      label: "顧客姓名",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "buyer_phone",
      label: "顧客電話",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "buyer_email",
      label: "顧客電郵",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    },
    {
      name: "created",
      label: "創立時間",
      type: "datetime",
      style: {
        flexBasis: 140,
        padding: "20px 30px"
      }
    }
  ]
};

class MemberDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: {
        ...this.props.instance
      },
      orders: [],
      showOrderSpinner: true,
      showSpinner: false,
      orderDetail: null
    };
  }

  async componentDidMount() {
    let { appActions, instance } = this.props;
    try {
      let orders = await appActions.fetchOrdersByMemberId(instance.id);
      orders = orders.map(o => ({ ...o, number: commonOrderNumberForamt(o) }));
      setTimeout(() => this.setState({ orders, showOrderSpinner: false }), 800);
    } catch (err) {
      console.warn(err);
      this.setState({ showOrderSpinner: false });
    }
  }

  render() {
    let { onClose, memberModel } = this.props;
    let {
      instance,
      orders,
      showSpinner,
      showOrderSpinner,
      orderDetail
    } = this.state;

    if (orderDetail) {
      let { items } = JsonParser(instance.data, { items: [] });
      return (
        <CommonOrederDetailView
          onClose={async () => this.setState({ orderDetail: null })}
          orderModel={orderModel}
          instance={orderDetail}
          items={items}
          member={true}
        />
      );
    }

    return (
      <DetailWrapper>
        <div className="align-container">
          <div className="breadcrumbs">{`會員管理 > 會員 > ID${instance.id}`}</div>
          <div className="buttons-wrapper">
            <Widget.OutlineButton onClick={onClose}>返回</Widget.OutlineButton>
          </div>
        </div>

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <>
            <div className="panels-section">
              <div className="panels-container">
                <Panel
                  css="margin-bottom: 20px;"
                  title="會員資料"
                  children={
                    <ItemContent>
                      {[...memberModel.fields].map((f, idx) => (
                        <div className="field" key={"member" + idx}>
                          <div className="label">{f.label}</div>
                          <div className="value">{instance[f.name]}</div>
                        </div>
                      ))}
                    </ItemContent>
                  }
                />
              </div>
            </div>

            <div className="panels-section">
              <Panel
                css="margin-bottom: 20px; max-width: 100%;"
                title="訂單列表"
                children={
                  showOrderSpinner ? (
                    <ItemContent>
                      <Widget.Spinner />
                    </ItemContent>
                  ) : (
                    <ItemContent>
                      {orders.length === 0 ? (
                        <div className="field">
                          <div className="label">目前沒有任何訂單</div>
                        </div>
                      ) : (
                        <Table
                          model={orderModel}
                          instances={orders}
                          onCellClick={this._onCellClick}
                        />
                      )}
                    </ItemContent>
                  )
                }
              />
            </div>
          </>
        )}
      </DetailWrapper>
    );
  }

  _onCellClick = ({ instance }) => {
    if (
      (this.state.orderDetail &&
        orderDetail.id !== this.state.orderDetail.id) ||
      !this.state.orderDetail
    ) {
      this.setState({
        orderDetail: instance
      });
    } else {
      this.setState({
        orderDetail: null
      });
    }
  };
}

const DetailWrapper = styled.div`
  & .back-button {
    cursor: pointer;
    margin-bottom: 20px;
    color: ${AppConfig.global.themeColor};
  }
`;

const ItemContent = styled.div`
  padding: 10px 23px;

  & .field {
    display: flex;
    align-items: center;
    padding: 8px 0px;

    & .label {
      margin-right: 10px;
      display: block;
      width: 160px;
      color: #19233b;
    }

    & .value {
      color: #101d1f;
    }
  }

  & .block {
    display: flex;

    & .list-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 59px;
      position: relative;

      & .line {
        width: 1px;
        height: 130.5px;
        background: #eaeaea;
      }

      & .dot {
        position: absolute;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background: #8596a6;
      }
    }

    & .text-container {
      flex: 1;
      min-height: 84px;
      margin-top: 16.5px;

      & .text {
        border-radius: 5px;
        padding: 20px;
        background: #eaeaea;
        letter-spacing: 1.6px;
        white-space: pre-wrap;
        font-size: 16px;
        color: #19233b;
      }

      :last-child {
        margin-bottom: 30px;
      }
    }
  }

  ${props => props.css || ""};
`;

export default connect(
  null,
  ActionCreator
)(MemberDetailView);
