import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Table from "../../src/Components/Table";
import * as Icon from "../../src/Components/Icon";
import { dateParser } from "../../src/Utils/DateUtil";
import { commonOrderNumberForamt } from "../../src/Utils/NumberingFormatUtil";
import { factoryOrderStatusName } from "../../src/Utils/OrderStatusNameUtil";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget";
import Panel from "./BuffetPanel";
import ConfirmDeleteActionDialog from "./BuffetConfirmDeleteActionDialog";
import FactoryOrderDetailDialog from "./BuffetFactoryOrderDetailDialog";
import FactoryOrderAddOrdersDialog from "./BuffetFactoryOrderAddOrdersDialog";
import FactoryOrderAddRepairOrdersDialog from "./BuffetFactoryOrderAddRepairOrdersDialog";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class FactoryOrderDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: {
        ...this.props.instance
      },
      selectedIds: [],
      commonOrderIds: this._filterOrderIds(this.props.instance.orders),
      repairOrderIds: this._filterOrderIds(this.props.instance.repair_orders),
      showSpinner: false,
      showOrderSpinner: false,
      showRepairOrderSpinner: false,
      openConfirmDeleteDialog: false,
      openDetailDialog: false,
      openOrdersDialog: false,
      openRepairOrdersDialog: false
    };
  }

  render() {
    let {
      onClose,
      factoryOrderModel,
      orderModel,
      repairOrderModel
    } = this.props;
    let {
      instance,
      commonOrderIds,
      repairOrderIds,
      selectedIds,
      showSpinner,
      showOrderSpinner,
      showRepairOrderSpinner,
      openConfirmDeleteDialog,
      openDetailDialog,
      openOrdersDialog,
      openRepairOrdersDialog
    } = this.state;

    return (
      <DetailWrapper>
        <div className="align-container">
          <div className="fake-container" />
          <Widget.OutlineButton onClick={onClose}>返回</Widget.OutlineButton>
        </div>
        <div className="align-container">
          <div className="breadcrumbs">{`工廠單管理 > ${instance.serial_number}`}</div>
          <div className="buttons-container">
            <div
              className="button"
              onClick={() => this.setState({ openDetailDialog: true })}
            >
              <Icon.Edit size={24} css="margin-right: 5px;" color="#727886" />
              編輯
            </div>
            <a
              className="button"
              href={`/preview?id=${instance.id}`}
              target="_blank"
            >
              <Icon.Export size={24} css="margin-right: 5px;" color="#727886" />
              匯出
            </a>
            <div
              className="button"
              onClick={() => this.setState({ openConfirmDeleteDialog: true })}
            >
              <Icon.Trash size={24} css="margin-right: 5px;" color="#727886" />
              刪除
            </div>
          </div>
        </div>

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <>
            <div className="panels-section">
              <div className="panels-container">
                <Panel
                  css="margin-bottom: 20px;"
                  title="工廠單資料"
                  children={
                    <ItemContent>
                      {[
                        ...factoryOrderModel.fields,
                        { name: "factory", label: "指定工廠" }
                      ].map((f, idx) => (
                        <div className="field" key={idx}>
                          <div className="label">{f.label}</div>
                          <div className="value">
                            {(() => {
                              if (
                                f.name === "created" ||
                                f.name === "updated"
                              ) {
                                return dateParser(instance[f.name]);
                              }

                              if (f.name === "status") {
                                return factoryOrderStatusName(instance[f.name]);
                              }

                              if (f.name === "export_status") {
                                return f.extra.displayMap[
                                  `${instance[f.name]}`
                                ];
                              }
                              return instance[f.name];
                            })()}
                          </div>
                        </div>
                      ))}
                    </ItemContent>
                  }
                />
              </div>

              <div className="panels-container">
                <Panel
                  css="margin-bottom: 20px;"
                  title="作業備註"
                  children={
                    <ItemContent>
                      <div className="field">{instance.note}</div>
                    </ItemContent>
                  }
                />
              </div>
            </div>

            <div className="panels-section">
              <Panel
                css="margin-bottom: 20px; max-width: 100%;"
                title="訂單列表"
                buttons={
                  <>
                    <div
                      className="button"
                      onClick={() =>
                        this._deleteOrders(instance, selectedIds, "commonOrder")
                      }
                    >
                      <Icon.Trash
                        size={24}
                        css="margin-right: 5px;"
                        color="#727886"
                      />
                      刪除
                    </div>
                    <Widget.CreateButton
                      onClick={() => this.setState({ openOrdersDialog: true })}
                    >
                      <div className="icon">
                        <Icon.Add size={24} color={"white"} />
                      </div>
                      <span>新增訂單</span>
                    </Widget.CreateButton>
                  </>
                }
                children={
                  showOrderSpinner ? (
                    <ItemContent>
                      <Widget.Spinner />
                    </ItemContent>
                  ) : (
                    <ItemContent>
                      {instance.orders.length === 0 ? (
                        <div className="field">
                          <div className="label">目前沒有任何訂單</div>
                        </div>
                      ) : (
                        <Table
                          model={orderModel}
                          instances={instance.orders}
                          isSelectedFunc={instance => {
                            return (
                              this.state.selectedIds.indexOf(instance.id) > -1
                            );
                          }}
                          onCellSelect={id => {
                            const idx = this.state.selectedIds.indexOf(id);
                            if (idx === -1) {
                              this.setState({
                                selectedIds: [...this.state.selectedIds, id]
                              });
                            } else {
                              const nextIds = [...this.state.selectedIds];
                              nextIds.splice(idx, 1);
                              this.setState({
                                selectedIds: nextIds
                              });
                            }
                          }}
                        />
                      )}
                    </ItemContent>
                  )
                }
              />
            </div>
          </>
        )}

        {openDetailDialog && (
          <FactoryOrderDetailDialog
            target="update"
            instance={instance}
            title="修改工廠單"
            open={openDetailDialog}
            onClose={() => this.setState({ openDetailDialog: false })}
            refreshOrder={() => {
              this._fetchFactoryOrderById(instance.id);
            }}
          />
        )}

        {openConfirmDeleteDialog && (
          <ConfirmDeleteActionDialog
            haveMessage={true}
            title="刪除工廠單"
            text="確認刪除該工廠單嗎？"
            open={openConfirmDeleteDialog}
            onConfirm={() => {
              this._deleteFactoryOrderById(instance.id);
            }}
            onClose={() => this.setState({ openConfirmDeleteDialog: false })}
          />
        )}

        {openOrdersDialog && (
          <FactoryOrderAddOrdersDialog
            title="新增工廠訂單內容"
            instance={instance}
            orderModel={orderModel}
            orderIds={commonOrderIds}
            open={openOrdersDialog}
            onClose={() => this.setState({ openOrdersDialog: false })}
            refreshOrder={() => {
              this._fetchFactoryOrderById(instance.id);
            }}
          />
        )}

        {openRepairOrdersDialog && (
          <FactoryOrderAddRepairOrdersDialog
            title="新增工廠維修單內容"
            instance={instance}
            orderModel={repairOrderModel}
            orderIds={repairOrderIds}
            open={openRepairOrdersDialog}
            onClose={() => this.setState({ openRepairOrdersDialog: false })}
            refreshOrder={() => {
              this._fetchFactoryOrderById(instance.id);
            }}
          />
        )}
      </DetailWrapper>
    );
  }

  _filterOrderIds = orders => {
    if (orders.length > 0) {
      return orders.map(o => o.id);
    }
    return [];
  };

  _fetchFactoryOrderById = id => {
    let { appActions } = this.props;

    this.setState({ showSpinner: true });
    delay(1000)
      .then(() => appActions.getFactoryOrderById(id))
      .then(result => {
        if (result.orders.length > 0) {
          result.orders = result.orders.map(o => ({
            ...o,
            number: commonOrderNumberForamt(o)
          }));
          this.setState({ instance: result });

          let commonOrderIds = result.orders.map(o => o.id);
          this.setState({ commonOrderIds });
        } else if (result.repair_orders.length > 0) {
          let repairOrderIds = result.repair_orders.map(o => o.id);
          this.setState({ repairOrderIds });
        } else {
          this.setState({ instance: result });
        }
      })
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };

  _deleteOrders = (instance, orderIds, type) => {
    let { appActions } = this.props;

    let instanceOrderIds = instance.orders.map(o => o.id);
    let nextIds = [];

    for (var i = 0; i < instanceOrderIds.length; i++) {
      if (orderIds.indexOf(instanceOrderIds[i]) === -1) {
        nextIds.push(instanceOrderIds[i]);
      }
    }

    if (type === "commonOrder") {
      let data = {
        id: instance.id,
        title: instance.title,
        status: instance.status,
        order_ids: nextIds
      };

      this.setState({ showOrderSpinner: true });
      delay(500)
        .then(() => appActions.updateFactoryOrderById(data))
        .then(() => appActions.getFactoryOrderById(instance.id))
        .then(result => {
          if (result.orders.length > 0) {
            result.orders = result.orders.map(o => ({
              ...o,
              number: commonOrderNumberForamt(o)
            }));
          }

          this.setState({
            instance: result,
            selectedIds: [],
            commonOrderIds: this._filterOrderIds(result.orders)
          });
        })
        .then(() => this.setState({ showOrderSpinner: false }))
        .catch(err => {
          console.warn(err);
          this.setState({ showOrderSpinner: false });
        });
    } else {
      // repair order
      let data = {
        id: instance.id,
        title: instance.title,
        status: instance.status,
        rep_order_ids: orderIds
      };

      this.setState({ showRepairOrderSpinner: true });
      delay(500)
        .then(() => appActions.updateFactoryOrderById(data))
        .then(() => appActions.getFactoryOrderById(instance.id))
        .then(result => this.setState({ instance: result }))
        .then(() => this.setState({ showRepairOrderSpinner: false }))
        .catch(err => {
          console.warn(err);
          this.setState({ showRepairOrderSpinner: false });
        });
    }
  };

  _deleteFactoryOrderById = async id => {
    let { appActions, onClose } = this.props;

    try {
      await appActions.deleteFactoryOrderById(id);
      onClose();
    } catch (err) {
      console.warn(err);
    }
  };
}

const ItemContent = styled.div`
  padding: 10px 23px;

  & .field {
    display: flex;
    align-items: center;
    padding: 8px 0px;

    & .label {
      margin-right: 10px;
      display: block;
      width: 160px;
      color: #19233b;
    }

    & .value {
      color: #101d1f;
    }
  }

  & .block {
    display: flex;

    & .list-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 59px;
      position: relative;

      & .line {
        width: 1px;
        height: 130.5px;
        background: #eaeaea;
      }

      & .dot {
        position: absolute;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background: #8596a6;
      }
    }

    & .text-container {
      flex: 1;
      min-height: 84px;
      margin-top: 16.5px;

      & .text {
        border-radius: 5px;
        padding: 20px;
        background: #eaeaea;
        letter-spacing: 1.6px;
        white-space: pre-wrap;
        font-size: 16px;
        color: #19233b;
      }

      :last-child {
        margin-bottom: 30px;
      }
    }
  }

  ${props => props.css || ""};
`;

const DetailWrapper = styled.div`
  & .align-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  & .breadcrumbs {
    margin-bottom: 10px;
    letter-spacing: 1.51px;
    line-height: 22px;
    font-size: 16px;
    color: #0d1100;
  }

  & .buttons-container {
    display: flex;
    margin-bottom: 10px;

    @media screen and (max-width: 550px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    & .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      margin-right: 20px;

      padding: 3px;
      min-width: 80px;
      max-height: 30px;
      text-decoration: none;
      letter-spacing: 1.6px;
      font-size: 16px;
      color: #727886;

      :hover {
        background: #e8e8e8;
      }

      :last-child {
        margin-right: 0px;
      }

      @media screen and (max-width: 550px) {
        justify-content: flex-start;
        margin-right: 0px;
        margin-bottom: 8px;
        padding: 5px 0px;
      }
    }
  }

  & .panels-section {
    display: flex;
    flex-wrap: wrap;

    & > .panels-container {
      max-width: 500px;
      width: 100%;
      padding: 10px 10px 10px 0px;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(FactoryOrderDetailView);
