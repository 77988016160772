import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Widget from "../Components/Widget";
import CommonOrderTable from "./BuffetCommonOrderTable";
import RepairOrderTable from "./BuffetRepairOrderTable";

const UiState = {
  common: "common",
  repair: "repair"
};

class OrderTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: UiState.common
    };
  }

  render() {
    let { uiState } = this.state;

    return (
      <Wrapper>
        <h2>訂單管理</h2>

        <div className="tab-container">
          <Widget.TabItem
            select={uiState === UiState.common}
            onClick={() => this.setState({ uiState: UiState.common })}
          >
            訂單
          </Widget.TabItem>
          <Widget.TabItem
            select={uiState === UiState.repair}
            onClick={() => this.setState({ uiState: UiState.repair })}
          >
            維修單
          </Widget.TabItem>
        </div>

        {uiState === "common" && <CommonOrderTable />}
        {uiState === "repair" && <RepairOrderTable />}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding: 0px 30px;
  & > h2 {
    padding: 10px;
    letter-spacing: 3.8px;
    font-weight: 400;
    font-size: 38px;
    color: #737987;
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 30px;
  }

  & .tab-container {
    margin-bottom: 30px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
  }

  & .back-button {
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    color: blue;
  }

  & .table-container {
    & .row {
      display: flex;
    }
  }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(OrderTable);
