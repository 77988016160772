import React, { Component } from "react";
import styled from "styled-components";

class Panel extends Component {
  render() {
    let { title, buttons, children, css } = this.props;
    return (
      <Wrapper css={css}>
        <div className="header">
          <div className="title">{title}</div>
          {buttons && <div className="buttons-wrapper">{buttons}</div>}
        </div>

        <div className="content">{children}</div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  border-radius: 5px;
  border: 1px solid #eaeaea;
  max-width: 470px;
  width: 100%;
  background: white;

  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 9px solid #8596a6;
    border-bottom: 1px solid #eaeaea;
    padding: 16px 23px;

    & > .title {
      letter-spacing: 2.4px;
      font-size: 24px;
      color: #19233b;
    }

    & > .buttons-wrapper {
    }

    @media screen and (max-width: 360px) {
      flex-direction: column;
      align-items: flex-start;

      & > .buttons-wrapper {
        margin: 10px 0px 0px 0px;
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }

  & .content {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  ${props => props.css || ""};
`;

export default Panel;
