import React from "react";
import { connect } from "react-redux";
import { withPage } from "../../Page";
import styled from "styled-components";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Icon from "../../Components/Icon";
import LoginForm from "../../Components/LoginForm";
import ProfileForm from "../../Components/ProfileForm";
import StoreForm from "../../Components/StoreForm";
import HomeTable from "../../Components/HomeTable";
import ProductTable from "../../Components/ProductTable";
import StoreOrderTable from "../../Components/StoreOrderTable";
import CmsDashboard from "../../../src/Components/CmsDashboard";
import CmsTableFactory from "../../../src/Components/CmsTableFactory";
import AppConfig from "../../Config";
import OrderTable from "../../Components/BuffetOrderTable";
import FactoryOrderTable from "../../Components/BuffetFactoryOrderTable";
import StaffTable from "../../Components/BuffetStaffTable";
import StockTable from "../../Components/BuffetStockTable";
import MemberTable from "../../Components/BuffetMemberTable";

class AdminPage extends React.Component {
  render() {
    let { profile, appActions } = this.props;

    if (!profile) {
      return <LoginForm />;
    } else {
      return (
        <CmsDashboard
          themeColor={AppConfig.global.themeColor}
          Header={
            <CustomHeader>
              <h3>{"BUFFET\nDashboard"}</h3>
              <h5>{profile.name} 您好！</h5>
            </CustomHeader>
          }
          entries={[
            {
              name: "order",
              label: "訂單"
            },
            {
              name: "factory-order",
              label: "工廠單"
            },
            {
              name: "staff",
              label: "人員"
            },
            // {
            //   name: "product",
            //   label: "商品"
            // },
            //
            {
              name: "stock",
              label: "庫存"
            },
            {
              name: "member",
              label: "會員"
            },
            {
              name: "logout",
              label: "登出",
              type: "function"
            }
          ]}
          entryFunctionHandler={this._onEntryFunction}
        >
          {({ cms }) => {
            let { entry } = cms;

            if (entry.name === "order") {
              return <OrderTable />;
            } else if (entry.name === "factory-order") {
              return <FactoryOrderTable />;
            } else if (entry.name === "staff") {
              return <StaffTable />;
            } else if (entry.name === "stock") {
              return <StockTable />;
            } else if (entry.name === "member") {
              return <MemberTable />;
            }
            throw "default";
          }}
        </CmsDashboard>
      );
    }
  }

  _onEntryFunction = entry => {
    let { appActions } = this.props;
    if (entry.name === "logout") {
      appActions.logout().catch(console.warn);
    }
  };
}

const CustomHeader = styled.div`
  background-color: white;
  padding: 20px 8px 10px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > h3 {
    margin-bottom: 15px;
    white-space: pre-wrap;
    text-align: center;
    padding: 0px;
    color: ${AppConfig.global.themeColor};
  }

  & > h5 {
    margin-bottom: 5px;
    word-break: break-all;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(AdminPage)
);
