import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { dateParser } from "../../src/Utils/DateUtil";
import { repairOrderNumberFormat } from "../../src/Utils/NumberingFormatUtil";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator/Buffet";
import * as Widget from "./Widget/Buffet";
import * as Icon from "./Icon";
import StatusButton from "./StatusButton";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class RepairOrderDetailDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      record: {
        serial_number: "",
        status: "waiting",
        amount: 0,
        buyer_name: "",
        buyer_phone: "",
        buyer_email: "",
        buyer_address: "",
        receiver_address: "",
        size: "",
        shape: "",
        leather: "",
        bottom: "",
        shoe_buckle: "",
        belt: "",
        staff: "",
        ...this.props.instance
      },
      showSpinner: false,
      message: {
        display: false,
        text: ""
      },
      staffs: []
    };
  }

  componentDidMount() {
    this._getStaffList();
  }

  render() {
    let { target, title, open, onClose, profile } = this.props;
    let { record, showSpinner, message, staffs } = this.state;

    return (
      <Widget.Dialog open={open} className={`${open ? "active" : ""}`}>
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="content">
              <div className="title">{title}</div>

              <div className="form-section">
                <div className="form-container">
                  <div className="form-content">
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單編號</span>
                      <div className="text">
                        {target === "create"
                          ? "待創建後生成"
                          : record.serial_number}
                      </div>
                    </Widget.FormField>
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單狀態</span>
                      <select
                        value={record.status}
                        onChange={e =>
                          this.setState({
                            record: { ...record, status: e.target.value }
                          })
                        }
                      >
                        {[
                          { value: "waiting", display: "等待中" },
                          { value: "accepted", display: "已受理" },
                          { value: "in_maintenance", display: "維修中" },
                          { value: "in_inspection", display: "檢查中" },
                          { value: "completed", display: "已完成" }
                        ].map((status, i) => (
                          <option key={"statue" + i} value={status.value}>
                            {status.display}
                          </option>
                        ))}
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">製單人</span>
                      <select
                        value={record.staff}
                        onChange={e =>
                          this.setState({
                            record: { ...record, staff: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      >
                        <option value="">---</option>
                        {staffs &&
                          staffs.map((staff, i) => (
                            <option key={"staff" + i} value={staff.name}>
                              {staff.name}
                            </option>
                          ))}
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">顧客姓名</span>
                      <input
                        value={record.buyer_name}
                        onChange={e =>
                          this.setState({
                            record: { ...record, buyer_name: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">顧客電話</span>
                      <input
                        value={record.buyer_phone}
                        onChange={e =>
                          this.setState({
                            record: { ...record, buyer_phone: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">顧客信箱</span>
                      <input
                        value={record.buyer_email}
                        onChange={e =>
                          this.setState({
                            record: { ...record, buyer_email: e.target.value },
                            message: { display: false, text: "" }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">寄送地址</span>
                      <input
                        value={record.receiver_address}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              receiver_address: e.target.value
                            }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>

                  <div className="form-content">
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">預期完成日</span>
                      <input
                        type="date"
                        value={record.completed_date}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              completed_date: e.target.value
                            }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">尺寸</span>
                      <input
                        value={record.size}
                        onChange={e =>
                          this.setState({
                            record: { ...record, size: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">款式</span>
                      <input
                        value={record.shape}
                        onChange={e =>
                          this.setState({
                            record: { ...record, shape: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">皮革</span>
                      <input
                        value={record.leather}
                        onChange={e =>
                          this.setState({
                            record: { ...record, leather: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">鞋底</span>
                      <input
                        value={record.bottom}
                        onChange={e =>
                          this.setState({
                            record: { ...record, bottom: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">鞋扣</span>
                      <input
                        value={record.shoe_buckle}
                        onChange={e =>
                          this.setState({
                            record: { ...record, shoe_buckle: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">鞋帶</span>
                      <input
                        value={record.belt}
                        onChange={e =>
                          this.setState({
                            record: { ...record, belt: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>
                </div>
                {message.display && (
                  <Widget.HintText css="align-self: flex-end;">
                    {message.text}
                  </Widget.HintText>
                )}
              </div>

              <div className="bottom-section">
                <div className="message" />

                {showSpinner ? (
                  <Widget.Spinner />
                ) : (
                  <div className="buttons">
                    <Widget.WhiteButton onClick={onClose}>
                      取消
                    </Widget.WhiteButton>
                    <Widget.BlueButton
                      onClick={() => this._submit(record, target)}
                    >
                      確定
                    </Widget.BlueButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Widget.Dialog>
    );
  }

  _getStaffList = async () => {
    let { appActions } = this.props;

    try {
      let staffs = await appActions.getStaffList();
      this.setState({ staffs });
    } catch (err) {
      console.warn(err);
    }
  };

  _submit = (data, target) => {
    let { appActions, onClose, refreshOrders, refreshOrder } = this.props;

    if (!data.buyer_name || !data.buyer_email || !data.buyer_phone) {
      this.setState({
        message: {
          display: true,
          text: "顧客姓名、電話、信箱為必填！"
        }
      });
      return;
    }

    if (target === "create") {
      this.setState({ showSpinner: true });
      delay(500)
        .then(() => appActions.createRepairOrder(data))
        .then(result => {
          result.serial_number = repairOrderNumberFormat(result);
          appActions.updateRepairOrderById(result);
        })
        .then(() => this.setState({ showSpinner: false }))
        .then(() => {
          onClose();
          refreshOrders();
        })
        .catch(err => {
          console.warn(err);
          this.setState({ showSpinner: false });
        });
    } else {
      //update
      data.serial_number = repairOrderNumberFormat(data);
      this.setState({ showSpinner: true });
      delay(500)
        .then(() => appActions.updateRepairOrderById(data))
        .then(() => this.setState({ showSpinner: false }))
        .then(() => {
          onClose();
          refreshOrder();
        })
        .catch(err => {
          console.warn(err);
          this.setState({ showSpinner: false });
        });
    }
  };
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(RepairOrderDetailDialog);
