import React, { Component } from "react";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { getDiffDate, d2s, dateParser } from "../../src/Utils/DateUtil";
import styled from "styled-components";
import * as Icon from "../../src/Components/Icon";
import Table from "../../src/Components/Table";
import JsonParser from "../../src/Utils/JsonParser";
import AppConfig from "../Config";
import MultiSelectSearch from "./BuffetMultiSelectSearch";

const StatusyMap = {
  pending: {
    display: "處理中",
    background: "#3C97CA",
    color: "white"
  },
  complete: {
    display: "已完成",
    color: "#95A7B1",
    background: "white"
  }
};

class StatusButton extends Component {
  render() {
    let { status = "pending", onChange = () => 0, choices = [] } = this.props;
    let target = StatusyMap[status];
    return (
      <Wrapper color={target.color} background={target.background}>
        <span>{target.display}</span>
        <select
          name=""
          id=""
          onChange={e => {
            onChange(e.target.value);
          }}
        >
          <option value="pending">處理中</option>
          <option value="complete">已完成</option>
        </select>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  border-radius: 15px;
  padding: 3px 40px 3px 15px;
  cursor: pointer;
  position: relative;

  select {
    display: hidden;
  }

  background-color: ${props => props.background};
  color: ${props => props.color};
  border: 1px solid ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:after {
    content: "";
    border: 7px solid transparent;
    border-top: 10px solid white;
    border-bottom-width: 0px;
    position: absolute;
    right: 15px;
  }
`;

export default StatusButton;
