import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "../../src/Components/Icon";

class MultiSelectSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      search: "",
      searching: false,
      searchApplied: false,
      searchedChoices: [],

      cached: props.choices
    };
  }

  render() {
    let {
      disabled,
      value,
      required,
      choices,
      fnGetLabel,
      fnSearchChoices,
      onSubmit,
      emptySearchResultText = "Oops! Not Found!",
      btnComp = null
    } = this.props;
    let {
      open,
      searching,
      searchApplied,
      searchedChoices,
      cached
    } = this.state;

    if (searchApplied) {
      choices = searchedChoices;
    }

    return (
      <Wrapper>
        <div
          className="display-label-wrapper"
          onClick={() =>
            this.setState({
              open: true,
              search: "",
              searching: false,
              searchApplied: false,
              searchedChoices: []
            })
          }
        >
          {btnComp ? (
            btnComp
          ) : (
            <div className="display-label">
              <span className="text">{this._getLabelByValue(value)}</span>
            </div>
          )}
        </div>
        <Dialog open={open} className={`${open ? "active" : ""}`}>
          <div
            className="backdrop"
            onClick={() => {
              this.setState({ open: false });
            }}
          />
          {open && (
            <div className="content" onClick={e => e.stopPropagation()}>
              <div className="search-wrapper">
                <input
                  placeholder="search for..."
                  onChange={e =>
                    this.setState({
                      search: e.target.value,
                      searchApplied: false
                    })
                  }
                />

                <div style={{ cursor: "pointer" }} onClick={this._doSearch}>
                  <Icon.Search size={24} color={searching ? "grey" : "black"} />
                </div>
              </div>

              {searching && <div className="searching">Searching...</div>}

              <div className="choices-wrapper">
                {!searching && searchApplied && choices.length === 0 && (
                  <div className="empty-result-text">
                    {emptySearchResultText}
                  </div>
                )}
                {choices.map((c, idx) => (
                  <div
                    className="choice-wrapper"
                    key={idx}
                    onClick={e => {
                      e.stopPropagation();
                      let foundIndex = cached.findIndex(
                        item => item.id === c.id
                      );
                      if (foundIndex > -1) {
                        let _cached = [...cached];
                        _cached.splice(foundIndex, 1);
                        this.setState({ cached: _cached });
                      } else {
                        this.setState({ cached: [...cached, c] });
                      }
                    }}
                  >
                    {cached.find(item => item.id === c.id) ? (
                      <Icon.CheckBox size={24} color={"#8596A6"} />
                    ) : (
                      <Icon.CheckBoxOutlineBlank size={24} color={"#8596A6"} />
                    )}
                    <div className="label">{`${fnGetLabel(c)}`}</div>
                  </div>
                ))}
              </div>

              <div className="actions-wrapper">
                <div
                  className="button"
                  onClick={() => {
                    this.setState({ cached: [], open: false });
                  }}
                >
                  取消
                </div>
                <div
                  className="button active"
                  onClick={() => {
                    onSubmit([...value, ...cached]);
                    this.setState({ cached: [], open: false });
                  }}
                >
                  確認
                </div>
              </div>
            </div>
          )}
        </Dialog>
      </Wrapper>
    );
  }

  _doSearch = async () => {
    let { fnSearchChoices } = this.props;
    let { search, searching } = this.state;

    if (search && !searching) {
      this.setState({
        searching: true,
        searchApplied: true,
        searchedChoices: []
      });
      try {
        let result = await fnSearchChoices(search);
        this.setState({
          searchedChoices: result
        });
      } catch (ex) {
        // TODO: show error msg
      }
      this.setState({ searching: false });
    }
  };

  _getLabelByValue = value => {
    let { choices, fnGetLabel } = this.props;
    if (!value) {
      return "---";
    }
    return value
      .map(v => fnGetLabel(v))
      .reduce((acc, cur, arr) => acc + `${acc ? `, ` : ""}${cur}`, "");
  };
}

let Wrapper = styled.div`
  & .display-label-wrapper {
    cursor: pointer;
  }
  & .display-label {
    background-color: #e4e4e4;
    border-radius: 6px;
    height: 30px;
    width: 200px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    justify-content: space-between;

    & > .text {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &::after {
      content: "";
      border: 7px solid transparent;
      border-top: 10px solid white;
      border-bottom-width: 0px;
    }
  }
`;

let Dialog = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;

  transform: translateX(-100vw);
  transition: 0s ease-in transform 0.5s;
  &.active {
    transform: translateX(0);
    transition: 0s ease-in transform 0s;
  }

  & .backdrop {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: ${props => (props.open ? 1 : 0)};
    transition: 0.2s ease-in opacity;
  }

  & .content {
    background-color: white;
    width: 80%;
    min-width: 600px;
    max-width: calc(100% - 20px);
    min-height: 300px;
    max-height: calc(100% - 60px);
    overflow: auto;
    border-radius: 5px;
    z-index: 1001;
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 700px) {
      min-width: initial;
    }

    & > .searching {
      color: grey;
      font-size: 16px;
      padding: 20px 10px;
      text-align: center;
    }

    & > .choices-wrapper {
      overflow: auto;
      flex-grow: 1;

      & .empty-result-text {
        color: grey;
        font-size: 16px;
        padding: 20px 10px;
        text-align: center;
      }

      & > .choice-wrapper {
        display: flex;
        padding: 6px 20px;
        align-items: center;
        border-bottom: 1px solid #f3f3f3;
        cursor: pointer;
        & > .label {
        }
      }
    }

    & > .search-wrapper {
      padding-top: 20px;
      padding: 20px 20px 10px 20px;
      border-bottom: 1px solid lightgrey;
      top: 0px;
      display: flex;
      align-items: center;

      & > input {
        width: 100%;
        border: 1px solid #979797;
        border-radius: 6px;
        padding: 8px 10px;
        outline: none;
        color: #101d1f;
        margin-right: 10px;
      }
    }

    & > .actions-wrapper {
      padding: 10px 20px 20px 20px;
      border-top: 1px solid lightgrey;
      bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;

      & > .button {
        color: #4e77e7;
        background-color: white;
        padding: 10px 13px;
        cursor: pointer;
        text-align: center;
        font-size: 16px;
        min-width: 150px;
        margin-left: 10px;
      }

      & .button.active {
        background-color: #4e77e7;
        color: white;
      }
    }
  }
`;

export default MultiSelectSearch;
