import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "../Widget";

class HomeTableItem extends Component {
  render() {
    let { data, css } = this.props;

    return (
      <Wrapper css={css} colors={data.colors}>
        <div className="name">
          <div className="icon-container">
            <img className="icon" />
          </div>
          {data.name}
        </div>
        <div className="line" />
        <div className="number">
          {data.number ? data.number : <Widget.Spinner />}
        </div>
        <div className="unit">{data.unit}</div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  border-radius: 8px;
  max-width: 260px;
  width: 100%;
  min-height: 160px;
  height: 100%;
  background-image: ${props =>
    props.colors
      ? `linear-gradient(-180deg, ${props.colors[0]} 0%, ${
          props.colors[1]
        } 100%)`
      : `inear-gradient(-180deg, #d78f11 0%, #ae5607 100%)`};

  & > .name {
    display: flex;
    align-items: center;
    padding: 12px;
    font-weight: 300;
    font-size: 14px;
    color: white;

    & > .icon-container {
      margin-right: 18px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);

      & > .icon {
      }
    }
  }

  & > .line {
    width: 100%;
    height: 1px;
    background: white;
  }

  & > .number {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 25px;
    width: 100%;
    letter-spacing: 3px;
    font-weight: 500;
    font-size: 30px;
    color: white;
  }

  & > .unit {
    margin: 10px;
    letter-spacing: 1px;
    text-align: right;
    font-size: 12px;
    color: white;
  }

  ${props => props.css || ""};
`;

export default HomeTableItem;
