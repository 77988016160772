import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import HomeTableContent from "../HomeTableContent";
import * as Widget from "../Widget";
import * as Icon from "../Icon";

const UiState = {
  week: "前7天",
  month: "前1個月",
  season: "前3個月",
  year: "前1年",
  custom: "自訂"
};

class HomeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: UiState.week
    };
  }

  render() {
    let { store } = this.props;
    let { uiState } = this.state;

    return (
      <Wrapper>
        <Widget.Row
          css={`
            border-bottom: 1px solid #eaeaea;
            @media screen and (max-width: 550px) {
              flex-wrap: wrap;
            }
          `}
        >
          <div className="title">首頁管理</div>
          <div className="store">
            <StoreItem>
              <img className="avatar" />
              <div className="name">{store ? store.name : "---"}</div>
            </StoreItem>
          </div>
        </Widget.Row>
        <div className="tab-container">
          <Widget.Row css="min-height: 58px; border-bottom: 1px solid #eaeaea;">
            <TabItem
              selected={uiState === UiState.week}
              onClick={() => this.setState({ uiState: UiState.week })}
            >
              {UiState.week}
            </TabItem>
            <TabItem
              selected={uiState === UiState.month}
              onClick={() => this.setState({ uiState: UiState.month })}
            >
              {UiState.month}
            </TabItem>
            <TabItem
              selected={uiState === UiState.season}
              onClick={() => this.setState({ uiState: UiState.season })}
            >
              {UiState.season}
            </TabItem>
            <TabItem
              selected={uiState === UiState.year}
              onClick={() => this.setState({ uiState: UiState.year })}
            >
              {UiState.year}
            </TabItem>
            <TabItem
              selected={uiState === UiState.custom}
              onClick={() => this.setState({ uiState: UiState.custom })}
            >
              <Widget.Row align="center">
                {UiState.custom}
                <Icon.ArrowDropDown
                  size={15}
                  color={
                    uiState === UiState.custom
                      ? "#4E77E7"
                      : "rgba(25,35,59,0.60)"
                  }
                  style={{ marginLeft: 5 }}
                />
              </Widget.Row>
            </TabItem>
          </Widget.Row>
        </div>
        <div className="content-container">
          {store ? (
            <HomeTableContent duration={uiState} storeId={store.id} />
          ) : (
            "loading"
          )}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  & .title {
    flex: 1;
    padding: 12px 10px 15px 10px;
    letter-spacing: 3.8px;
    font-size: 38px;
    color: rgba(25, 35, 59, 0.6);
  }

  & .store {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #eaeaea;
    max-width: 200px;
    width: 100%;

    @media screen and (max-width: 550px) {
      justify-content: flex-start;
      max-width: 100%;
      padding: 0px 10px 15px;
    }
  }

  & .tab-container {
    width: 100%;
    overflow: auto;
  }

  & .content-container {
    padding: 10px;
  }
`;

const StoreItem = styled.div`
  display: flex;
  align-items: center;

  & .avatar {
    margin-right: 10px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: lightgray;
  }

  & .name {
    letter-spacing: 1.4px;
    font-size: 14px;
    color: #19233b;
  }
`;

const TabItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  border-bottom: ${props =>
    props.selected ? "4px solid #4E77E7" : "4px solid transparent"};
  padding: 3px;
  min-width: 80px;
  font-size: 16px;
  color: ${props => (props.selected ? "#4E77E7" : "rgba(25,35,59,0.60)")};
  cursor: pointer;
`;

export default connect(
  (state, ownProps) => ({
    store: Selectors.getStore(state)
  }),
  ActionCreator
)(HomeTable);
