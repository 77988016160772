import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Icon from "../../src/Components/Icon/Buffet";
import { dateParser } from "../../src/Utils/DateUtil";
import { orderStatusName } from "../../src/Utils/OrderStatusNameUtil";
import { commonOrderNumberForamt } from "../../src/Utils/NumberingFormatUtil";
import { commonOrderMailTemplate } from "../../src/Utils/MailTemplateUtil";
import JsonParser from "../../src/Utils/JsonParser";
import { formatPrice } from "../../src/Utils/PriceFormatter";
import { calcFinalAmount } from "../../src/Utils/calcFinalAmount";
import ActionCreator from "../ActionCreator";
import AppConfig from "../Config/Buffet";
import * as Widget from "./Widget/Buffet";
import Panel from "./BuffetPanel";
import ConfirmDialog from "./BuffetConfirmDialog";
import ConfirmDeleteActionDialog from "./BuffetConfirmDeleteActionDialog";
import CommonOrderDetailDialog from "./BuffetCommonOrderDetailDialog";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class CommonOrderDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: {
        ...this.props.instance
      },
      history: this.props.history,
      staffList: [],
      shoesCustomTypes: null,
      showSpinner: false,
      openConfirmDialog: false,
      openConfirmDeleteDialog: false,
      openDetailDialog: false
    };
  }

  componentDidMount() {
    this._fetchStaffList();
    this._getShoesCustomType();
  }

  render() {
    let { onClose, orderModel, items, member = false } = this.props;
    let {
      instance,
      history,
      staffList,
      shoesCustomTypes,
      showSpinner,
      openConfirmDialog,
      openConfirmDeleteDialog,
      openDetailDialog
    } = this.state;

    return (
      <DetailWrapper>
        <div className="align-container">
          <div className="breadcrumbs">{`訂單管理 > 訂單 > ${instance.number}`}</div>
          <div className="buttons-wrapper">
            {!member && (
              <>
                <div
                  className="button"
                  onClick={() => this.setState({ openConfirmDialog: true })}
                >
                  <Icon.Email
                    size={24}
                    css="margin-right: 5px;"
                    color={"#727886"}
                  />
                  通知
                </div>
                <div
                  className="button"
                  onClick={() => this.setState({ openDetailDialog: true })}
                >
                  <Icon.Edit
                    size={24}
                    css="margin-right: 5px;"
                    color={"#727886"}
                  />
                  編輯
                </div>
                <div
                  className="button"
                  onClick={() =>
                    this.setState({ openConfirmDeleteDialog: true })
                  }
                >
                  <Icon.Trash
                    size={24}
                    color={"#727886"}
                    css="margin-right: 5px;"
                  />
                  刪除
                </div>
              </>
            )}

            <Widget.OutlineButton onClick={onClose}>返回</Widget.OutlineButton>
          </div>
        </div>

        <div className="align-container">
          <div className="buttons-wrapper">
            <a
              className="button"
              target="_blank"
              href={
                "https://www.sf-express.com/tw/tc/dynamic_function/waybill/"
              }
            >
              <Icon.AirportShuttle
                size={24}
                css="margin-right: 5px;"
                color={"#727886"}
              />
              順豐
            </a>
            <a
              className="button"
              target="_blank"
              href={
                "https://www.post.gov.tw/post/internet/SearchZone/default.jsp?ID=13"
              }
            >
              <Icon.AirportShuttle
                size={24}
                css="margin-right: 5px;"
                color={"#727886"}
              />
              郵局
            </a>
            <a
              className="button"
              target="_blank"
              href={"http://query2.e-can.com.tw/ECAN_APP/search.shtm"}
            >
              <Icon.AirportShuttle
                size={24}
                css="margin-right: 5px;"
                color={"#727886"}
              />
              宅配通
            </a>
          </div>
        </div>

        {showSpinner ? (
          <Widget.Spinner />
        ) : (
          <div className="panels-section">
            <div className="panels-container">
              <Panel
                css="margin-bottom: 20px;"
                title="訂單資料"
                children={
                  <ItemContent>
                    {[
                      ...orderModel.fields.filter(
                        f =>
                          f.name.indexOf("buyer") === -1 &&
                          f.name.indexOf("final_amount") <= -1 &&
                          f.name.indexOf("payment_balance") === -1 &&
                          f.name.indexOf("note") === -1
                      ),
                      { name: "staff", label: "製單人" },
                      { name: "amount", label: "訂單金額" },
                      { name: "discount", label: "訂單折扣" },
                      { name: "deposit", label: "預付訂金" },
                      { name: "shipping_cost", label: "運費" },
                      { name: "payment_balance", label: "尾款狀態" },
                      { name: "payment_method", label: "付款方式" },
                      { name: "note", label: "訂單備註" },
                      { name: "completed_date", label: "預期完成日" },
                      { name: "logistics_number", label: "物流單編號" }
                    ].map((f, idx) => (
                      <div className="field" key={"order" + idx}>
                        <div className="label">{f.label}</div>
                        <div className="value">
                          {(() => {
                            if (f.name === "created") {
                              return dateParser(instance[f.name]);
                            } else if (f.name === "url") {
                              return (
                                <a href={instance[f.name]} target="_blank">
                                  {instance[f.name]}
                                </a>
                              );
                            } else if (
                              f.name === "deposit" ||
                              f.name === "shipping_cost"
                            ) {
                              return `${formatPrice(instance[f.name])} 元`;
                            } else if (f.name === "payment_method") {
                              const labels = {
                                cash: "現金",
                                card: "信用卡"
                              };
                              return labels[instance.payment_method];
                            } else if (f.name === "amount") {
                              return (
                                <>
                                  {formatPrice(instance.amount)} 元
                                  <span
                                    style={{
                                      marginLeft: 16,
                                      fontSize: 14,
                                      color: "#c80f26"
                                    }}
                                  >{`(尚有未付款項 ${formatPrice(
                                    calcFinalAmount(instance)
                                  )}元)`}</span>
                                </>
                              );
                            } else if (f.name === "discount") {
                              return instance[f.name] !== 0
                                ? `${instance[f.name]}折`
                                : "無折扣";
                            } else if (f.name === "status") {
                              return orderStatusName(instance[f.name]);
                            } else if (f.name === "payment_balance") {
                              return instance[f.name] ? "結清" : "未結清";
                            }
                            return instance[f.name];
                          })()}
                        </div>
                      </div>
                    ))}
                  </ItemContent>
                }
              />
              <Panel
                css="margin-bottom: 20px;"
                title="顧客資訊"
                children={
                  <ItemContent>
                    {[
                      ...orderModel.fields.filter(
                        f => f.name.indexOf("buyer") > -1
                      ),
                      { name: "receiver_address", label: "寄送地址" },
                      { name: "delivery_type", label: "配送方式" }
                    ].map((f, idx) => (
                      <div className="field" key={"buyer" + idx}>
                        <div className="label">{f.label}</div>
                        <div className="value">
                          {(() => {
                            if (f.name === "delivery_type") {
                              switch (instance[f.name]) {
                                case "express":
                                  return "順豐";

                                case "post":
                                  return "郵局";

                                case "pelican":
                                  return "宅配通";

                                case "self_pick":
                                  return "自取";

                                default:
                                  return "---";
                              }
                            }

                            return instance[f.name];
                          })()}
                        </div>
                      </div>
                    ))}
                  </ItemContent>
                }
              />
            </div>

            <div className="panels-container">
              <Panel
                css="margin-bottom: 20px;"
                title="商品詳情"
                children={items.map((item, i) => (
                  <ItemContent
                    key={"item" + i}
                    css="border-bottom: 1px solid #eaeaea;"
                  >
                    {shoesCustomTypes &&
                      item.choices.map((c, i) => {
                        let hitType = shoesCustomTypes.find(
                          type => type.id === c.type.id
                        );
                        return (
                          <div className="field" key={"choice" + i}>
                            <div className="label">{hitType.display}</div>
                            <div className="value">{c.option.name}</div>
                          </div>
                        );
                      })}
                  </ItemContent>
                ))}
              />

              <Panel
                css="margin-bottom: 20px;"
                title="作業備註"
                children={
                  <ItemContent>
                    <div className="field">
                      <div className="value">{instance.production_note}</div>
                    </div>
                  </ItemContent>
                }
              />

              {!member && (
                <Panel
                  css="margin-bottom: 20px;"
                  title="訂單更新紀錄"
                  children={
                    <>
                      <ItemContent css="padding: 0px 23px 0px 0px;">
                        <div className="block">
                          <div className="list-container">
                            <div className="line" />
                            <div className="dot" />
                          </div>
                          <div className="text-container">
                            <div className="text">
                              {`${dateParser(instance.created)}\n${
                                instance.staff
                              } 創立訂單`}
                            </div>
                          </div>
                        </div>
                      </ItemContent>
                      {history &&
                        history.map((h, i) => {
                          if (i > 0) {
                            let preHistoryData = JSON.parse(
                              history[i - 1].data
                            );
                            let data = JSON.parse(h.data);

                            if (data.status !== preHistoryData.status) {
                              return (
                                <ItemContent
                                  css="padding: 0px 23px 0px 0px;"
                                  key={"common-order-history" + i}
                                >
                                  <div className="block">
                                    <div className="list-container">
                                      <div className="line" />
                                      <div className="dot" />
                                    </div>
                                    <div className="text-container">
                                      <div className="text">
                                        {`${dateParser(
                                          h.created
                                        )}\n${this._getStaffName(
                                          staffList,
                                          h.profile
                                        )} 更新訂單狀態為 ${orderStatusName(
                                          data.status
                                        )}`}
                                      </div>
                                    </div>
                                  </div>
                                </ItemContent>
                              );
                            }

                            if (
                              data.payment_balance !==
                              preHistoryData.payment_balance
                            ) {
                              return (
                                <ItemContent
                                  css="padding: 0px 23px 0px 0px;"
                                  key={"common-order-history" + i}
                                >
                                  <div className="block">
                                    <div className="list-container">
                                      <div className="line" />
                                      <div className="dot" />
                                    </div>
                                    <div className="text-container">
                                      <div className="text">
                                        {`${dateParser(
                                          h.created
                                        )}\n${this._getStaffName(
                                          staffList,
                                          h.profile
                                        )} 更新尾款狀態為${
                                          data.payment_balance
                                            ? "「結清」"
                                            : "「未結清」"
                                        }`}
                                      </div>
                                    </div>
                                  </div>
                                </ItemContent>
                              );
                            }
                          }
                        })}
                    </>
                  }
                />
              )}
            </div>
          </div>
        )}

        {openConfirmDialog && (
          <ConfirmDialog
            title="寄送訂單成立通知"
            text={`確認成立通知給 購買人 ${instance.buyer_name} 嗎？`}
            open={openConfirmDialog}
            onConfirm={() => this._sendMail()}
            onClose={() => this.setState({ openConfirmDialog: false })}
          />
        )}

        {openConfirmDeleteDialog && (
          <ConfirmDeleteActionDialog
            haveMessage={true}
            title="刪除訂單"
            text="確認刪除該訂單嗎？"
            open={openConfirmDeleteDialog}
            onConfirm={() => {
              this._cancelOrderById(instance);
            }}
            onClose={() => this.setState({ openConfirmDeleteDialog: false })}
          />
        )}

        {openDetailDialog && (
          <CommonOrderDetailDialog
            title="修改訂單"
            instance={instance}
            items={items}
            open={openDetailDialog}
            shoesCustomTypes={shoesCustomTypes}
            onClose={() => this.setState({ openDetailDialog: false })}
            refreshOrder={() => {
              this._refreshOrderById(instance.id);
            }}
          />
        )}
      </DetailWrapper>
    );
  }

  _sendMail = () => {
    let { appActions } = this.props;
    let { instance } = this.state;

    let data = {
      html: true,
      subject: "BUFFET 訂單成立通知",
      message: commonOrderMailTemplate(instance),
      to: instance.buyer_email
    };

    appActions
      .sendMail(data)
      .then(() => this.setState({ openConfirmDialog: false }))
      .catch(err => console.warn(err));
  };

  _getStaffName = (list, id) => {
    let staff = list.filter(s => s.id === id)[0];

    if (staff) {
      return staff.name;
    } else {
      return "---";
    }
  };

  _fetchStaffList = () => {
    let { appActions } = this.props;

    appActions
      .getStaffList()
      .then(staffList => this.setState({ staffList }))
      .catch(err => console.warn(err));
  };

  _getShoesCustomType = async () => {
    let { appActions } = this.props;

    try {
      const shoesCustomTypes = await appActions.getShoesCustomType();

      this.setState({ shoesCustomTypes });
    } catch (ex) {
      console.warn(ex);
    }
  };

  _refreshOrderById = id => {
    let { appActions } = this.props;

    this.setState({ showSpinner: true });
    delay(1000)
      .then(() => appActions.getCommonOrderById(id))
      .then(result => {
        result.number = commonOrderNumberForamt(result);
        this.setState({ instance: result });
      })
      .then(() => appActions.fetchOrdersHistoryById(id))
      .then(history => this.setState({ history }))
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };

  _cancelOrderById = data => {
    let { appActions, onClose } = this.props;

    this.setState({ showSpinner: true });
    delay(2000)
      .then(() => appActions.cancelOrderById(data))
      .then(() => onClose())
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };
}

const ItemContent = styled.div`
  padding: 10px 23px;

  & .field {
    display: flex;
    align-items: center;
    padding: 8px 0px;

    & .label {
      margin-right: 10px;
      display: block;
      width: 160px;
      color: #19233b;
    }

    & .value {
      color: #101d1f;
    }
  }

  & .block {
    display: flex;

    & .list-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 59px;
      position: relative;

      & .line {
        width: 1px;
        height: 130.5px;
        background: #eaeaea;
      }

      & .dot {
        position: absolute;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        background: #8596a6;
      }
    }

    & .text-container {
      flex: 1;
      min-height: 84px;
      margin-top: 16.5px;

      & .text {
        border-radius: 5px;
        padding: 20px;
        background: #eaeaea;
        letter-spacing: 1.6px;
        white-space: pre-wrap;
        font-size: 16px;
        color: #19233b;
      }

      :last-child {
        margin-bottom: 30px;
      }
    }
  }

  ${props => props.css || ""};
`;

const DetailWrapper = styled.div`
  & .align-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .breadcrumbs {
    letter-spacing: 1.51px;
    line-height: 22px;
    font-size: 16px;
    color: #0d1100;
  }

  & .buttons-wrapper {
    & > .button {
      display: flex;
      justify-content: center;
      align-items: center;
      letter-spacing: 1.6px;
      font-size: 16px;
      color: #727886;
    }
  }

  & .panels-section {
    display: flex;
    flex-wrap: wrap;

    & > .panels-container {
      max-width: 500px;
      width: 100%;
      padding: 10px 10px 10px 0px;
    }
  }
`;

export default connect(
  null,
  ActionCreator
)(CommonOrderDetailView);
