import React, { Component } from "react";
import styled from "styled-components";
import * as Icon from "../../src/Components/Icon";
import * as Widget from "./Widget/Buffet";

class BuffetExportCSVBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: "",
      to: "",
      message: {
        display: false,
        text: ""
      }
    };
  }

  render() {
    let { apiLink } = this.props;
    let { from, to, message } = this.state;

    return (
      <Wrapper>
        <div className="title">匯出日期：</div>
        <div className="bar-container">
          <input
            className="select"
            type="date"
            value={from}
            onChange={e => this.setState({ from: e.target.value })}
            onFocus={() => this.setState({ message: { display: false } })}
          />
          <div className="duration">~</div>
          <input
            className="select"
            type="date"
            value={to}
            onChange={e => this.setState({ to: e.target.value })}
            onFocus={() => this.setState({ message: { display: false } })}
          />
          <Widget.Row justify="center" align="center">
            {this._checkFromAndTo(this.state.from, this.state.to).result ? (
              <a
                className="button"
                href={`${apiLink}?from=${this.state.from}&to=${this.state.to}`}
                target="_blank"
              >
                <Icon.Export
                  size={24}
                  css="margin-right: 5px;"
                  color={"#727886"}
                />
                匯出
              </a>
            ) : (
              //fake button
              <div
                className="button"
                onClick={() =>
                  this.setState({
                    message: {
                      display: true,
                      text: this._checkFromAndTo(from, to).msg
                    }
                  })
                }
              >
                <Icon.Export
                  size={24}
                  css="margin-right: 5px;"
                  color={"#727886"}
                />
                匯出
              </div>
            )}
            {message.display && <div className="message">{message.text}</div>}
          </Widget.Row>
        </div>
      </Wrapper>
    );
  }

  _checkFromAndTo = (from, to) => {
    if (!from || !to) {
      return {
        result: false,
        msg: "起始與結束日期皆須填寫！"
      };
    } else if (from && to) {
      let fromMs = new Date(from).getTime();
      let toMs = new Date(to).getTime();

      if (fromMs > toMs) {
        return {
          result: false,
          msg: "起始日期不可大於結束日期！"
        };
      } else {
        return {
          result: true,
          msg: ""
        };
      }
    }
  };
}

const Wrapper = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & .title {
    margin-right: 10px;
    letter-spacing: 1.6px;
    font-size: 16px;
    color: rgba(25, 35, 59, 0.6);
  }

  & .bar-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  & .select {
    border: 1px solid #95a7b1;
    border-radius: 6px;
    padding: 4px 4px 4px 16px;
    max-width: 160px;
    min-height: 34px;
    font-size: 14px;
    color: #737987;

    :focus {
      outline: none;
    }
  }

  & .duration {
    margin: 10px;
    color: #727886;
  }

  & .button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-left: 20px;

    padding: 3px;
    min-width: 80px;
    max-height: 30px;
    text-decoration: none;
    letter-spacing: 1.6px;
    font-size: 16px;
    color: #727886;

    :hover {
      background: #e8e8e8;
    }
  }

  & .message {
    margin-left: 5px;
    font-size: 12px;
    color: #727886;
  }
`;

export default BuffetExportCSVBar;
