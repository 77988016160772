import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Widget from "./Widget/Buffet";
import ActionCreator from "../ActionCreator";
import * as Icon from "./Icon";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class StaffDetailDialog extends Component {
  constructor(props) {
    super(props);
    let { target, instance = {} } = this.props;

    this.state = {
      record: {
        name: "",
        phone: "",
        email: "",
        ...this.props.instance
      },
      showSpinner: false,
      hint: ""
    };
  }

  render() {
    let { target, title, open, onClose } = this.props;
    let { record, showSpinner, hint } = this.state;

    return (
      <Widget.Dialog open={open} className={`${open ? "active" : ""}`}>
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="content">
              <div className="title">{title}</div>

              <div className="form-section">
                <div className="form-container">
                  <div className="form-content">
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">會員編號</span>
                      <span className="text">
                        {record.id ? record.id : "-"}
                      </span>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">會員姓名</span>
                      <input
                        value={record.name}
                        onChange={e =>
                          this.setState({
                            record: { ...record, name: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">電子郵箱</span>
                      <input
                        value={record.email}
                        onChange={e =>
                          this.setState({
                            record: { ...record, email: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField>
                      <span className="label">手機號碼</span>
                      <input
                        value={record.phone}
                        onChange={e =>
                          this.setState({
                            record: { ...record, phone: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>
                </div>
              </div>

              {showSpinner ? (
                <Widget.Spinner />
              ) : (
                <div className="bottom-section">
                  <div className="message" />
                  <div className="buttons">
                    <Widget.WhiteButton onClick={onClose}>
                      取消
                    </Widget.WhiteButton>
                    <Widget.BlueButton
                      onClick={() => this._submit(record, target)}
                    >
                      確定
                    </Widget.BlueButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Widget.Dialog>
    );
  }

  _submit = (data, target) => {
    let { appActions, onClose, refreshMembers } = this.props;

    if (target === "create") {
      this.setState({ showSpinner: true });
      delay(500)
        .then(() => appActions.createMember(data))
        .then(() => this.setState({ showSpinner: false }))
        .then(() => {
          onClose();
          refreshMembers();
        });
    }
  };
}

export default connect(
  null,
  ActionCreator
)(StaffDetailDialog);
