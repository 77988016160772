import React, { Component } from "react";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import { getDiffDate, d2s } from "../../src/Utils/DateUtil";
import { repairOrderNumberFormat } from "../../src/Utils/NumberingFormatUtil";
import styled from "styled-components";
import Constants from "../../src/Domain/constants";
import JsonParser from "../../src/Utils/JsonParser";
import * as Icon from "../../src/Components/Icon";
import Table from "../../src/Components/Table";
import AppConfig from "../Config";
import * as Widget from "./Widget";
import RepairOrderDetailView from "./BuffetRepairOrderDetailView";
import RepairOrderDetailDialog from "./BuffetRepairOrderDetailDialog";
import ExportCSVBar from "./BuffetExportCSVBar";

const orderModel = {
  fields: [
    {
      name: "serial_number",
      label: "編號",
      style: {
        flexBasis: 230,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        padding: "20px 10px"
      }
    },
    {
      name: "status",
      label: "狀態",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      },
      extra: {
        displayMap: {
          waiting: "等待中",
          accepted: "已受理",
          in_maintenance: "維修中",
          in_inspection: "檢查中",
          completed: "已完成"
        }
      }
    },
    {
      name: "buyer_name",
      label: "顧客姓名",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "buyer_phone",
      label: "顧客電話",
      style: {
        flexBasis: 150,
        padding: "20px 10px"
      }
    },
    {
      name: "buyer_email",
      label: "顧客電郵",
      style: {
        flexBasis: 200,
        padding: "20px 10px"
      }
    },
    {
      name: "created",
      label: "創立時間",
      type: "datetime",
      style: {
        flexBasis: 140,
        padding: "20px 30px"
      }
    }
  ]
};

const SEARCH_FIELDS = [
  "serial_number",
  "buyer_name",
  "buyer_phone",
  "buyer_email",
  "created"
];

class RepairOrderTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      allOrders: [],
      ordersHistory: [],
      filterProgressType: "all",
      instance: null,
      search: "",
      openDialog: false
    };
  }

  async componentDidMount() {
    let orders = await this._fetchOrders();
    let ordersHistory = await this._fetchOrdersHistory();
    this.setState({ orders, allOrders: orders, ordersHistory });
  }

  render() {
    let {
      orders,
      allOrders,
      ordersHistory,
      filterProgressType,
      instance,
      search,
      openDialog
    } = this.state;

    return (
      <Wrapper>
        {(() => {
          if (instance) {
            let { items } = JsonParser(instance.data, { items: [] });
            let history = ordersHistory.filter(
              h => h.repair_order === instance.id
            );
            return (
              <RepairOrderDetailView
                onClose={async () => {
                  let orders = await this._fetchOrders();
                  this.setState({ orders, allOrders: orders });
                  this.setState({ instance: null });
                }}
                orderModel={orderModel}
                instance={instance}
                items={items}
                history={history}
              />
            );
          }

          return (
            <>
              <div className="align-container">
                <div>
                  <div className="search-wrapper">
                    <input
                      placeholder={`共有${orders.length}筆維修單`}
                      value={search}
                      onChange={e => this.setState({ search: e.target.value })}
                    />
                    <div className="icon-wrapper">
                      <Icon.Search
                        size={24}
                        css="margin-right: 10px;"
                        color={AppConfig.global.themeColor}
                      />
                    </div>
                  </div>
                  <span style={{ fontSize: 10, color: "rgba(25,35,59,0.6)" }}>
                    可搜尋編號、顧客姓名、顧客電話、顧客電郵、創立時間
                  </span>
                </div>

                <div className="buttons-wrapper">
                  <a className="button" href={`/search`} target="_blank">
                    <Icon.PlaylistAddCheck
                      size={26}
                      css="margin-right: 5px;"
                      color={"#727886"}
                    />
                    url
                  </a>
                  <Widget.CreateButton
                    onClick={() => this.setState({ openDialog: true })}
                  >
                    <div className="icon">
                      <Icon.Add size={24} color={"white"} />
                    </div>
                    <span>新增維修單</span>
                  </Widget.CreateButton>
                </div>
              </div>

              <ExportCSVBar
                apiLink={`${Constants.apiUrl}/api/repair_order/export_csv/`}
              />

              <FilteringWrapper css="margin-bottom: 20px;">
                {[
                  { value: "title", label: "維修單狀態：" },
                  { value: "all", label: "全部" },
                  { value: "waiting", label: "等待中" },
                  { value: "accepted", label: "已受理" },
                  { value: "in_maintenance", label: "維修中" },
                  { value: "in_inspection", label: "檢查中" },
                  { value: "completed", label: "已完成" }
                ].map((e, idx) => {
                  if (e.value === "title") {
                    return (
                      <div className="title" key={idx}>
                        {e.label}
                      </div>
                    );
                  }

                  return (
                    <div
                      key={idx}
                      className={
                        "option" +
                        (e.value === filterProgressType ? " active" : "")
                      }
                      onClick={() => {
                        this._onProgressFilterClick(
                          e.value,
                          allOrders,
                          filterProgressType
                        );
                      }}
                    >
                      {e.label}
                    </div>
                  );
                })}
              </FilteringWrapper>

              <div className="table-container">
                {orders && (
                  <Table
                    model={orderModel}
                    instances={orders.filter(o => {
                      o.serial_number = repairOrderNumberFormat(o);
                      return SEARCH_FIELDS.some(
                        f => (o[f] ? `${o[f]}` : "").indexOf(search) > -1
                      );
                    })}
                    onCellClick={this._onCellClick}
                    activeInstanceId={instance && instance.id}
                  />
                )}
              </div>
            </>
          );
        })()}

        {openDialog && (
          <RepairOrderDetailDialog
            target="create"
            title="新增維修單"
            open={openDialog}
            onClose={() => this.setState({ openDialog: false })}
            refreshOrders={async () => {
              let orders = await this._fetchOrders();
              this.setState({ orders, allOrders: orders });
            }}
          />
        )}
      </Wrapper>
    );
  }

  _fetchOrders = async (options = { from: "", to: "" }) => {
    let { appActions } = this.props;
    let orders = await appActions.searchRepairOrders("", {
      from: options.from,
      to: options.to
    });
    return orders.sort((a, b) => b.id - a.id);
  };

  _fetchOrdersHistory = async () => {
    let { appActions } = this.props;
    let ordersHistory = await appActions.fetchRepairOrdersHistory();

    return ordersHistory;
  };

  _onProgressFilterClick = (type, orders, currentType) => {
    if (currentType === type) {
      this.setState({ filterProgressType: "all", orders });
    } else if (type === "all") {
      this.setState({ filterProgressType: "all", orders });
    } else {
      this.setState({ filterProgressType: type, orders });
      let result = orders.filter(order => order.status === type);
      this.setState({ orders: result });
    }
  };

  _onCellClick = ({ instance }) => {
    if (
      (this.state.instance && instance.id !== this.state.instance.id) ||
      !this.state.instance
    ) {
      this.setState({ instance });
    } else {
      this.setState({
        instance: null
      });
    }
  };
}

const Wrapper = styled.div`
  & .align-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  & .search-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 300px;

    & .icon-wrapper {
      position: absolute;
      right: 10px;
    }

    & input {
      width: 100%;
      border: 1px solid #979797;
      border-radius: 6px;
      padding: 8px 10px;
      outline: none;
      color: #101d1f;

      &:focus {
        box-shadow: 0px 0px 0px 1.5px lightgrey;
      }
    }

    @media screen and (max-width: 550px) {
      margin-bottom: 14px;
    }

    @media screen and (max-width: 320px) {
      width: 260px;
    }
  }

  & .buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    & > .button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      margin-right: 20px;

      padding: 3px;
      min-width: 80px;
      max-height: 30px;
      text-decoration: none;
      letter-spacing: 1.6px;
      font-size: 16px;
      color: #727886;

      :hover {
        background: #e8e8e8;
      }
    }

    @media screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }
`;

const FilteringWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  & .title {
    letter-spacing: 1.6px;
    font-size: 16px;
    color: rgba(25, 35, 59, 0.6);
  }

  & .option {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 5px;
    padding: 5px 10px;
    letter-spacing: 1.6px;
    color: #b5b8bf;
    min-width: 60px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
  }

  & .option.active {
    color: #4e77e7;
  }

  @media screen and (max-width: 550px) {
    flex-wrap: wrap;
  }

  ${props => props.css || ""};
`;

export default connect(
  null,
  ActionCreator
)(RepairOrderTable);
