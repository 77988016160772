import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../ActionCreator";
import Selectors from "../../Selectors";
import Form from "../../../src/Components/Form";

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

class ProfileForm extends React.Component {
  render() {
    let { profile, appActions } = this.props;
    if (!profile) {
      return null;
    }

    return (
      <Wrapper>
        <Form
          model={{
            fields: [
              { name: "name", type: "text", label: "Name" },
              { name: "phone", type: "text", label: "Phone" },
              { name: "avatar", type: "image", label: "Avatar" },
              { name: "pro", type: "checkbox", label: "Pro" },
              {
                name: "store",
                type: "select-ext",
                label: "Owner",
                extra: {
                  fnFetchChoices: async () => {
                    return [
                      { label: "AAA", value: "aaa" },
                      { label: "BBB", value: "bbb" },
                      { label: "CCC", value: "ccc" }
                    ];
                  },
                  fnSearchChoices: async search => {
                    await delay(1000);
                    return [
                      { label: "AAA", value: "aaa" },
                      { label: "BBB", value: "bbb" },
                      { label: "CCC", value: "ccc" }
                    ].filter(item => item.value.indexOf(search) >= 0);
                  },
                  fnGetLabel: choice => choice.label
                }
              }
            ]
          }}
          instance={profile}
          fnUpdate={data => {
            if (typeof data.avatar === "string") {
              delete data.avatar;
            }
            appActions.editMyProfile(data);
          }}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div``;

export default connect(
  null,
  ActionCreator
)(ProfileForm);
