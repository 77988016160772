import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { dateParser } from "../../src/Utils/DateUtil";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator/Buffet";
import * as Widget from "./Widget/Buffet";
import * as Icon from "./Icon";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class CommonOrderCreateDialog extends Component {
  constructor(props) {
    super(props);
    let { instance, items } = this.props;
    this.state = {
      record: {
        title: "TITLE",
        status: "waiting",
        buyer_name: "",
        buyer_phone: "",
        buyer_email: "",
        receiver_address: "",
        deposit: 0,
        amount: 0,
        delivery_type: "express",
        url: "",
        note: "",
        production_note: "",
        logistics_number: "",
        completed_date: "",
        number: "",
        discount: 9.5,
        shipping_cost: 0,
        payment_method: "cash",
        staff: ""
      },
      shoesCustomTypes: null,
      item: null,
      showSpinner: false,
      message: {
        display: true,
        text: ""
      },
      hint: "",
      staffs: []
    };
  }

  componentDidMount() {
    this._getShoesCustomType();
    this._getStaffList();
  }

  render() {
    let { title, open, onClose, profile } = this.props;
    let {
      record,
      shoesCustomTypes,
      showSpinner,
      message,
      item,
      hint,
      staffs
    } = this.state;

    return (
      <Widget.Dialog open={open} className={`${open ? "active" : ""}`}>
        <div className="backdrop" onClick={onClose} />
        {open && (
          <div className="container" onClick={e => e.stopPropagation()}>
            <div className="content">
              <div className="title">{title}</div>
              <div className="form-section">
                <div className="form-container">
                  <div className="form-content">
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單編號</span>
                      <div className="text">待創建後生成</div>
                    </Widget.FormField>
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單狀態</span>
                      <select
                        value={record.status}
                        onChange={e =>
                          this.setState({
                            record: { ...record, status: e.target.value }
                          })
                        }
                      >
                        {[
                          { value: "waiting", display: "未付款" },
                          { value: "confirming", display: "留單" },
                          { value: "deposit_paid", display: "已付訂金" },
                          { value: "in_production", display: "製作中" },
                          { value: "in_inspection", display: "整理檢查" },
                          { value: "store_pickup", display: "可店取" },
                          { value: "delivered", display: "已寄送" },
                          { value: "completed", display: "已完成" }
                        ].map((status, i) => (
                          <option key={"statue" + i} value={status.value}>
                            {status.display}
                          </option>
                        ))}
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">製單人</span>
                      <select
                        value={record.staff}
                        onChange={e =>
                          this.setState({
                            record: { ...record, staff: e.target.value }
                          })
                        }
                      >
                        <option value="">---</option>
                        {staffs &&
                          staffs.map((staff, i) => (
                            <option key={"staff" + i} value={staff.name}>
                              {staff.name}
                            </option>
                          ))}
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">顧客姓名</span>
                      <input
                        value={record.buyer_name}
                        onChange={e =>
                          this.setState({
                            record: { ...record, buyer_name: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">顧客電話</span>
                      <input
                        value={record.buyer_phone}
                        onChange={e =>
                          this.setState({
                            record: { ...record, buyer_phone: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">顧客信箱</span>
                      <input
                        value={record.buyer_email}
                        onChange={e =>
                          this.setState({
                            record: { ...record, buyer_email: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">寄送地址</span>
                      <input
                        value={record.receiver_address}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              receiver_address: e.target.value
                            }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單折扣</span>
                      <select
                        value={record.discount}
                        onChange={e =>
                          this.setState({
                            record: { ...record, discount: e.target.value }
                          })
                        }
                      >
                        <option value={0}>無折扣</option>
                        <option value={9.5}>九五折</option>
                        <option value={9}>九折</option>
                        <option value={8}>八折</option>
                        <option value={8.5}>八五折</option>
                        <option value={5}>對折</option>
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">運費</span>
                      <input
                        value={record.shipping_cost}
                        type="number"
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              shipping_cost: e.target.value
                            }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>

                  <div className="form-content">
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">預付訂金</span>
                      <input
                        type="number"
                        value={record.deposit}
                        onChange={e =>
                          this.setState({
                            record: { ...record, deposit: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>
                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單金額</span>
                      <input
                        type="number"
                        value={record.amount}
                        onChange={e =>
                          this.setState({
                            record: { ...record, amount: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">付款方式</span>
                      <select
                        value={record.payment_method}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              payment_method: e.target.value
                            }
                          })
                        }
                      >
                        <option value={"cash"}>現金</option>
                        <option value={"card"}>信用卡</option>
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">訂單備註</span>
                      <input
                        value={record.note}
                        onChange={e =>
                          this.setState({
                            record: { ...record, note: e.target.value }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">預期完成日</span>
                      <input
                        type="date"
                        value={record.completed_date}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              completed_date: e.target.value
                            }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">配送方式</span>
                      <select
                        value={record.delivery_type}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              delivery_type: e.target.value
                            }
                          })
                        }
                      >
                        <option value="express">順豐</option>
                        <option value="post">郵局</option>
                        <option value="pelican">宅配通</option>
                        <option value="self_pick">自取</option>
                      </select>
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">物流單編號</span>
                      <input
                        value={record.logistics_number}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              logistics_number: e.target.value
                            }
                          })
                        }
                      />
                    </Widget.FormField>

                    <Widget.FormField css="margin-bottom: 17px;">
                      <span className="label">作業備註</span>
                      <textarea
                        value={record.production_note}
                        onChange={e =>
                          this.setState({
                            record: {
                              ...record,
                              production_note: e.target.value
                            }
                          })
                        }
                      />
                    </Widget.FormField>
                  </div>
                </div>

                <div className="shoes-container">
                  {shoesCustomTypes && (
                    <div className="shoes-content">
                      {shoesCustomTypes
                        .filter((_, i) => i <= 2)
                        .map(customType => {
                          let choice = item.choices.find(
                            (c, i) => c.type.id === customType.id
                          );
                          return (
                            <Widget.FormField
                              css="margin-bottom: 17px;"
                              key={customType.display}
                            >
                              <span className="label">
                                {customType.display}
                              </span>
                              <select
                                value={choice && choice.option.id}
                                onChange={e => {
                                  let hitOptions = customType.options.find(
                                    option =>
                                      option.id === Number(e.target.value)
                                  );
                                  choice.option = hitOptions;

                                  let hitChoiceIdx = item.choices.findIndex(
                                    (c, i) => c.type.id === customType.id
                                  );
                                  item.choices[hitChoiceIdx] = choice;

                                  this.setState({ item });
                                }}
                              >
                                {customType.options.map(o => (
                                  <option value={o.id}>{o.display}</option>
                                ))}
                              </select>
                            </Widget.FormField>
                          );
                        })}
                    </div>
                  )}

                  {shoesCustomTypes && (
                    <div className="shoes-content">
                      {shoesCustomTypes
                        .filter((_, i) => i >= 3)
                        .map(customType => {
                          let choice = item.choices.find(
                            (c, i) => c.type.id === customType.id
                          );
                          return (
                            <Widget.FormField
                              css="margin-bottom: 17px;"
                              key={customType.display}
                            >
                              <span className="label">
                                {customType.display}
                              </span>
                              <select
                                value={choice && choice.option.id}
                                onChange={e => {
                                  let hitOptions = customType.options.find(
                                    option =>
                                      option.id === Number(e.target.value)
                                  );
                                  choice.option = hitOptions;

                                  let hitChoiceIdx = item.choices.findIndex(
                                    (c, i) => c.type.id === customType.id
                                  );
                                  item.choices[hitChoiceIdx] = choice;

                                  this.setState({ item });
                                }}
                              >
                                {customType.options.map(o => (
                                  <option value={o.id}>{o.display}</option>
                                ))}
                              </select>
                            </Widget.FormField>
                          );
                        })}
                    </div>
                  )}
                </div>
              </div>

              <div className="bottom-section">
                <div className="message">{message.display && message.text}</div>
                {showSpinner ? (
                  <Widget.Spinner />
                ) : (
                  <div className="buttons">
                    <Widget.WhiteButton onClick={onClose}>
                      取消
                    </Widget.WhiteButton>
                    <Widget.BlueButton onClick={this._createOrder}>
                      確定
                    </Widget.BlueButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </Widget.Dialog>
    );
  }

  _getShoesCustomType = async () => {
    let { appActions } = this.props;

    try {
      const shoesCustomTypes = await appActions.getShoesCustomType();
      const item = {
        store: {
          id: 1,
          name: "Chenjingkai Office"
        }
      };

      item.choices = shoesCustomTypes.map(customType => ({
        type: { id: customType.id, name: customType.name },
        option: customType.options[0]
      }));

      this.setState({ shoesCustomTypes, item });
    } catch (ex) {
      console.warn(ex);
    }
  };

  _getStaffList = async () => {
    let { appActions } = this.props;

    try {
      let staffs = await appActions.getStaffList();
      this.setState({ staffs });
    } catch (err) {
      console.warn(err);
    }
  };

  _createOrder = async () => {
    const { appActions, onClose, refreshOrder } = this.props;
    const { record, item } = this.state;

    if (record.completed_date === "") {
      this.setState({
        message: {
          display: true,
          text: "請填寫預期完成日。"
        }
      });
      return;
    }

    record.deposit = Number(record.deposit);
    record.shipping_cost = Number(record.shipping_cost);
    let data = JSON.stringify({ items: [item] });
    record.data = data;

    this.setState({ showSpinner: true });
    try {
      await appActions.createCommonOrder(record);
      onClose();
      refreshOrder();
    } catch (ex) {
      console.warn(ex);
    }
    this.setState({ showSpinner: false });
  };
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(CommonOrderCreateDialog);
