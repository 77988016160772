import React from "react";
import styled from "styled-components";
import { dateParser } from "../../../src/Utils/DateUtil";
import * as Icon from "../../../src/Components/Icon";
import * as Widget from "../Widget";

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;

  & .row {
    display: flex;
    align-items: stretch;
    margin-bottom: 14px;
    min-height: 60px;

    .cell {
      flex: 0;
      flex-shrink: 0;
      flex-basis: 100px;
      padding: 5px 10px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 16px;
      color: rgba(25, 35, 59, 0.6);
    }

    .cell.header {
      font-size: 16px;
      color: rgba(25, 35, 59, 0.6);
      padding: 10px 5px;
      text-align: center;
      letter-spacing: 1.6px;
      border-top: 1px solid #e4e4e4;
      background-color: transparent;
    }

    .cell:first-child {
      cursor: pointer;
      color: blue;
      text-decoration: underline;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-left: 10px solid #95a7b1;
    }

    .cell.header:first-child {
      border-left: 0px;
      color: #a4a8b1;
      text-decoration: none;
    }

    .cell:first-child:hover {
      font-weight: bold;
    }

    .cell:last-child {
      flex-grow: 1;
    }
  }
`;

const Cell = styled.div`
  ${props => props.css || ""}
`;

class Table extends React.Component {
  render() {
    let {
      model,
      instances,
      onCellClick = () => 0,
      customizeCells = {},
      onCellSelect = () => 0,
      onCellSelectValueChange = () => 0,
      isSelectedFunc = () => 0
    } = this.props;
    return (
      <Wrapper>
        <TableHeader model={model} />

        {instances.map((instance, idx) => (
          <TableRow
            key={idx}
            instance={instance}
            model={model}
            onCellClick={onCellClick}
            customizeCells={customizeCells}
            onCellSelect={onCellSelect}
            onCellSelectValueChange={onCellSelectValueChange}
            selected={isSelectedFunc(instance)}
          />
        ))}
      </Wrapper>
    );
  }
}

class TableRow extends React.Component {
  render() {
    let {
      model,
      instance,
      onCellClick,
      customizeCells,
      onCellSelect,
      onCellSelectValueChange,
      selected
    } = this.props;

    return (
      <div className="row">
        {model.fields.map((field, idx) => {
          let extraProps = {};

          if (idx === 0) {
            extraProps.onClick = () => onCellClick({ instance });
          }

          if (
            field.name.indexOf("customize-") === 0 &&
            customizeCells[field.name]
          ) {
            try {
              return (
                <Cell
                  className="cell"
                  key={idx}
                  style={field.style || {}}
                  {...extraProps}
                >
                  {customizeCells[field.name]["renderCustomizeCell"](instance)}
                </Cell>
              );
            } catch (err) {
              throw new Error(
                `format mismatch, ex: customizeCells: { 'customize-1': { renderCustomizeCell: () => {} } }`
              );
            }
          }

          return (
            <Cell
              className="cell"
              key={idx}
              style={field.style || {}}
              {...extraProps}
            >
              {(() => {
                if (field.type === "datetime") {
                  return dateParser(instance[field.name]);
                }

                if (field.type === "url") {
                  return (
                    <a href={instance[field.name]} target="_blank">
                      {instance[field.name]}
                    </a>
                  );
                }

                if (field.type === "checkbox") {
                  return (
                    <Widget.Row
                      align="center"
                      onClick={() => {
                        onCellSelect(instance.id);
                      }}
                    >
                      {selected ? (
                        <Icon.CheckBox size={20} color="#95A7B1" />
                      ) : (
                        <Icon.CheckBoxOutlineBlank size={20} color="#95A7B1" />
                      )}
                      <span style={{ marginLeft: 4 }}>
                        {instance[field.name]}
                      </span>
                    </Widget.Row>
                  );
                }

                if (field.type === "nested") {
                  return instance[field.name][field.key]
                    ? field.extra.displayMap[
                        `${instance[field.name][field.key]}`
                      ]
                    : field.extra.displayMap[
                        `${instance[field.name][field.key]}`
                      ];
                }

                if (field.type === "dropdown") {
                  return (
                    <select
                      value={instance[field.name]}
                      onChange={e =>
                        onCellSelectValueChange(e.target.value, instance)
                      }
                    >
                      {field.extra.dataMap.map((option, i) => (
                        <option key={field.name + i} value={option.value}>
                          {option.display}
                        </option>
                      ))}
                    </select>
                  );
                }

                if (field.type === "boolean") {
                  return (
                    field.extra &&
                    field.extra.displayMap.find(
                      d => d.value === instance[field.name]
                    ).display
                  );
                }

                return (
                  (field.extra &&
                    field.extra.displayMap[`${instance[field.name]}`]) ||
                  `${instance[field.name]}`
                );
              })()}
            </Cell>
          );
        })}
      </div>
    );
  }
}

class TableHeader extends React.Component {
  render() {
    let { model } = this.props;
    return (
      <div className="row">
        {model.fields.map((field, idx) => (
          <Cell className="cell header" key={idx} style={field.style || {}}>
            {field.label || field.name}
          </Cell>
        ))}
      </div>
    );
  }
}

export default Table;
